export const toTitleCase = (str) => {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
  });
};

export const removeUnderscore = (string) => {
  return string?.replace(/_/g, " ");
};
export const splitStr = (str, character = "") => {
  return str && str?.split(character)[0];
};

export const getUpdatedFilterValue = (value) => {
  if (value === "Nut & Peanut free") value = "Nut free";
  else if (value === "Eco-friendly") value = "Eco-friendly packaging";
  else if (value === "Crowd pleasers") value = "Crowd Pleasers";
  else if (value === "hungerhub exclusives") value = "hungerhub Exclusives";

  return value;
};

export const truncateText = (text, length) => {
  return text?.length > length ? `${text.slice(0, length)}...` : text;
};

export const getByPassLocationCheckText = (address) => {
  let errorMessage = `We are unable to update the address on your account while you have an order in progress.
     We will deliver to the address we have on file: ${address?.suite} ${
    address?.suite && "-"
  } ${address?.street_number} ${address?.street_name}, ${address?.city} ${
    address?.postal_code
  }.
     If you need to have the order delivered to a new address, place the order and then let us know the new address via online chat or email to help@hungerhub.com.`;

  return errorMessage;
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const formattedAddres = (address) => {
  let addres = ` ${address?.nickname ?? ""} 
  ${address?.destination_code ? `(${address.destination_code})` : ""} 
  - ${address?.suite ? `${address.suite} -` : ""} ${
    address?.street_number ?? ""
  } ${address?.street_name}, ${address?.city}, ${address?.province ?? ""} ${
    address?.postal_code ?? ""
  }`;

  return Object.values(address ? address : {}).every((value) => value === "")
    ? "-"
    : addres;
};
