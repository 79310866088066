import React, { useState, useContext, useEffect } from "react";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { MobXProviderContext, observer } from "mobx-react";
import { InfoButton, RadioActive, RadioInActive } from "assets/img";
import { toJS } from "mobx";
import { stripePromise } from "utils/helpers/stripe";

const CheckoutForm = ({
  setModal,
  handleTemporaryCard,
  handlePaymentMethod,
  visible,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [saveCard, setSaveCard] = useState(true);

  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  let { locationID } = userStore;

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
      focus: {
        color: "#303238",
        backgroundColor: "#000",
      },
    },
  };

  useEffect(() => {
    if (visible) {
      elements?.getElement(CardNumberElement)?.clear();
      elements?.getElement(CardExpiryElement)?.clear();
      elements?.getElement(CardCvcElement)?.clear();
      setSaveCard(true);
    }
  }, [visible]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    let cardDetails = {
      display_brand: paymentMethod?.card?.brand,
      last4: paymentMethod?.card?.last4,
      exp_month: paymentMethod?.card?.exp_month,
      exp_year: paymentMethod?.card?.exp_year,
    };

    if (error) {
      setErrorMessage(error.message);
    } else {
      const cardId = paymentMethod?.id;

      if (saveCard) {
        //create credit card
        store.paymentInfoStore
          .createLocationCreditCard({
            location_id: locationID,
            card_id: cardId,
          })
          .then((response) => {
            store.userStore?.setLoader(false);
            return response;
          })
          .catch(() => store.userStore.setLoader(false));
      } else {
        handleTemporaryCard(cardDetails);
        store.paymentInfoStore.saveCardElements(cardElement);
      }
      handlePaymentMethod();

      setModal(false);
    }
    return;
  };

  return (
    <form
      className="d-col items-center w-full h-full gap-6 justify-between items-center"
      onSubmit={handleSubmit}
    >
      <div className="w-full">
        <div className="d-col w-full gap-4">
          <div className="text-slate-700 text-lg font-inter-medium leading-snug">
            Card Number
          </div>

          <div
            style={{
              padding: "15px",
            }}
            className="w-full bg-slate-50 rounded-xl border border-slate-50"
          >
            <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>

        <div className="d-row w-full gap-[18px] mt-[24px]">
          <div className="d-col w-full gap-4">
            <div className="text-slate-700 text-lg font-inter-medium leading-snug">
              Expiration Date
            </div>

            <div
              style={{
                padding: "15px",
              }}
              className="w-full bg-slate-50 rounded-xl border border-slate-50"
            >
              <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>

          <div className="d-col w-full gap-4">
            <div className="text-slate-700 text-lg font-inter-medium leading-snug">
              CVV{" "}
            </div>

            <div
              style={{
                padding: "15px",
              }}
              className="w-full bg-slate-50 rounded-xl border border-slate-50"
            >
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>
        </div>

        <div className="d-row w-full gap-[18px] mt-[24px]">
          <div className="d-col w-full gap-4">
            <div className="text-slate-700 text-lg font-inter-medium leading-snug">
              Would you like to save this credit card to your account?
            </div>

            <div className="w-full p-2 bg-zinc-100 rounded-lg justify-center items-center gap-3 inline-flex">
              <img
                src={InfoButton}
                alt="info-icon"
                className="icon w-[20px] h-[20px]"
              />

              <div className="grow shrink basis-0 text-slate-700 text-base font-inter-normal leading-tight">
                Saved credit cards will be available to all individuals with
                administrative privileges when placing an order.
              </div>
            </div>

            <div
              className="flex cursor-pointer"
              onClick={() => setSaveCard(false)}
            >
              <img
                alt="radio-button"
                src={!saveCard ? RadioActive : RadioInActive}
              />

              <small className="text-sm text-black font-inter-normal ml-4">
                No, use it only for this order.
              </small>
            </div>

            <div
              className="flex cursor-pointer"
              onClick={() => setSaveCard(true)}
            >
              <img
                alt="radio-button"
                src={saveCard ? RadioActive : RadioInActive}
              />

              <small className="text-sm text-black font-inter-normal ml-4">
                Yes, save it to my payment methods
              </small>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2 inline-flex gap-[19px]">
        <button className="btn-light btn-style" onClick={() => setModal(false)}>
          Cancel
        </button>

        <button
          className="btn-purple btn-style"
          type="submit"
          disabled={!stripe}
        >
          Save
        </button>
      </div>

      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const AddPaymentCard = ({
  visible,
  setModal,
  add,
  edit,
  handleTemporaryCard,
  handlePaymentMethod,
}) => (
  <div
    className={`fixed inset-0 z-[90] flex items-center justify-center bg-black bg-opacity-25 ${
      visible ? "visible opacity-100" : "invisible opacity-0"
    } transition-opacity duration-300`}
  >
    <div className="fixed inset-0" onClick={() => setModal(false)} />

    <div className="relative w-[664px] bg-white rounded-xl shadow-xl p-8">
      <div className="flex justify-between items-center mb-5">
        <h2 className="text-2xl font-bold">
          {edit ? "Edit Card" : "Add New Card"}
        </h2>
        <button
          onClick={() => setModal(false)}
          className="text-dark-gray text-xl"
          aria-label="Close"
        >
          &times;
        </button>
      </div>

      <Elements stripe={stripePromise}>
        <CheckoutForm
          setModal={setModal}
          handleTemporaryCard={handleTemporaryCard}
          handlePaymentMethod={handlePaymentMethod}
          visible={visible}
        />
      </Elements>
    </div>
  </div>
);

export default observer(AddPaymentCard);
