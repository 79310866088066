import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AltImage } from "assets/img";
import Tooltip from "components/Tooltip";
import { toTitleCase } from "utils/helpers/string";
import badge from "utils/helpers/badge";
import { getOperatingHours } from "utils/helpers/restaurants";
import { priceFormatter } from "utils/helpers/validation";
import icon from "utils/helpers/icon";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ODC_TAGLINE_LENGTH } from "utils/constants/common";
import { useNavigate } from "react-router-dom";

const TagSkeleton = () => (
  <Skeleton width={100} height={20} borderRadius={10} className="mr-3" />
);

function RestaurantDetailsModal({
  visible,
  closeModal,
  loadedMenu,
  loadingMenu,
  isGroupMeal,
}) {
  const navigate = useNavigate();

  const restaurantLink = `${
    isGroupMeal ? "/get-started-group" : "/get-started-catering"
  }/restaurant/${loadedMenu?.attributes?.code}`;

  const moveToRestaurantDetails = () => {
    navigate(restaurantLink, { state: { menuDetails: loadedMenu } });
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={() => closeModal()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-3/6 select-none transform overflow-hidden rounded-2xl bg-white px-8 py-5 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => closeModal()}
                    className="btn-style"
                  >
                    <FontAwesomeIcon
                      style={{ width: 16, height: 26 }}
                      icon={faXmark}
                      size="2x"
                      color="#2D3958"
                    />
                  </button>
                </div>

                <div className="flex flex-col flex-wrap mt-3">
                  {loadingMenu ? (
                    <Skeleton height={181} borderRadius={20} />
                  ) : (
                    <div className="flex relative">
                      <div
                        className={`w-full relative overflow-hidden`}
                        style={{ height: "181px", borderRadius: 20 }}
                      >
                        <img
                          src={
                            loadedMenu?.attributes?.background_image_url ??
                            AltImage
                          }
                          width="100%"
                          height={"181px"}
                          layout="fill"
                          objectFit="cover"
                          placeholder="blur"
                          blurDataURL={
                            loadedMenu?.attributes?.background_image_url
                          }
                          sizes="(max-width: 768px) 10vw, (max-width: 1200px) 20vw, 20vw"
                          alt="restaurant-image"
                        />

                        <div className="absolute inset-0 bg-black bg-opacity-50" />
                      </div>

                      <div className="absolute z-10 top-5 right-3">
                        <div className="flex flex-wrap justify-end px-1 w-full float-right space-x-2">
                          {loadedMenu?.attributes?.featured_badges
                            ?.slice(0, 2)
                            ?.map((featured, index) =>
                              badge.getFeaturedTag(featured, index)
                            )}
                        </div>
                      </div>

                      <div className="flex absolute z-10 bottom-3 left-4">
                        <div className="flex flex-col px-4 py-3">
                          <div className="flex items-center">
                            <span
                              className={`text-[24px] font-inter-semibold text-white mr-1`}
                            >
                              {loadedMenu?.attributes?.display_name}
                            </span>

                            <Tooltip
                              whiteIcon
                              tooltipText={getOperatingHours(loadedMenu)}
                              id="res-info-tooltip"
                              backgroundColor={"#ffffff"}
                              textColor="black"
                              place={"bottom"}
                            />
                          </div>

                          <div className="flex flex-wrap">
                            {loadedMenu?.attributes?.cuisine_tags?.map(
                              (tag, index) => (
                                <span
                                  key={`${loadedMenu?.attributes?.display_name}-tag-${index}`}
                                  className={`font-inter-regular text-md text-white`}
                                >
                                  {tag}
                                  {index + 1 !==
                                    loadedMenu?.attributes?.cuisine_tags
                                      ?.length && (
                                    <span className="whitespace-pre"> • </span>
                                  )}
                                </span>
                              )
                            )}
                          </div>

                          <span className="mt-2 text-white text-sm pr-4">
                            {loadedMenu?.attributes?.tagline_odc_long?.length >
                            ODC_TAGLINE_LENGTH
                              ? `${loadedMenu?.attributes?.tagline_odc_long.slice(
                                  0,
                                  ODC_TAGLINE_LENGTH
                                )}...`
                              : loadedMenu?.attributes?.tagline_odc_long}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="flex flex-col mt-6">
                    {loadingMenu ? (
                      <Skeleton height={85} />
                    ) : (
                      <div className="flex bg-background rounded-lg p-4 text-sm">
                        <div className="flex flex-col">
                          <span className="text-primary-black">Address:</span>
                          <span className="text-primary-black mt-1 font-inter-medium">
                            {loadedMenu?.attributes?.restaurant_address}
                          </span>
                        </div>

                        <div className="w-0.5 bg-divider mx-2" />

                        <div className="flex flex-col text-sm">
                          <span className="text-primary-black">
                            Minimum order:
                          </span>
                          <span className="text-primary-black mt-1 font-inter-medium">
                            {priceFormatter(
                              loadedMenu?.attributes?.order_minimum_amount
                            )}
                          </span>
                        </div>

                        <div className="w-0.5 bg-divider mx-2" />

                        <div className="flex flex-col text-sm">
                          <span className="text-primary-black">
                            Minimum order time:
                          </span>

                          <span className="text-primary-black mt-1 font-inter-medium">
                            {
                              loadedMenu?.attributes
                                ?.accept_order_hours_in_advance
                            }{" "}
                            Hours
                          </span>
                        </div>

                        <div className="w-0.5 bg-divider mx-2" />

                        <div className="flex flex-col text-sm">
                          <span className="text-primary-black">
                            Next available date:
                          </span>
                          <span className="text-primary-black mt-1 font-inter-medium">
                            {loadedMenu?.attributes?.next_available_date}
                          </span>
                        </div>
                      </div>
                    )}

                    {loadingMenu ? (
                      <div className="flex mt-3">
                        <TagSkeleton />
                        <TagSkeleton />
                        <TagSkeleton />
                      </div>
                    ) : (
                      <div className="flex flex-wrap mt-3">
                        {loadedMenu?.attributes?.menu_items_badges?.map(
                          (tag, index) => (
                            <div
                              key={`${index}-dietary_tag_in_detail`}
                              className="flex bg-background py-1 px-2 my-1 mr-2 whitespace-pre-line rounded-xl items-center justify-center"
                            >
                              <img
                                width={"16px"}
                                height={"16px"}
                                layout="fixed"
                                alt={`${tag}-icon-menu`}
                                src={icon.getBadgeIcon(tag)}
                              />
                              <span
                                className={`text-xs font-inter-medium ml-2`}
                              >
                                {toTitleCase(tag)}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>

                  <div className="flex items-center justify-center mt-5 mb-3">
                    {loadingMenu ? (
                      <Skeleton width={130} height={30} />
                    ) : (
                      <button
                        type="button"
                        className="btn-purple"
                        onClick={moveToRestaurantDetails}
                      >
                        Open restaurant page
                      </button>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default RestaurantDetailsModal;
