import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { CopyLinkUrl } from "assets/img";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

function GenerateLink({ visible, setModal }) {
  const linkToCopy = `${process.env.REACT_APP_UNCATERING_APP_LINK}/guest/restaurants/${visible}`;

  const copyLinkToClipboard = () => {
    const linkToCopy = `${process.env.REACT_APP_UNCATERING_APP_LINK}/guest/restaurants/${visible}`;
    navigator.clipboard.writeText(linkToCopy);

    setTimeout(() => {
      setModal(false);
    }, 1000);
  };
  return (
    <Transition appear show={visible ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[667px] d-col items-center rounded-xl transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <>
                  <div className="d-row w-full justify-end items-center">
                    <button
                      onClick={() => setModal(false)}
                      className="text-dark-gray hover:text-yellow-600"
                      aria-label="Close"
                    >
                      <span className="text-xl">&times;</span>
                    </button>
                  </div>

                  <div className="flex flex-col items-center justify-center">
                    <div className="flex flex-col items-center">
                      <img
                        className="w-[139px] h-[89px] self-center"
                        src={CopyLinkUrl}
                      />

                      <span className="text-[24px] font-inter-semibold text-black-light mt-1.5">
                        Link generated
                      </span>

                      <span className="text-md text-center mt-3 w-[418px]">
                        You can send this link to multiple guests. Please note
                        that invited users will not be permanently added to this
                        meal series or to your account
                      </span>
                    </div>

                    <div className="flex flex-col justify-center items-center flex-wrap mt-12 w-full">
                      <div className="flex flex-col w-full">
                        <div className="flex justify-between text-md font-inter-regular rounded-xl bg-background w-full overflow-hidden focus:outline-none h-12 mt-3">
                          <input
                            className="flex truncate w-full bg-background text-secondary-text focus:outline-none rounded-l-md text-md px-3"
                            disabled={true}
                            value={linkToCopy}
                          />

                          <button
                            type="button"
                            onClick={copyLinkToClipboard}
                            className="btn-style w-[226px] bg-primary-dark hover:bg-hh-accent-light"
                          >
                            <div className="flex items-center justify-center">
                              <FontAwesomeIcon
                                style={{ width: 16, height: 26 }}
                                icon={faCopy}
                                size="2x"
                                color="#fff"
                                className="mr-[8px] w-[18px] h-[20px]"
                              />
                              <span className="text-white font-inter-medium text-[16px]">
                                Copy Link
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default GenerateLink;
