import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { RadioActive, RadioInActive } from "assets/img";

function ExportPdf({ visible, setModal, applyPressed }) {
  const [excludeCancel, setExcludeCancel] = useState(true);

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="d-col justify-between rounded-xl transform overflow-scroll sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="d-col">
                  <div className="d-row justify-end">
                    <button
                      onClick={() => setModal(false)}
                      className="text-dark-gray hover:text-yellow-600"
                      aria-label="Close"
                    >
                      <span className="text-3xl text-[#BBC0C5]">&times;</span>
                    </button>
                  </div>

                  <div className="d-col w-full mt-[12px] items-start justify-center flex">
                    <div className="text-[#2d3958] text-2xl font-inter-semibold leading-loose mb-[20px]">
                      Include cancelled orders in export?{" "}
                    </div>

                    <div
                      className="flex cursor-pointer mb-[12px]"
                      onClick={() => setExcludeCancel(true)}
                    >
                      <img
                        alt="radio-button"
                        src={excludeCancel ? RadioActive : RadioInActive}
                      />

                      <div className="ml-3">
                        <span className="text-[#2d3958] text-sm font-inter-bold leading-none tracking-tight">
                          Yes
                        </span>
                        <span className="text-[#2d3958] text-sm font-inter-medium leading-none tracking-tight">
                          {" "}
                          - include all orders, including cancelled ones.
                        </span>
                      </div>
                    </div>

                    <div
                      className="flex cursor-pointer"
                      onClick={() => setExcludeCancel(false)}
                    >
                      <img
                        alt="radio-button"
                        src={!excludeCancel ? RadioActive : RadioInActive}
                      />

                      <div className="ml-3">
                        <span className="text-[#2d3958] text-sm font-inter-bold leading-none tracking-tight">
                          No
                        </span>
                        <span className="text-[#2d3958] text-sm font-inter-medium leading-none tracking-tight">
                          {" "}
                          - Exclude cancelled orders from the export.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-row w-full items-center mt-[32px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5 border border-[#232777]"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3"
                    onClick={() => applyPressed(excludeCancel)}
                  >
                    Export Orders
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ExportPdf;
