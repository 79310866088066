import React, { useEffect, useState, useContext } from "react";
import { useManagePlan } from "contexts/ManagePlanContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { NotFound, NotificationBanner } from "components";
import { weekDays } from "utils/constants/onboardingStep";
import { useNavigate, useLocation } from "react-router-dom";
import { MobXProviderContext, observer } from "mobx-react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { capitalizeFirstLetter, formattedAddres } from "utils/helpers/string";
import dateTime from "utils/helpers/dateTime";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "components/ConfirmationModal";
import dayjs from "dayjs";

import {
  ChangeDeliveryTime,
  ChangeExpectedOrders,
  ChangeContact,
  ChangeDeliveryInstructions,
  ChangeSubsidy,
  ViewBag,
  ChangeMealPlanName,
  ChangeEnabledDays,
  ChangeDeliveryAddress,
  ChangeMealDate,
} from "alerts";
import { EditPencil, InfoButton } from "assets/img";
import classNames from "classnames";
import object from "utils/helpers/object";
import { priceFormatter } from "utils/helpers/validation";
import { isPresent } from "utils/helpers/array";
import AddressTimeModal from "components/AddressTimeModal";

const EditPencilButton = ({ onClick }) => (
  <button onClick={onClick} className="d-row items-center btn-style ml-[5px]">
    <img src={EditPencil} layout="fixed" />
  </button>
);

function UncateringPlans({
  managePlanPress,
  data,
  tzinfo,
  setUncateringMealPlanPending,
}) {
  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="bg-light-gray">
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-bold text-dark-gray sm:pl-3"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Address
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Next Order date
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Expected order
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Deadline to order
                </th>

                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                ></th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {data?.map((meal) => (
                <tr
                  key={meal?.id}
                  className="border border-l-0 border-r-0 border-[#DEE2E6] border-b-[0.5px]"
                >
                  <td className="break-words py-4 pl-4 pr-3 text-sm font-inter-medium text-dark-gray sm:pl-3">
                    {capitalizeFirstLetter(meal?.attributes?.name)}
                  </td>
                  <td className="px-3 py-4 text-sm font-inter-medium text-dark-gray break-all">
                    {capitalizeFirstLetter(
                      meal?.attributes?.address?.attributes?.nickname
                    )}{" "}
                    ({meal?.attributes?.address?.attributes?.destination_code})
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                    {meal?.attributes?.next_order_date
                      ? dateTime.formatDate(
                          meal?.attributes?.next_order_date,
                          dateTime.longOrdersDateFormat
                        )
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                    {meal?.attributes?.expected_bags}{" "}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                    {dateTime.to12hourformat(meal?.attributes?.cutoff, tzinfo)}
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-3">
                    <button
                      onClick={() => {
                        if (meal?.attributes?.under_review)
                          setUncateringMealPlanPending(true);
                        else managePlanPress(true, meal?.id);
                      }}
                      className="btn-outline font-inter-medium border-2"
                    >
                      Manage plan
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function UpcomingGroupOrders({ onClick, data, navigate, tzinfo, locationId }) {
  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="bg-light-gray">
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-bold text-dark-gray sm:pl-3"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Address
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Restaurant
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Order Date
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Delivery Time
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Budget per user
                </th>

                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                ></th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {data?.map((order) => (
                <tr
                  key={order.id}
                  className="border border-l-0 border-r-0 border-[#DEE2E6] border-b-[0.5px]"
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-medium text-dark-gray sm:pl-3">
                    {capitalizeFirstLetter(order?.attributes?.meal_name)}
                  </td>

                  <td className="break-all px-3 py-4 text-sm font-inter-medium text-dark-gray">
                    {capitalizeFirstLetter(
                      order?.attributes?.address?.attributes?.nickname
                    )}{" "}
                    ({order?.attributes?.address?.attributes?.destination_code})
                  </td>
                  <td className="break-words px-3 py-4 text-sm font-inter-medium text-dark-gray">
                    {order?.attributes?.restaurant_name}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                    {dateTime.formatDate(
                      order?.attributes?.meal_date,
                      dateTime.longOrdersDateFormat
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                    {dateTime.formatTime(
                      order?.attributes?.target_time,
                      tzinfo
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                    {priceFormatter(order?.attributes?.budget_per_user || "")}
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-3">
                    <button
                      onClick={() =>
                        navigate(
                          `/group-order/admin/orders/${order?.attributes?.meal_series_id}/${locationId}`
                        )
                      }
                      className="btn-outline font-inter-medium border-2"
                    >
                      View order
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {!isPresent(data) && (
            <div className="d-col w-full self-center justify-center items-center gap-2">
              <small className="text-secondary font-[14px] mt-[24px]">
                No upcoming group orders
              </small>

              <button
                onClick={() => {
                  navigate("/our-services");
                }}
                className="btn-light-gray"
              >
                Learn about Group Orders
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const UpcomingCateringOrders = ({ data, navigate, locationId }) => {
  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="bg-light-gray">
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-bold text-dark-gray sm:pl-3"
                >
                  Address
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Restaurant
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Order Date
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Delivery Time
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Total Items
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                ></th>
              </tr>
            </thead>

            <tbody className="bg-white space-y-4">
              {data?.map((meal) => (
                <tr
                  key={meal?.id}
                  className={`border border-l-0 border-r-0 border-[#DEE2E6] border-b-[0.5px] ${
                    meal?.attributes?.declined_at
                      ? "text-[#566884]"
                      : "text-dark-gray"
                  }`}
                >
                  <td className="break-words pl-4 py-5 pr-3 text-sm font-inter-medium sm:pl-3">
                    {capitalizeFirstLetter(
                      meal?.attributes?.address?.attributes?.nickname
                    )}{" "}
                    ({meal?.attributes?.address?.attributes?.destination_code})
                  </td>
                  <td className="whitespace-nowrap px-3 text-sm font-inter-medium">
                    {meal?.attributes?.restaurant_name}
                  </td>
                  <td className="whitespace-nowrap px-3 text-sm font-inter-medium">
                    {dateTime.formatDate(
                      meal?.attributes?.meal_date,
                      dateTime.longOrdersDateFormat
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3  text-sm font-inter-medium">
                    {dayjs(meal?.attributes?.target_time).format("h:mm A")}
                  </td>
                  <td className="whitespace-nowrap px-3 text-sm font-inter-medium">
                    {meal?.attributes?.items_count}
                  </td>

                  <td className="relative whitespace-nowrap pl-3 pr-4 text-right text-sm sm:pr-3">
                    <td className="relative whitespace-nowrap pl-3 pr-4 text-right text-sm sm:pr-3">
                      <button
                        onClick={() =>
                          navigate(
                            `/meal-management/catering-order/${meal?.attributes?.orders[0]?.slug}/${locationId}`
                          )
                        }
                        className="btn-outline font-inter-medium border-2"
                      >
                        View order
                      </button>
                    </td>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {!isPresent(data) && (
            <div className="d-col w-full self-center justify-center items-center gap-2">
              <small className="text-secondary font-[14px] mt-[24px]">
                No upcoming catering orders
              </small>

              <button
                onClick={() => {
                  navigate("/our-services");
                }}
                className="btn-light-gray"
              >
                Learn about Catering
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function ListView() {
  const [viewBagModal, setViewBag] = useState(false);
  const {
    managePlan,
    setManagePlan,
    changeDeliveryTime,
    setChangeDeliveryTime,
    changeExpectedOrders,
    setChangeExpectedOrders,
    changeSubsidy,
    setChangeSubsidy,
    setChangeContact,
    changeContact,
    changeDeliveryInstructions,
    setChangeDeliveryInstructions,
    changeMealPlanName,
    setChangeMealPlanName,
    changeEnabledDays,
    setChangeEnabledDays,
    changeAddress,
    setChangeAddress,
    changeMealDate,
    setChangeMealDate,
  } = useManagePlan();
  const navigate = useNavigate();
  const location = useLocation();

  const { mealManagementStore, userStore, deliveryInfoStore } =
    useContext(MobXProviderContext);

  const mealPlans = mealManagementStore.mealPlans;
  const mealPlan = mealManagementStore.mealPlan;
  const groupOrders = mealManagementStore.groupOrders;
  const cateringOrders = mealManagementStore.cateringOrders;
  const defaultLocation = userStore.defaultLocation;
  const mealPlansPagy = mealManagementStore.mealPlansPagy;
  const groupOrdersPagy = mealManagementStore.groupOrdersPagy;
  const cateringOrdersPagy = mealManagementStore.cateringOrdersPagy;

  const [addressTimeModal, setAddressTimeModal] = useState(false);
  const [mealType, setMealType] = useState("");
  const [expandUncatering, setExpandUncatering] = useState(false);
  const [expandCatering, setExpandCatering] = useState(false);
  const [uncateringMealPlanPending, setUncateringMealPlanPending] =
    useState(false);

  useEffect(() => {
    if (defaultLocation?.organizationId) {
      deliveryInfoStore?.getLocationAddresses({
        location_id: defaultLocation?.organizationId,
      });

      mealManagementStore
        .fetchMealPlans(defaultLocation?.organizationId, {
          service_eq: "uncatering",
          per_page: 10,
        })
        .then(() =>
          mealManagementStore.fetchGroupOrders(
            defaultLocation?.organizationId,
            { per_page: 10 }
          )
        )
        .then(() =>
          mealManagementStore.fetchCateringOrders(
            defaultLocation?.organizationId,
            { per_page: 10 }
          )
        );
    }
  }, [defaultLocation?.organizationId]);

  const managePlanPress = (value, id) => {
    mealManagementStore.fetchMealPlan(defaultLocation?.organizationId, id);
    setManagePlan(value);
  };

  const getRequestStatus = (targetMapping) => {
    if (isPresent(mealPlan?.attributes?.change_requests)) {
      let request = [...mealPlan?.attributes?.change_requests]
        .reverse() // Reverse the array to traverse from last to first
        ?.find((change) => change?.attributes?.target_mapping == targetMapping);

      if (request) {
        if (targetMapping == "delivery_time")
          return `Requested update: ${request?.attributes?.change_request_items?.target_time?.new_value} - under review`;
        else if (targetMapping == "expected_bags") {
          return `Requested update: ${request?.attributes?.change_request_items?.expected_bags?.new_value} - under review`;
        } else if (targetMapping == "subsidy") {
          let subsidyType =
            request?.attributes?.change_request_items?.subsidy_type?.new_value;

          let text = "";
          if (subsidyType == "daily") {
            let newVal =
              request?.attributes?.change_request_items?.daily_subsidy
                ?.new_value;
            text = `Daily - Mon $${newVal?.monday}  Tue $${newVal?.tuesday}  Wed $${newVal?.wednesday}  Thu $${newVal?.thursday}  Fri $${newVal?.friday}  Sat $${newVal?.saturday}  Sun $${newVal?.sunday}`;
          } else
            text = `Weekly ${request?.attributes?.change_request_items?.subsidy?.new_value}`;
          return `Requested update: ${text} - under review`;
        } else if (targetMapping == "delivery_address") {
          return `Requested update: ${formattedAddres(
            request?.attributes?.change_request_items?.address_id?.new_value
          )} - under review`;
        } else if (targetMapping == "days") {
          let days = request?.attributes?.change_request_items?.days
            ? request?.attributes?.change_request_items?.days?.new_value
            : request?.attributes?.change_request_items?.dates_to_add
                ?.new_value;

          let text = `Days: ${days?.map((day) => day + " ")}`;

          return `Requested update: ${text} - under review`;
        }
      }
    }
    return "";
  };

  const handleNextClick = (mealType) => {
    if (mealType === "meal_plans") {
      mealManagementStore.fetchMealPlans(defaultLocation?.organizationId, {
        service_eq: "uncatering",
        per_page: 10,
        page: mealPlansPagy?.page + 1,
      });
    } else if (mealType === "group_orders") {
      mealManagementStore.fetchGroupOrders(defaultLocation?.organizationId, {
        per_page: 10,
        page: groupOrdersPagy?.page + 1,
      });
    } else if (mealType === "catering_orders") {
      mealManagementStore.fetchCateringOrders(defaultLocation?.organizationId, {
        per_page: 10,
        page: cateringOrdersPagy?.page + 1,
      });
    }
  };

  return (
    <div className="rounded-lg flex flex-col min-w-full">
      {managePlan ? (
        <div className="pt-[24px]">
          <NotificationBanner
            message={`Request is under review by hungerhub, this message will disappear once changes have been updated.`}
            messageType="warning"
            visible={mealPlan?.attributes?.changes_under_review}
            abilityToDismiss={false}
            hideClose={true}
            messageLink="Contact us"
            handleLinkClick={() => userStore.setFreshChatOpen(true)}
          />

          <button
            className="flex items-center cursor-pointer mb-[24px]"
            onClick={() => setManagePlan(false)}
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              style={{ width: 14, height: 14 }}
            />
            <span className="text-md text-left font-inter-medium text-black-light ml-3">
              Back
            </span>
          </button>

          <h2 className="font-inter-semibold text-[32px] text-dark-gray">
            Manage Meal Plan
          </h2>

          <div className="mt-[33px] bg-[#F4F4F4] px-[10px] py-[13px] rounded-lg inline-flex">
            <img
              src={InfoButton}
              alt="info-icon"
              className="w-[20px] h-[20px] mr-2"
            />
            <p className="font-inter-medium text-dark-gray text-[14px]">
              The changes made here will apply to all future meal orders in your
              meal plan series. To adjust a specific meal order, please go to
              request changes for a single day.
            </p>
          </div>

          <div className="d-row mt-[24px] items-center">
            <strong className="text-dark-gray text-[24px] font-inter-medium">
              {mealPlan?.attributes?.name}
            </strong>

            <EditPencilButton
              onClick={() => {
                setChangeMealPlanName(true);
              }}
            />
          </div>

          {isPresent(mealPlan?.attributes?.meals) && (
            <div className="d-row items-center justify-between my-[16px]">
              <small className="text-[#575BE8] font-inter-medium text-[16px] ">
                Manage the next upcoming order in meal plan series:{" "}
                {dayjs(mealPlan?.attributes?.meals[0]?.meal_date).format(
                  "MMMM D"
                )}
              </small>

              <button
                className="btn-outline"
                onClick={() => {
                  setChangeMealDate(true);
                }}
              >
                Request changes for a single day
              </button>
            </div>
          )}

          <div className="grid grid-cols-2 gap-6">
            <div className="h-[182px] bg-light-gray w-full rounded-lg p-[24px] d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Delivery time
              </span>
              <strong className="font-inter-semibold text-[16px] text-dark-gray">
                {dateTime.formatTimeWithFormat(
                  mealPlan?.attributes?.target_time,
                  "hh:mm a",
                  mealPlan?.attributes?.tzinfo
                )}
              </strong>
              <p className="text-dark-gray font-inter-medium text-[14px]">
                Please allow for a 15 minute window before/after requested time
              </p>

              {mealPlan?.attributes?.changes_under_review &&
                getRequestStatus("delivery_time") && (
                  <small className="text-[#566985] d-row items-center text-[14px] font-inter-medium">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color="#E39F55"
                      size="sm"
                      style={{ width: 14, height: 14 }}
                      className="small-icon mr-2"
                      data-class="my-tooltip"
                    />
                    {getRequestStatus("delivery_time")}
                  </small>
                )}

              <button
                className="btn-white p-[10px] w-[141px] mt-[12px] font-inter-medium text-[12px]"
                onClick={() => setChangeDeliveryTime(true)}
              >
                Request Changes
              </button>
            </div>

            <div className="h-[182px] bg-light-gray w-full rounded-lg p-[24px] d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Deadline to order
              </span>
              <strong className="font-inter-semibold text-[16px] text-dark-gray">
                {dateTime.formatTimeWithFormat(
                  mealPlan?.attributes?.cutoff,
                  "hh:mm a",
                  mealPlan?.attributes?.tzinfo
                )}
              </strong>
              <p className="text-dark-gray font-inter-medium text-[14px]">
                Deadline to order is set by hungerhub
              </p>
            </div>

            <div className="h-[182px] bg-light-gray w-full rounded-lg p-[24px] d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Expected Order{" "}
              </span>

              {mealPlan.attributes?.expected_bags_calculation_mode ===
              "same_day_average" ? (
                <div>
                  {object.isPresent(
                    mealPlan?.attributes?.parsed_expected_bags
                  ) &&
                    Object.entries(
                      mealPlan?.attributes?.parsed_expected_bags
                    ).map((key) => (
                      <small
                        key={key}
                        className="font-inter-semibold text-[16px] text-dark-gray"
                      >
                        {key[0]}: {key[1]}
                      </small>
                    ))}
                </div>
              ) : (
                <strong className="font-inter-semibold text-[16px] text-dark-gray">
                  {mealPlan.attributes?.expected_bags}
                </strong>
              )}
              <p className="text-dark-gray font-inter-medium text-[14px]">
                The number of people expected to place an order
              </p>

              {mealPlan?.attributes?.changes_under_review &&
                getRequestStatus("expected_bags") && (
                  <small className="text-[#566985] d-row items-center text-[14px] font-inter-medium">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color="#E39F55"
                      size="sm"
                      style={{ width: 14, height: 14 }}
                      className="small-icon mr-2"
                      data-class="my-tooltip"
                    />
                    {getRequestStatus("expected_bags")}
                  </small>
                )}

              <button
                className="btn-white p-[10px] w-[141px] mt-[12px] font-inter-medium text-[12px]"
                onClick={() => setChangeExpectedOrders(true)}
              >
                Update
              </button>
            </div>

            <div className="h-[182px] bg-light-gray w-full rounded-lg p-[24px] d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Enabled days
              </span>

              <div className="mb-0.5 w-full xs:w-full-215">
                <div className="lg:inline-flex md:inline-flex">
                  {weekDays.map((value, index) => (
                    <div
                      key={index}
                      className={classNames({
                        "lg:mr-3 md:mr-3 mt-[6px] lg:mt-0 md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] hover:bg-indigo-500 hover:text-white rounded-3xl justify-center gap-2 flex": true,
                        "bg-blue-900 text-white":
                          mealPlan?.attributes?.days.includes(
                            value?.toLowerCase()
                          ),
                        "bg-white text-dark-gray":
                          !mealPlan?.attributes?.days.includes(
                            value?.toLowerCase()
                          ),
                      })}

                      // onClick={() => handleRecievingDaysClick(value)}
                    >
                      {capitalizeFirstLetter(value.slice(0, 3))}
                    </div>
                  ))}
                </div>
              </div>

              {mealPlan?.attributes?.changes_under_review &&
                getRequestStatus("days") && (
                  <small className="text-[#566985] d-row items-center text-[14px] font-inter-medium">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color="#E39F55"
                      size="sm"
                      style={{ width: 14, height: 14 }}
                      className="small-icon mr-2"
                      data-class="my-tooltip"
                    />
                    {getRequestStatus("days")}
                  </small>
                )}

              <button
                className="btn-white p-[10px] w-[141px] mt-[12px] font-inter-medium text-[12px]"
                onClick={() => setChangeEnabledDays(true)}
              >
                Update
              </button>
            </div>

            <div className="min-h-[182px] bg-light-gray rounded-lg p-[24px] d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Meal subsidy
              </span>
              <strong className="font-inter-semibold text-dark-gray">
                {mealPlan?.attributes?.subsidy_type === "weekly" ? (
                  "Weekly " + priceFormatter(mealPlan?.attributes?.subsidy)
                ) : (
                  <div className="grid grid-cols-3 gap-2">
                    {mealPlan.attributes?.daily_subsidy &&
                      Object.keys(mealPlan.attributes?.daily_subsidy)?.map(
                        (day) => (
                          <div className="bg-white capitalize px-[16px] py-[12px] d-row rounded-3xl">
                            <small className="mr-2 font-inter-medium text-dark-gray text-[14px]">
                              {day}:
                            </small>
                            <small className="font-inter-medium text-dark-gray text-[14px]">
                              {priceFormatter(
                                mealPlan.attributes?.daily_subsidy[day]
                              )}
                            </small>
                          </div>
                        )
                      )}
                  </div>
                )}
              </strong>

              {/* {mealPlan?.attributes?.employee_pays_by_credit_card && ( */}
              <div className="d-col mt-2">
                <strong className="text-dark-gray font-inter-semibold tex-[16px]">
                  Overpayment enabled
                </strong>
                <small className="text-secondary font-inter-medium text-[14px]">
                  Allows individuals to charge their personal credit cards if
                  they go over allotted subisidy
                </small>
              </div>
              {/* )} */}

              {mealPlan?.attributes?.changes_under_review &&
                getRequestStatus("subsidy") && (
                  <small className="text-[#566985] d-row items-center text-[14px] font-inter-medium">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color="#E39F55"
                      size="sm"
                      style={{ width: 14, height: 14 }}
                      className="small-icon mr-2"
                      data-class="my-tooltip"
                    />
                    {getRequestStatus("subsidy")}
                  </small>
                )}

              <button
                className="btn-white p-[10px] w-[141px] mt-[12px] font-inter-medium text-[12px]"
                onClick={() => setChangeSubsidy(true)}
              >
                Update
              </button>
            </div>

            <div className="h-[182px] bg-light-gray rounded-lg p-[24px] d-col">
              <div className="d-row justify-between items-center">
                <span className="text-dark-gray font-inter-regular text-[16px]">
                  Teams added
                </span>

                <span className="text-dark-gray font-inter-regular text-[16px]">
                  {mealPlan?.attributes?.location_employees?.length} users in
                  total
                </span>
              </div>
              <strong className="font-inter-semibold text-dark-gray">
                {mealPlan?.attributes?.teams?.length} teams
              </strong>

              <button
                className="btn-white p-[10px] w-[141px] mt-[12px] font-inter-medium text-[12px]"
                onClick={() => {
                  navigate(`/meal-management/manage-teams/${mealPlan?.id}`);
                }}
              >
                Manage users
              </button>
            </div>

            <div className="p-[16px] bg-light-gray rounded-lg w-full items-start d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Delivery address details and point of contact
              </span>

              <small className="font-inter-semibold text-[16px] text-dark-gray">
                Delivery address
              </small>
              <small className="font-inter-medium text-[14px] text-dark-gray">
                {formattedAddres(mealPlan?.attributes?.address?.attributes)}
              </small>

              <small className="font-inter-semibold mt-[8px] text-dark-gray text-[16px]">
                Delivery instructions
              </small>
              <small className="font-inter-medium text-[14px] text-dark-gray">
                {
                  mealPlan?.attributes?.address?.attributes
                    ?.delivery_instructions
                }
              </small>

              <small className="font-inter-semibold mt-[8px] text-dark-gray text-[16px]">
                Point of contact
              </small>
              <small className="font-inter-medium text-[14px] text-dark-gray">
                {
                  mealPlan?.attributes?.address?.attributes
                    ?.delivery_contact_name
                }
              </small>

              {mealPlan?.attributes?.changes_under_review &&
                getRequestStatus("delivery_address") && (
                  <small className="text-[#566985] d-row items-center text-[14px] font-inter-medium">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color="#E39F55"
                      size="sm"
                      style={{ width: 14, height: 14 }}
                      className="small-icon mr-2"
                      data-class="my-tooltip"
                    />
                    {getRequestStatus("delivery_address")}
                  </small>
                )}

              <button
                onClick={() => {
                  setChangeAddress(true);
                }}
                className="btn-white p-[10px] w-[141px] mt-[12px] font-inter-medium text-[12px]"
              >
                Update
              </button>
            </div>
          </div>

          {changeDeliveryTime && (
            <ChangeDeliveryTime
              visible={changeDeliveryTime}
              setModal={setChangeDeliveryTime}
              time={mealPlan?.attributes?.target_time}
              tzinfo={mealPlan?.attributes?.tzinfo}
            />
          )}

          {changeExpectedOrders && (
            <ChangeExpectedOrders
              visible={changeExpectedOrders}
              setModal={setChangeExpectedOrders}
            />
          )}

          {changeSubsidy && (
            <ChangeSubsidy
              visible={changeSubsidy}
              setModal={setChangeSubsidy}
            />
          )}
          {changeContact && (
            <ChangeContact
              visible={changeContact}
              setModal={setChangeContact}
            />
          )}
          {changeDeliveryInstructions && (
            <ChangeDeliveryInstructions
              visible={changeDeliveryInstructions}
              setModal={setChangeDeliveryInstructions}
            />
          )}
          {changeMealPlanName && (
            <ChangeMealPlanName
              visible={changeMealPlanName}
              setModal={setChangeMealPlanName}
              placeholder={mealPlan?.attributes?.name}
            />
          )}
          {changeEnabledDays && (
            <ChangeEnabledDays
              visible={changeEnabledDays}
              setModal={setChangeEnabledDays}
            />
          )}
          {changeAddress && (
            <ChangeDeliveryAddress
              visible={changeAddress}
              setModal={setChangeAddress}
              mealPlan={mealPlan}
            />
          )}

          {changeMealDate && (
            <ChangeMealDate
              visible={changeMealDate}
              setModal={setChangeMealDate}
              meals={mealPlan?.attributes?.meals}
            />
          )}
        </div>
      ) : (
        <>
          <div className="d-row justify-between">
            <div className="d-col">
              <strong className="text-uncatering text-[20px] font-inter-medium">
                Active Uncatering plans
              </strong>
              <small className="font-inter-regular">
                Custom meals ordered by individual employees
              </small>
            </div>
            <div className="flex items-end">
              <button
                className={classNames({
                  "d-row items-center rounded-xl text-catering hover:text-white font-inter-bold": true,
                  "btn-blue-accent text-white": true,
                })}
                onClick={() => {
                  navigate("/get-started-uncatering");
                }}
              >
                + New Uncatering Plan
              </button>
            </div>
          </div>

          {isPresent(mealPlans) ? (
            <div>
              <UncateringPlans
                data={mealPlans}
                managePlanPress={(value, id) => managePlanPress(value, id)}
                tzinfo={defaultLocation?.tzinfo}
                setUncateringMealPlanPending={setUncateringMealPlanPending}
              />

              {mealPlansPagy?.next && (
                <div className="d-row justify-center w-full">
                  <button
                    onClick={() => {
                      handleNextClick("meal_plans");

                      window.scroll({ top: 0, left: 0, behavior: "smooth" });
                    }}
                    className="text-catering d-row items-center font-inter-medium text-[16px] mt-[18px]"
                  >
                    Show More Meal Plans
                    <FontAwesomeIcon
                      icon={expandUncatering ? faChevronLeft : faChevronRight}
                      style={{
                        color: "#575BE8",
                      }}
                      className="transform-left ml-2 transform rotate-90"
                    />
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="d-col w-full items-center">
              <NotFound
                data={mealPlans}
                message="No active meal plans enabled"
              />
              <button
                className="btn-disabled-mode"
                onClick={() => {
                  navigate("/our-services");
                }}
              >
                Learn about Uncatering
              </button>
            </div>
          )}

          <div className="d-row justify-between">
            <div className="d-col mt-[32px]">
              <strong className="text-group-order text-[20px] font-inter-medium">
                Upcoming Group Orders
              </strong>
              <small className="font-inter-regular">
                One-off custom meals ordered by individuals
              </small>
            </div>

            <div className="flex items-end">
              <button
                className={classNames({
                  "d-row items-center rounded-xl text-catering hover:text-white font-inter-bold": true,
                  "btn-blue-accent text-white": true,
                })}
                onClick={() => {
                  setMealType("Group");
                  setAddressTimeModal(true);
                }}
              >
                + New Group Order
              </button>
            </div>
          </div>

          <div>
            <UpcomingGroupOrders
              onClick={setViewBag}
              data={groupOrders}
              navigate={navigate}
              tzinfo={defaultLocation?.tzinfo}
              locationId={defaultLocation?.organizationId}
              handleNextClick={handleNextClick}
            />

            {groupOrdersPagy?.next && (
              <div className="d-row justify-center w-full">
                <button
                  onClick={() => {
                    handleNextClick("group_orders");
                  }}
                  className="text-catering d-row items-center font-inter-medium text-[16px] mt-[18px]"
                >
                  Show More Group Orders
                  <FontAwesomeIcon
                    icon={expandUncatering ? faChevronLeft : faChevronRight}
                    style={{
                      color: "#575BE8",
                    }}
                    className="transform-left ml-2 transform rotate-90"
                  />
                </button>
              </div>
            )}
          </div>

          <div className="d-row justify-between">
            <div className="d-col mt-[32px]">
              <strong className="text-catering text-[20px] font-inter-medium">
                Upcoming Catering Orders
              </strong>
              <small className="font-inter-regular">
                Catering order placed by one person on behalf of many
              </small>
            </div>

            <div className="flex items-end">
              <button
                className={classNames({
                  "d-row items-center rounded-xl text-catering hover:text-white font-inter-bold": true,
                  "btn-blue-accent text-white": true,
                })}
                onClick={() => {
                  setMealType("Catering");
                  setAddressTimeModal(true);
                }}
              >
                + New Catering Order
              </button>
            </div>
          </div>

          <UpcomingCateringOrders
            onClick={setViewBag}
            data={
              cateringOrders?.length > 5 && !expandCatering
                ? cateringOrders?.slice(0, 5)
                : cateringOrders
            }
            navigate={navigate}
            locationId={defaultLocation?.organizationId}
            handleNextClick={handleNextClick}
          />

          {cateringOrdersPagy?.next && (
            <div className="d-row justify-center w-full">
              <button
                onClick={() => {
                  handleNextClick("catering_orders");
                }}
                className="text-catering d-row items-center font-inter-medium text-[16px] mt-[18px]"
              >
                Show More Catering Orders
                <FontAwesomeIcon
                  icon={expandCatering ? faChevronLeft : faChevronRight}
                  style={{
                    color: "#575BE8",
                  }}
                  className="transform-left ml-2 transform rotate-90"
                />
              </button>
            </div>
          )}

          {viewBagModal && (
            <ViewBag
              // getAvailableTimes={getAvailableTimes}
              // getAvailableDates={getAvailableDates}
              visible={viewBagModal}
              // setApplied={setApplied}
              setModal={setViewBag}
            />
          )}

          <AddressTimeModal
            visibleModal={addressTimeModal}
            showModal={setAddressTimeModal}
            orderType={mealType}
          />

          {uncateringMealPlanPending && (
            <ConfirmationModal
              visible={uncateringMealPlanPending}
              setModal={setUncateringMealPlanPending}
              title="Uncatering request under review"
              body="You will be able to manage your uncatering meal plan as soon as we enable it."
              rightButtonText="Learn more about our services"
              onClickRightButton={() => navigate("/our-services")}
              hideCancel={true}
            />
          )}
        </>
      )}
    </div>
  );
}

export default observer(ListView);
