import { Dialog, Transition } from "@headlessui/react";
import React, {
  Fragment,
  useContext,
  useReducer,
  useState,
  useEffect,
} from "react";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select from "react-select";
import { MobXProviderContext } from "mobx-react";
import { errorHandler } from "utils/middlewares/errorHandler";
import MultiSelect from "components/MultiSelect";
import {
  reminderOptions,
  rolesForTeamAdmin,
  userRoles,
} from "utils/constants/common";
import InputError from "components/InputError/InputError";

function BulkUserEdit({
  visible,
  setModal,
  bulkUsers,
  teams,
  handleBulkUpdate,
}) {
  const initialState = {
    role: null,
    selectedTeams: null,
    reminder1: null,
    reminder2: null,
    reminder3: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);
  const store = useContext(MobXProviderContext);
  const { userStore } = useContext(MobXProviderContext);
  const { defaultLocation } = userStore;
  const [isSameRole, setIsSameRole] = useState(false);
  const [teamError, setTeamError] = useState(false);
  const [roleError, setRoleError] = useState(false);

  useEffect(() => {
    dispatch({ field: "role", value: null });
    dispatch({ field: "selectedTeams", value: null });
    dispatch({ field: "reminder1", value: null });
    dispatch({ field: "reminder2", value: null });
    dispatch({ field: "reminder3", value: null });

    setTeamError(false);
    setRoleError(false);

    checkAdminAndUser();
  }, [bulkUsers, visible]);

  const applyPressed = () => {
    if (!provider.role?.value && isSameRole) {
      setRoleError(true);
      return;
    }

    if (!provider.selectedTeams) {
      setTeamError(true);
      return;
    }

    let payload = {
      user: {
        first_reminder: provider.reminder1?.value,
        second_reminder: provider.reminder2?.value,
        third_reminder: provider.reminder3?.value,
      },
      location_employee: {
        role: provider.role?.value,
        team_ids: provider.selectedTeams?.map((team) => team?.value),
      },
    };

    store.userStore.setLoader(true);
    store.teamStore
      .updateBulkUsers(
        {
          location_id: defaultLocation?.organizationId,
          ids: bulkUsers?.map((user) => user?.attributes?.employee_id),
        },
        payload
      )
      .then((response) => {
        if (!response?.response?.data?.errors) {
          errorHandler({
            title: "Successfully updated all specified employees!",
          });
        }
        store.userStore.setLoader(false);
        handleBulkUpdate();
        setModal(false);
      });
  };

  const hanldePasswordResetLink = () => {
    store.userStore.setLoader(true);
    store.teamStore
      .resetPassword({
        location_id: defaultLocation?.organizationId,
        user_ids: bulkUsers?.map((user) => user?.attributes?.user_id),
      })
      .then((response) => {
        store.userStore.setLoader(false);
        if (response?.data?.message)
          errorHandler({
            title:
              "An email was just sent to the selected users with password reset instructions.",
          });
        handleBulkUpdate();
        setModal(false);
      });
  };

  const handleDeleteBulkUser = () => {
    store.userStore.setLoader(true);
    store.teamStore
      .deleteBulkUsers({
        location_id: defaultLocation?.organizationId,
        ids: bulkUsers?.map((user) => user?.attributes?.employee_id),
      })
      .then((response) => {
        store.userStore.setLoader(false);
        if (!response?.response?.data?.errors) {
          errorHandler({
            title: "Successfully deleted all specified employees!",
          });
        }
        handleBulkUpdate();
        setModal(false);
      });
  };

  function checkAdminAndUser() {
    let roles = bulkUsers?.map((user) => user?.attributes?.role);
    let allSameRole = new Set(roles)?.size === 1;

    if (allSameRole) {
      const validRoles = ["User", "Account admin", "Team admin"];
      if (validRoles.includes(roles[0])) {
        setIsSameRole(true);
      } else {
        setIsSameRole(false);
      }
    } else {
      setIsSameRole(false);
    }
  }

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[696px] d-col justify-between rounded-xl transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="d-col">
                  <div className="d-row justify-between items-center mb-4">
                    <h2 className="text-2xl text-[#3a3a3a] font-inter-semibold">
                      Bulk Users Edit
                    </h2>
                    <button
                      onClick={() => setModal(false)}
                      className="text-dark-gray hover:text-yellow-600"
                      aria-label="Close"
                    >
                      <span className="text-xl">&times;</span>
                    </button>
                  </div>

                  {isSameRole && (
                    <div className="d-col items-start py-1.5 text-sm select-non cursor-pointer">
                      <span className="ml-1 font-inter-semibold text-[18px] mb-[8px] text-sm w-40 text-[#2d3958]">
                        Role*
                      </span>

                      <Select
                        required
                        styles={customStylesSubsidy({
                          preferences: true,
                          noBorder: true,
                        })}
                        aria-labelledby="sort-by"
                        aria-label="sort-by"
                        placeholder="Select role"
                        closeMenuOnSelect
                        value={provider.role}
                        onChange={(option) => {
                          setRoleError(false);
                          dispatch({
                            field: "role",
                            value: option,
                          });
                        }}
                        options={
                          defaultLocation?.role == "team_admin"
                            ? rolesForTeamAdmin
                            : userRoles
                        }
                        className="w-full rounded-xl"
                      />

                      {roleError && (
                        <InputError error="This is required field." />
                      )}
                    </div>
                  )}

                  <div className="d-col items-start py-1.5 text-sm select-non cursor-pointer">
                    <span className="ml-1 font-inter-semibold text-[18px] mb-[8px] text-sm w-40 text-[#2d3958]">
                      Attach to teams*
                    </span>

                    <MultiSelect
                      options={teams}
                      selectedOptions={provider.selectedTeams}
                      handleChange={(options) => {
                        setTeamError(false);
                        dispatch({
                          field: "selectedTeams",
                          value: options,
                        });
                      }}
                      placeholderValue="Choose teams"
                      handleRemoveOption={(updatedSelectedTeams) => {
                        dispatch({
                          field: "selectedTeams",
                          value: updatedSelectedTeams,
                        });
                      }}
                    />

                    {teamError && (
                      <InputError error="This is required field." />
                    )}
                  </div>

                  <div className="d-col items-start py-1.5 text-sm select-non cursor-pointer">
                    <span className="ml-1 font-inter-semibold text-[18px] mb-[8px] text-sm w-40 text-[#2d3958]">
                      Reminders
                    </span>

                    <Select
                      styles={customStylesSubsidy({
                        preferences: true,
                        noBorder: true,
                      })}
                      aria-labelledby="sort-by"
                      aria-label="sort-by"
                      placeholder="Reminder 1"
                      closeMenuOnSelect
                      value={provider.reminder1}
                      onChange={(option) => {
                        dispatch({
                          field: "reminder1",
                          value: option,
                        });
                      }}
                      options={reminderOptions}
                      className="w-full rounded-xl"
                      menuShouldScrollIntoView={true}
                      maxMenuHeight={140}
                    />

                    <Select
                      styles={customStylesSubsidy({
                        preferences: true,
                        noBorder: true,
                      })}
                      aria-labelledby="sort-by"
                      aria-label="sort-by"
                      placeholder="Reminder 2"
                      closeMenuOnSelect
                      value={provider.reminder2}
                      onChange={(option) => {
                        dispatch({
                          field: "reminder2",
                          value: option,
                        });
                      }}
                      options={reminderOptions}
                      className="w-full rounded-xl mt-4"
                      menuShouldScrollIntoView={true}
                      maxMenuHeight={140}
                    />

                    <Select
                      styles={customStylesSubsidy({
                        preferences: true,
                        noBorder: true,
                      })}
                      aria-labelledby="sort-by"
                      aria-label="sort-by"
                      placeholder="Reminder 3"
                      closeMenuOnSelect
                      value={provider.reminder3}
                      onChange={(option) => {
                        dispatch({
                          field: "reminder3",
                          value: option,
                        });
                      }}
                      options={reminderOptions}
                      className="w-full rounded-xl mt-4"
                      menuShouldScrollIntoView={true}
                      maxMenuHeight={140}
                    />
                  </div>

                  <div className="d-row w-full gap-4 py-1.5">
                    <div className="w-1/2 bg-background rounded-xl flex items-center justify-center">
                      <button
                        className="h-12 px-[19px] py-[9px] bg-[#f7f9ff] rounded-xl text-[#2d3958] text-base font-inter-medium"
                        onClick={hanldePasswordResetLink}
                      >
                        Send a reset link
                      </button>
                    </div>

                    <div className="w-1/2 bg-background rounded-xl flex items-center justify-center">
                      <button
                        className="h-12 px-[19px] py-[9px] bg-[#f7f9ff] rounded-xl text-[#d56c76] text-base font-inter-medium"
                        onClick={handleDeleteBulkUser}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>

                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3"
                    onClick={applyPressed}
                  >
                    Apply
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default BulkUserEdit;
