import classNames from "classnames";
import React, { useEffect, useRef } from "react";

const SelectionModal = ({
  options,
  selectedOptions,
  handleTypeChange,
  handleCloseModal,
  modalPosition,
  isCheckbox,
  isDotsDropdown,
  handleOptionClick,
  filterType,
}) => {
  const modalRef = useRef(null);
  const optionsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        !modalRef.current?.contains(event.target) &&
        !optionsRef.current?.contains(event.target)
      ) {
        handleCloseModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleCloseModal]);

  return (
    <div
      className="absolute z-50 rounded-xl border border-[#dee2e6] py-2 bg-white"
      style={{
        left: modalPosition?.left - 15,
      }}
    >
      <div
        ref={modalRef}
        className={classNames(
          "bg-white rounded-xl",
          isCheckbox && options?.length > 2
            ? "h-[152px] w-48"
            : isCheckbox
            ? "h-[112px] w-48"
            : "h-[92px] w-40"
        )}
      >
        {isCheckbox ? (
          <div>
            {options?.map((option) => (
              <div key={option.value} className="p-4 hover:bg-[#f3f3f3]">
                <label className="text-[#2d3958] text-sm font-inter-normal leading-none">
                  <input
                    type="checkbox"
                    name={option.value}
                    checked={selectedOptions?.includes(option.value)}
                    onChange={(e) => handleTypeChange(e, filterType)}
                    className="mr-2 cursor-pointer"
                  />
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <div>
            {options?.map((option, index) => (
              <div key={index} className="px-4 py-3 hover:bg-[#f3f3f3]">
                <div
                  className={classNames(
                    "text-sm font-inter-normal leading-none inline-flex gap-2 cursor-pointer",
                    isDotsDropdown ? "text-[#575be8]" : "text-[#2d3958]"
                  )}
                  onClick={(e) => {
                    handleOptionClick(option?.value, filterType);
                    handleCloseModal();
                  }}
                >
                  {option?.icon}
                  {option?.label}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectionModal;
