import moment from "moment-timezone";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const toLocalTime = (time) => {
  return moment(time).local().format("hh:mm A");
};

const getLocalTimezone = () => {
  let visitorTz = moment.tz.guess();
  let momentDateTime = moment.utc().tz(visitorTz);

  //compare first 3 letters to check if abbr available for this timezone:
  let z1 = momentDateTime.format("Z"); //'+06:00'
  let z2 = momentDateTime.format("z"); //PDT|AEST|IST|'+06'

  let tzAbbr = z1.substring(0, 3) == z2.substring(0, 3) ? `(${z2} GMT)` : z2;

  return tzAbbr;
};

const formatTimeWithSeconds = (time) => {
  return moment(time, "HH:mm:ss").format("hh:mm A");
};

const formatTime = (time, timeZone = "America/New_York") => {
  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  let _time = moment(time).local().utcOffset(offset).format("h:mm A");

  return _time === "Invalid date"
    ? `${time} ${checkAndGetTimezone(timeZone)}`
    : `${_time} ${checkAndGetTimezone(timeZone)}`;
};

const formatTimeToTimezone = (time, timeZone = "America/New_York") => {
  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  let _time = moment(time, "h:mm a").local().utcOffset(offset).format("h:mm a");

  return _time === "Invalid date" ? `${time}` : `${_time}`;
};

const formatTimeWithFormat = (
  time,
  toFormat,
  timeZone = "America/New_York"
) => {
  if (!timeZone) return "";

  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  let _time = moment(time).local().utcOffset(offset).format(toFormat);

  return _time === "Invalid date"
    ? `${moment(time, "hh:mm:ss").format(toFormat)} ${checkAndGetTimezone(
        timeZone
      )}`
    : `${_time} ${checkAndGetTimezone(timeZone)}`;
};

const formatDate = (date, format) => {
  return moment(date).local().format(format);
};

const formatDateWithtimzone = (date, format, timeZone = "America/New_York") => {
  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  return moment(date).local().utcOffset(offset).format(format);
};

const formatDateWithTimzoneInfo = (
  date,
  format,
  timeZone = "America/New_York"
) => {
  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  return `${moment(date)
    .local()
    .utcOffset(offset)
    .format(format)} - ${checkAndGetTimezone(timeZone)}`;
};

const onlyDate = (date) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return typeof date === "string" ? date.split("T")[0] : ""; // Only split if it's a string.
};

const onlyTime = (date) => {
  return (date && date?.split("T")[1]) || "";
};

const isToday = (date) => {
  return moment().isSame(moment(date).local(), "day");
};

const subtractTime = (time, minutes, timeZone) => {
  let _time = moment(time, "HH:mm a")
    .subtract(minutes, "minutes")
    .format("hh:mm a");

  return _time === "Invalid date"
    ? `${time || ""} ${checkAndGetTimezone(timeZone)}`
    : `${_time} ${checkAndGetTimezone(timeZone)}`;
};

const to12hourformat = (time, timeZone = "America/New_York") => {
  return `${moment(time, "HH:mm a").format("hh:mm a")} ${checkAndGetTimezone(
    timeZone
  )}`;
};

const to24hourformat = (time) => {
  return moment(time, "hh:mm A").format("HH:mm");
};

const localWithUTCoffset = (time, timezone = "America/New_York", toFormat) => {
  let offset = moment().tz(timezone)?.utcOffset();
  return moment(time).local().utcOffset(offset).format(toFormat);
};

const withtzInfo = (time, timezone = "America/New_York") => {
  if (time === "00:00") return "00:00";
  let withDate = moment(time, "HH:mm").toDate();
  return moment.tz(moment(withDate).format("HH:mm"), "HH:mm", timezone);
};

const utcwithtzInfo = (time, date, timezone = "America/New_York") => {
  if (time === "00:00") return "00:00";
  let dateTime = moment(date + " " + time, "YYYY-MM-DD HH:mm");
  return moment
    .tz(
      moment(dateTime).format("YYYY-MM-DD HH:mm"),
      "YYYY-MM-DD HH:mm",
      timezone
    )
    .utc();
};

export const isSameDay = (date) => {
  return moment(moment().format("l")).isSame(moment(date).format("l"));
};

export const ifSelectedTimeAfterCurrent = (time) => {
  return moment(moment().format("HH:mm"), "HH:mm").isBefore(
    moment(time, "HH:mm")
  );
};

export const checkAndGetTimezone = (timezone = "America/New_York", dash) => {
  if (!timezone) return "";

  let TZ = timezone == "" ? "America/New_York" : timezone;
  let timeZoneAbbre = moment().tz(TZ).zoneAbbr();

  return moment().tz(TZ).utcOffset() === moment().utcOffset()
    ? ""
    : dash
    ? ` - ${timeZoneAbbre}`
    : timeZoneAbbre;
};

export const dateWithNowTime = (date) => {
  return moment(date)
    .set({
      hour: moment().hour(),
      minute: moment().minute(),
      second: moment().second(),
    })
    .toDate();
};

export const isClosed = (specialHours) => {
  return (
    specialHours?.filter(
      (hour) =>
        hour.attributes.from === "00:00:00" && hour.attributes.to === "00:00:00"
    ).length == 1 && specialHours.length == 1
  );
};
export const isEarlierThanEndLimit = (timeValue, endLimit, lastValue) => {
  let timeValueIsEarlier =
    moment(timeValue, "h:mm a").diff(moment(endLimit, "h:mm a")) < 0;
  let timeValueIsLaterThanLastValue =
    lastValue === undefined
      ? true
      : moment(lastValue, "h:mm a").diff(moment(timeValue, "h:mm a")) < 0;

  return timeValueIsEarlier && timeValueIsLaterThanLastValue;
};

const enumerateDaysBetweenDates = (startDate, endDate) => {
  var now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.toDate());
    now.add(1, "days");
  }
  return dates;
};

export const getToBeExcludedDates = (
  activeRestaurant,
  dateToStartEnumeration
) => {
  let advanceHours =
    activeRestaurant?.attributes?.accept_order_hours_in_advance;

  let currDate = moment();
  let minDateTimeRequired = moment().add(advanceHours, "hours");
  let datesBetween = [];
  let disableDates = [];

  datesBetween = enumerateDaysBetweenDates(currDate, minDateTimeRequired);

  datesBetween.forEach((date) => {
    if (moment(dateWithNowTime(date)).isBefore(minDateTimeRequired, "day")) {
      disableDates.push(date);
    }
  });

  const operatingHours = activeRestaurant?.attributes?.operating_hours;
  const specialHours = activeRestaurant?.attributes?.special_hours;

  let formattedEnumerationStartDate =
    moment(dateToStartEnumeration).month() == 0
      ? moment({
          year: moment(dateToStartEnumeration).year() - 1,
          month: 11,
          day: 1,
        })
      : moment({
          year: moment(dateToStartEnumeration).year(),
          month: moment(dateToStartEnumeration).month() - 1,
          day: 1,
        });

  enumerateDaysBetweenDates(
    formattedEnumerationStartDate,
    moment(formattedEnumerationStartDate).add(80, "days")
  )?.forEach((futureDate) => {
    const day = moment(futureDate).format("dddd").toLowerCase();

    if (
      moment(dateWithNowTime(futureDate)).isBefore(minDateTimeRequired, "day")
    ) {
      disableDates.push(futureDate);
    }

    if (
      operatingHours?.filter(
        (hours) =>
          hours.attributes.day.toLowerCase() == day && !hours.attributes.active
      ).length > 0
    ) {
      disableDates.push(futureDate);
    }

    if (
      !(
        operatingHours?.filter(
          (hours) =>
            hours.attributes.day.toLowerCase() == day && hours.attributes.active
        ).length > 0
      )
    ) {
      disableDates.push(futureDate);
    }

    const specialHoursForDate = getSpecialHoursForDate(
      specialHours,
      futureDate
    );

    if (isClosed(specialHoursForDate)) {
      disableDates.push(futureDate);
    } else {
      if (specialHoursForDate?.length > 0)
        disableDates = disableDates.filter(
          (date) =>
            moment(date).format("YYYY-MM-DD") !==
            moment(
              specialHoursForDate[0]?.attributes?.date?.split("T")[0]
            ).format("YYYY-MM-DD")
        );
    }
  });

  return disableDates;
};

export const checkIsDateAvailable = (activeRestaurant, date, time) => {
  let day = moment(date).format("dddd").toLowerCase();
  let operatingHours = activeRestaurant?.attributes?.operating_hours;
  let specialHours = activeRestaurant?.attributes?.special_hours;

  let specialHoursForDate = getSpecialHoursForDate(specialHours, date);

  if (isClosed(specialHoursForDate)) return false;

  let specialFromTime =
    moment(
      specialHoursForDate && specialHoursForDate[0]?.attributes.from,
      "HH:mm"
    ).format("h:mm a") || "12:00 am";

  let specialToTime =
    moment(
      specialHoursForDate && specialHoursForDate[0]?.attributes.to,
      "HH:mm"
    ).format("h:mm a") || "11:59 pm";

  if (
    moment(
      moment(date).format("YYYY-MM-DD") + " " + time,
      dateTimeFormatLong
    ).isSameOrAfter(
      moment(
        moment(date).format("YYYY-MM-DD") + " " + specialFromTime,
        dateTimeFormatLong
      )
    ) &&
    moment(
      moment(date).format("YYYY-MM-DD") + " " + time,
      dateTimeFormatLong
    ).isSameOrBefore(
      moment(
        moment(date).format("YYYY-MM-DD") + " " + specialToTime,
        dateTimeFormatLong
      )
    )
  ) {
    return true;
  }

  if (
    operatingHours?.filter(
      (hours) =>
        hours.attributes.day.toLowerCase() == day && !hours.attributes.active
    )?.length > 0
  ) {
    return false;
  }

  let operatingHoursForDate = date
    ? operatingHours?.filter(
        (hours) =>
          hours.attributes.day.toLowerCase() == day && hours.attributes.active
      )
    : operatingHours;

  let fromTime =
    moment(
      operatingHoursForDate && operatingHoursForDate[0]?.attributes.from,
      "HH:mm"
    ).format("h:mm a") || "12:00 am";

  let toTime =
    moment(
      operatingHoursForDate && operatingHoursForDate[0]?.attributes.to,
      "HH:mm"
    ).format("h:mm a") || "11:59 pm";

  if (
    !(
      moment(
        moment(date).format("YYYY-MM-DD") + " " + time,
        dateTimeFormatLong
      ).isSameOrAfter(
        moment(
          moment(date).format("YYYY-MM-DD") + " " + fromTime,
          dateTimeFormatLong
        )
      ) &&
      moment(
        moment(date).format("YYYY-MM-DD") + " " + time,
        dateTimeFormatLong
      ).isSameOrBefore(
        moment(
          moment(date).format("YYYY-MM-DD") + " " + toTime,
          dateTimeFormatLong
        )
      )
    )
  ) {
    return false;
  }

  return true;
};

export const getOptionsWithDisabled = (activeRestaurant, date, options) => {
  let advanceHours =
    activeRestaurant?.attributes?.accept_order_hours_in_advance;

  let format = "YYYY-MM-DD HH:mm:ss";

  let convertedTime = moment
    .tz(moment(), activeRestaurant?.attributes?.tzinfo)
    .format(format);

  let minDateTimeRequired = moment(convertedTime, format)
    .add(advanceHours, "hours")
    .format(format);

  return options
    .map((option) => {
      if (
        moment(
          `${moment(date).format("YYYY-MM-DD")} ${option?.label}`,
          dateTimeFormatLong
        ).isSameOrBefore(moment(minDateTimeRequired, format)) ||
        moment(
          `${moment(date).format("YYYY-MM-DD")} ${option?.label}`,
          dateTimeFormatLong
        ).isSameOrBefore(
          moment(moment().format(dateTimeFormatLong), dateTimeFormatLong)
        )
      )
        return { ...option, disabled: true };

      return option;
    })
    .filter((option) => !option.disabled);
};

export const getTimeOptionsWithDisabledInDirecory = (selectedDate, options) => {
  return options
    ?.map((option) => {
      if (
        moment(
          `${moment(selectedDate).format("YYYY-MM-DD")} ${option?.label}`,
          "YYYY-MM-DD h:mm a"
        ).isSameOrBefore(moment())
      )
        return { ...option, disabled: true };
      return option;
    })
    .filter((option) => !option.disabled);
};

const getSpecialHoursForDate = (specialHours, date) => {
  return specialHours?.filter((hours) =>
    moment(hours.attributes.date?.split("T")[0], "YYYY-MM-DD").isSame(
      moment(moment(date).format("YYYY-MM-DD"), "YYYY-MM-DD")
    )
  );
};

export const getOperatingHoursOptionsForDropdown = (
  activeRestaurant,
  date,
  addressTimezone
) => {
  let options = [];
  let operatingHours = activeRestaurant?.attributes?.operating_hours;
  let specialHours = activeRestaurant?.attributes?.special_hours;
  let timeZone = activeRestaurant?.attributes?.tzinfo;

  let day = moment(date).format("dddd").toLowerCase();

  let operatingHoursForDate = date
    ? operatingHours?.filter(
        (hours) =>
          hours.attributes.day.toLowerCase() == day && hours.attributes.active
      )
    : operatingHours;

  let specialHoursForDate = getSpecialHoursForDate(specialHours, date);

  if (isClosed(specialHoursForDate)) return [];

  operatingHoursForDate =
    operatingHoursForDate?.length > 0 ? operatingHoursForDate : [];

  let timeValue =
    moment(
      operatingHoursForDate && operatingHoursForDate[0]?.attributes.from,
      "HH:mm"
    ).format("h:mm a") || "12:00 am";
  let lastValue;
  let endLimit =
    moment(
      operatingHoursForDate && operatingHoursForDate[0]?.attributes.to,
      "HH:mm"
    ).format("h:mm a") || "11:59 pm";
  let step = 15;

  if (timeValue !== "Invalid date")
    options.push({
      label: `${timeValue} ${addressTimezone ?? ""}`,
      value: timeValue,
    });

  while (isEarlierThanEndLimit(timeValue, endLimit, lastValue)) {
    lastValue = timeValue;

    timeValue = moment(timeValue, "h:mm a")
      .add(step, "minutes")
      .format("h:mm a");

    if (timeValue !== "Invalid date")
      options.push({
        label: `${timeValue} ${addressTimezone ?? ""}`,
        value: timeValue,
      });
  }

  specialHoursForDate =
    specialHoursForDate?.length > 0 ? specialHoursForDate : null;

  specialHoursForDate
    ?.filter(
      (hour) =>
        hour.attributes.from !== "00:00:00" && hour.attributes.to !== "00:00:00"
    )
    .forEach((hour) => {
      let timeValue = moment(hour.attributes.from, "HH:mm:ss").format("h:mm a");
      let endLimit = moment(hour.attributes.to, "HH:mm:ss").format("h:mm a");
      let lastValue;

      options.push({
        label: `${timeValue} ${addressTimezone ?? ""}`,
        value: timeValue,
      });

      while (isEarlierThanEndLimit(timeValue, endLimit, lastValue)) {
        lastValue = timeValue;

        timeValue = moment(timeValue, "h:mm a")
          .add(step, "minutes")
          .format("h:mm a");

        options.push({
          label: `${timeValue} ${addressTimezone ?? ""}`,
          value: timeValue,
        });
      }
    });

  //Filter duplicate options
  options = options.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.value === value.value)
  );

  return getOptionsWithDisabled(activeRestaurant, date, options);
};
export const getDeliveryWindow = (deliveryTime, orderDate) => {
  const parsedDate = dayjs(orderDate, "YYYY-MM-DD");
  const parsedTime = dayjs(deliveryTime, "h:mm A");

  if (!parsedDate.isValid() || !parsedTime.isValid()) {
    console.error("Invalid date or time format");
    return "Invalid Date";
  }

  const originalTime = parsedDate
    .hour(parsedTime.hour())
    .minute(parsedTime.minute());

  const timeBefore = originalTime.subtract(15, "minute");
  const timeAfter = originalTime.add(15, "minute");

  const result = `${timeBefore.format("h:mm A")} - ${timeAfter.format(
    "h:mm A"
  )}`;
  return result;
};

const to12hourWithFormat = (
  time,
  timeZone = "America/New_York",
  format = "h:mm A"
) => {
  return (
    time &&
    `${moment(time, format).format(format)} ${checkAndGetTimezone(timeZone)}`
  );
};

export const cartTimeFormat = (time, timeZone) =>
  `${time} ${checkAndGetTimezone(timeZone)}`;

export const subtractOneHour = (timeStr) => {
  const newTime = moment(timeStr, "HH:mm").subtract(1, "hour");
  return newTime.format("HH:mm");
};
export const timeFormat = "h:mm a";
export const longDateFormat = "dddd - MMMM Do, yyyy";

export const longDateFormatWithShortDay = "ddd, MMMM Do, yyyy";
export const longDateFormatWithLongDay = "dddd, MMMM Do, yyyy";

export const dateTimeFormatLong = "YYYY-MM-DD h:mm a";
export const dateFormat = "YYYY-MM-DD";
export const deadlineFormat = "YYYY-MM-DD hh:mm A";

export const ordersDateFormat = "MMM DD, YYYY";

export const smallDateFormat = "MMMM DD, hh:mm a";

export const longOrdersDateFormat = "MMMM DD, YYYY";

export default {
  formatTime,
  formatDate,
  isToday,
  subtractTime,
  localWithUTCoffset,
  formatTimeWithSeconds,
  toLocalTime,
  withtzInfo,
  checkAndGetTimezone,
  formatTimeWithFormat,
  getLocalTimezone,
  formatDateWithtimzone,
  formatDateWithTimzoneInfo,
  to12hourformat,
  utcwithtzInfo,
  to24hourformat,
  onlyDate,
  onlyTime,
  isSameDay,
  ifSelectedTimeAfterCurrent,
  dateWithNowTime,
  formatTimeToTimezone,
  timeFormat,
  longOrdersDateFormat,
  getDeliveryWindow,
  longDateFormatWithLongDay,
  to12hourWithFormat,
};
