import classNames from "classnames";
import { removeUnderscore } from "utils/helpers/string";

const MenuElementStatus = ({ status, light = false, mediumSized = false }) => {
  return (
    <strong
      className={classNames(
        "input-label w-1/3 self-end text-right capitalize font-inter-medium",
        {
          "text-white": light,
          "text-dark-purple": !light,
          "text-sm": mediumSized,
          "text-xs": !mediumSized,
          visible: status === "sold_out" || status === "in_active",
          invisible: status !== "sold_out" && status !== "in_active",
        }
      )}
    >
      {removeUnderscore(status)}
    </strong>
  );
};

export default MenuElementStatus;
