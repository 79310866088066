import { observer } from "mobx-react";
import { useContext, useEffect, useReducer, useState } from "react";
import { MobXProviderContext } from "mobx-react";
import { NotFound, SearchBar } from "components";
import { customStylesSubsidy } from "utils/helpers/styles";
import ReactPaginate from "react-paginate";
import moment from "moment-timezone";
import { dateFormat, ordersDateFormat } from "utils/helpers/dateTime";
import { priceFormatter } from "utils/helpers/validation";
import Select from "react-select";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import CheckboxDropdown from "components/CheckboxDropdown";
import dayjs from "dayjs";
import { Transition } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "assets/img";
import { isPresent } from "utils/helpers/array";
import { sortingOptions } from "utils/constants/common";

const ExpandableRow = ({ index, order, locationId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const service = order?.attributes?.service;

  const onClickViewOrder = (attributes) => {
    if (attributes?.service == "uncatering")
      navigate(`/order-history/uncatering-order/${attributes?.meal_id}`);
    else if (attributes?.service == "grouped")
      navigate(
        `/group-order/admin/orders/${attributes?.meal_series_id}/${locationId}`
      );
  };

  const shouldShowViewOrderButton = (order) => {
    if (!order?.attributes) return false;
    const { service, orders, status, declined_at } = order?.attributes;
    return (
      service !== "odc" &&
      isPresent(orders) &&
      status !== "cancelled" &&
      status !== "No Orders" &&
      declined_at !== ""
    );
  };

  return (
    <div className="w-full">
      {/* Row Header */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          key={`order-${index}`}
          className={classNames(
            "flex cursor-pointer items-center border-b border-border-gray py-6 md:py-7 last:border-0 px-5 lg:w-full w-[1114px] grid grid-cols-7",
            {
              "bg-slate-50": index % 2 !== 0,
            }
          )}
        >
          <div className="flex flex-col">
            <span
              className={classNames("text-sm capitalize font-inter-medium", {
                "text-pink-600": service === "uncatering",
                "text-indigo-900": service === "grouped" || service === "odc",
              })}
            >
              {service === "grouped"
                ? "Group Order"
                : service === "odc"
                ? "Catering"
                : service}
            </span>
          </div>

          <div className="text-sm font-inter-medium text-slate-700">
            {moment(order?.attributes?.meal_date, dateFormat).format(
              ordersDateFormat
            )}
          </div>

          <div className="text-sm font-inter-medium text-slate-700">
            {dayjs(order?.attributes?.target_time).format("h:mm a")}
          </div>

          <div className="text-sm font-inter-medium text-slate-700">
            {order?.attributes?.service == "uncatering"
              ? order?.attributes?.menu_display_name_uncatering
              : (order?.attributes?.service == "odc" &&
                  order?.attributes?.orders?.length <= 1) ||
                order?.attributes?.service == "grouped"
              ? order?.attributes?.menu_display_name_odc
              : "Restaurant"}
          </div>

          <div className="text-sm font-inter-medium text-slate-700">
            {order?.attributes?.address?.attributes?.nickname} (
            {order?.attributes?.address?.attributes?.destination_code})
          </div>

          <div className="text-sm font-inter-medium text-slate-700 items-center pl-8">
            {priceFormatter(order?.attributes?.food_total)}
          </div>

          {shouldShowViewOrderButton(order) && (
            <div className="flex items-center justify-center">
              <button
                type="button"
                onClick={() => onClickViewOrder(order?.attributes)}
                className="flex w-auto btn-style items-center justify-center p-[10px] border-hh-accent-light lg:border text-hh-accent-light hover:text-hh-accent-dark rounded-2xl"
              >
                <span className="text-sm font-inter-medium">View Order</span>
              </button>
            </div>
          )}

          {service == "odc" &&
            order?.attributes?.orders?.length <= 1 &&
            order?.attributes?.orders[0]?.status !== "cancelled" &&
            order?.attributes?.declined_at == null && (
              <div className="flex items-center justify-center">
                <button
                  type="button"
                  onClick={() => {
                    navigate(
                      `/meal-management/catering-order/${order?.attributes?.orders[0]?.slug}/${locationId}`
                    );
                  }}
                  className="flex w-auto btn-style items-center justify-center p-[10px] border-hh-accent-light lg:border text-hh-accent-light hover:text-hh-accent-dark rounded-2xl"
                >
                  <span className="text-sm font-inter-medium">View Order</span>
                </button>
              </div>
            )}

          {service == "odc" && order?.attributes?.orders?.length > 1 && (
            <div className="d-row items-center justify-center">
              <img
                src={isOpen ? ChevronLeftIcon : ChevronRightIcon}
                className="h-[20px] w-[20px] transform-left transform rotate-90"
              />
            </div>
          )}
        </div>
      </div>

      {/* Expandable Content */}
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        {order?.attributes?.orders?.map((orders, ind, arr) => {
          return (
            <div
              key={`order-${ind}`}
              className={classNames(
                "flex cursor-pointer items-center py-6 md:py-7 px-5 lg:w-full w-[1114px] grid grid-cols-7"
              )}
            >
              <div className="flex flex-col"></div>

              <div className="text-sm font-inter-medium text-slate-700"></div>

              <div className="text-sm font-inter-medium text-slate-700"></div>

              <div className="text-sm font-inter-medium text-slate-700">
                {order?.attributes?.menu_display_name_odc}
              </div>

              <div className="text-sm font-inter-medium text-slate-700"></div>

              <div className="text-sm font-inter-medium text-slate-700 items-center pl-8">
                {priceFormatter(orders?.food_total)}
              </div>

              <div className="d-row items-center justify-center md:px-1">
                {service == "odc" &&
                  isPresent(arr) &&
                  orders?.status !== "cancelled" && (
                    <button
                      onClick={() => {
                        navigate(
                          `/meal-management/catering-order/${orders?.slug}/${locationId}`
                        );
                      }}
                      className="flex w-auto btn-style items-center justify-center p-[10px] border-hh-accent-light lg:border text-hh-accent-light hover:text-hh-accent-dark rounded-2xl"
                    >
                      <span className="text-sm font-inter-medium">
                        View Order
                      </span>
                    </button>
                  )}
              </div>

              <div></div>
            </div>
          );
        })}
      </Transition>
    </div>
  );
};

const OrderHistory = () => {
  const initialState = {
    searchText: "",
    orders: [],
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const store = useContext(MobXProviderContext);
  const userStore = store?.userStore;
  let { defaultLocation } = userStore;
  let { searchText, orders } = provider;
  const [sortByDate, setSortByDate] = useState([]);
  const [ordersPagy, setOrdersPagy] = useState([]);
  const [selectedServiceOptions, setSelectedServiceOptions] = useState([]);
  const [selectedService, setSelectedService] = useState([]);

  useEffect(() => {
    getOrders();
  }, [searchText, sortByDate, selectedService]);

  const getOrders = (page = 1) => {
    store.userStore?.setLoader(true);
    store.groupOrderStore
      .getAllOrders(
        {
          search_q: searchText ?? "",
          sort_by: sortByDate?.value ?? "",
          page,
          per_page: 7,
          service: selectedService,
        },
        defaultLocation?.organizationId
      )
      .then((response) => {
        store.userStore?.setLoader(false);
        if (!response?.data?.errors) {
          dispatch({ field: "orders", value: response?.data });
          setOrdersPagy(response?.pagy);
        }
      })
      .catch(() => store.userStore?.setLoader(false));
  };

  const pageClick = (data) => {
    getOrders(data.selected + 1);
  };

  const handleServiceChange = (selected) => {
    const mappedValue = {
      Catering: "odc",
      "Group Order": "grouped",
      Uncatering: "uncatering",
    };

    if (Array.isArray(selected)) {
      const selectedValues = selected?.map((value) => mappedValue[value]);
      setSelectedService(selectedValues);
    } else {
      const selectedValue = mappedValue[selected];
      setSelectedService(selectedValue);
    }
    setSelectedServiceOptions(selected);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-5 md:p-[40px] gap-[24px] pb-32">
      <div className="flex items-center justify-between">
        <span className="text-[32px] font-inter-semibold">Order History</span>
      </div>

      <div className="lg:d-row d-col items-center justify-between">
        <div className="lg:w-1/3 w-full d-row items-center sm:justify-between md:justify-between lg:justify-start">
          <SearchBar
            value={searchText}
            placeholder="Search"
            handleOnChange={(e) => {
              dispatch({ field: "searchText", value: e.target.value });
            }}
            width="lg:w-[274px] md:w-[274px] w-full"
          />
        </div>

        <div className="w-full lg:mt-0 mt-6 d-row justify-between">
          <div className="d-row items-center mr-4">
            <small className="font-inter-regular tex-sm mx-[10px]">
              Sort by:
            </small>
            <Select
              styles={customStylesSubsidy({ preference: true })}
              aria-labelledby="sort-by"
              aria-label="sort-by"
              placeholder="Date"
              closeMenuOnSelect
              onChange={setSortByDate}
              className="w-[125px]"
              options={sortingOptions}
            />
          </div>

          <CheckboxDropdown
            options={["Catering", "Group Order", "Uncatering"]}
            prompt="Filter by type"
            selectedOption={selectedServiceOptions}
            onChange={(value) => handleServiceChange(value)}
          />
        </div>
      </div>

      <div className="mt-6 border rounded-tl-xl rounded-tr-xl overflow-x-auto">
        <div className="flex lg:w-full w-[1114px] bg-background px-5 py-6 rounded-tl-xl rounded-tr-xl border-b bg-slate-50 grid grid-cols-7">
          <div className="flex text-sm font-inter-semibold text-indigo-900">
            Type
          </div>

          <div className="flex text-right items-center sm:text-left text-sm font-inter-semibold text-slate-700">
            Delivery Date
          </div>

          <div className="text-sm font-inter-semibold text-slate-700">
            Delivery Time
          </div>

          <div className="text-sm font-inter-semibold md:mr-4 text-slate-700">
            Restaurant
          </div>

          <div className="text-sm font-inter-semibold md:mr-4 text-slate-700">
            Address
          </div>

          <div className="text-sm font-inter-semibold text-slate-700 pl-8">
            Total
          </div>

          <div />
        </div>

        {orders?.map((order, index) => (
          <ExpandableRow
            index={index}
            order={order}
            locationId={defaultLocation?.organizationId}
          />
        ))}
      </div>

      <NotFound data={orders} message="No orders found" />

      {ordersPagy && ordersPagy?.count > 0 && (
        <div className="w-full flex items-center justify-center mt-12 text-primary-color">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={<span className="gap">...</span>}
            pageCount={ordersPagy?.pages}
            onPageChange={pageClick}
            forcePage={ordersPagy?.page - 1 > 0 ? ordersPagy?.page - 1 : 0}
            containerClassName={"pagination"}
            previousLinkClassName={"previous_page"}
            nextLinkClassName={"next_page"}
            pageClassName={"page_number"}
            activeClassName={"active_page"}
          />
        </div>
      )}
    </div>
  );
};

export default observer(OrderHistory);
