import { Dialog, Transition } from "@headlessui/react";
import React, {
  Fragment,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";
import { MobXProviderContext, observer } from "mobx-react";
import { CloudUpload } from "assets/img";
import { NotificationBanner } from "components";
import { isPresent } from "utils/helpers/array";

function UploadCSV({
  visible,
  setModal,
  setErrors,
  setRecordError,
  setIsSuccessResponse,
  setIsErrorResponse,
}) {
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [invalidFormatError, setInvalidFormatError] = useState(false);
  const fileInputRef = useRef(null);
  const store = useContext(MobXProviderContext);
  const userStore = store.userStore;
  const { defaultLocation } = userStore;

  useEffect(() => {
    setErrors([]);
    setRecordError(false);
    setIsSuccessResponse(false);
    setIsErrorResponse(false);
  }, []);

  const handleUploadFile = (file) => {
    setInvalidFormatError(false);
    setShowSuccessNotification(false);

    const formData = new FormData();
    formData.append("file", file);

    store.userStore.setLoader(true);
    store.teamStore
      .createBulkUsers(
        {
          location_id: defaultLocation?.organizationId,
        },
        formData,
        true
      )
      .then((response) => {
        const responseErrors = response?.response?.data?.errors;
        if (isPresent(responseErrors)) {
          const rowErrors = responseErrors?.filter((error) =>
            error?.startsWith("Row")
          );

          if (isPresent(rowErrors)) {
            if (responseErrors?.length <= 15) {
              setRecordError(true);
              setErrors(responseErrors);
              setIsErrorResponse(true);
            } else {
              sessionStorage.setItem(
                "errorsData",
                JSON.stringify(responseErrors)
              );

              window.open("/uncatering/team/errors", "_blank");
            }
          } else {
            setRecordError(false);
            setErrors([]);
            setIsErrorResponse(true);
          }
        } else setIsSuccessResponse(true);

        fileInputRef.current.value = "";
        setModal(false);
        store.userStore.setLoader(false);
      });
  };

  const downloadTemplate = () => {
    const filePath = "/sample.csv";
    const link = document.createElement("a");
    link.href = filePath;
    link.download = "template.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return;
    else handleUploadFile(selectedFile);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[696px] d-col items-center justify-between rounded-xl transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-[37px] pb-8 py-[40px] text-left align-middle shadow-xl transition-all">
                <div className="d-row w-full justify-between items-center">
                  <h2 className="text-2xl font-inter-bold">Upload CSV</h2>
                  <button
                    onClick={() => setModal(false)}
                    className="text-dark-gray hover:text-yellow-600"
                    aria-label="Close"
                  >
                    <span className="text-xl">&times;</span>
                  </button>
                </div>

                <div className="w-full px-4 py-6 rounded-3xl border border-[#f7f9ff] flex flex-col justify-center items-center mt-4">
                  <NotificationBanner
                    message="File was successfully uploaded"
                    messageType="success"
                    textColor="success"
                    visible={showSuccessNotification}
                    setVisible={setShowSuccessNotification}
                  />

                  <NotificationBanner
                    message="Error! Please make sure you are using the template CSV before uploading"
                    messageType="error"
                    textColorDanger={true}
                    visible={invalidFormatError}
                    setVisible={setInvalidFormatError}
                  />

                  <img src={CloudUpload} className="mb-[12px]" />

                  <span className="font-inter-medium text-sm text-dark-gray">
                    {" "}
                    Drag your file here to upload
                  </span>

                  {!showSuccessNotification && !invalidFormatError && (
                    <p className="text-dark-gray font-inter-medium text-sm max-w-[439px] text-center mt-[24px]">
                      Uploaded files need to be in the same format as the CSV
                      template provided by hungerhub
                    </p>
                  )}

                  <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    id="csv-upload"
                    className="mt-4 hidden"
                    ref={fileInputRef}
                  />

                  <div className="w-[248px] h-[0px] border border-[#f7f9ff] mt-[24px]"></div>

                  <div className="d-row w-full items-center mt-[24px] justify-center">
                    <button
                      className="btn-light-gray text-heading btn-style py-3 px-5"
                      onClick={downloadTemplate}
                    >
                      Download CSV template
                    </button>

                    <button
                      className="btn-purple bg-catering btn-style py-3 px-6 ml-3"
                      onClick={handleButtonClick}
                    >
                      Upload file
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default observer(UploadCSV);
