import dateTime from "./dateTime";
import moment from "moment-timezone";
import { toJS } from "mobx";

export const checkAndGetTimezone = (timezone, dash) => {
  let timeZoneAbbre = moment().tz(timezone).zoneAbbr();

  return moment().tz(timezone).utcOffset() === moment().local().utcOffset()
    ? ""
    : `${dash ? " - " : ""}${timeZoneAbbre}`;
};

export const extractETA = (data, timezone = "America/New_York") => {
  // Do not change the order of these if statements
  // This is to catch a special case when we don't want to display any ETA info
  if (!data?.delivery_started && data?.delivered)
    return "ORDER SENT TO RESTAURANT";
  if (data?.delivered) return "ORDER DELIVERED";
  if (!data?.delivery_eta) return "ORDER SENT TO RESTAURANT";
  if (data?.delivery_delay_reason && data?.delivery_delay_reason?.trim() !== "")
    return data?.delivery_delay_reason;
  let now = moment(new Date());
  let eta = moment(data?.delivery_eta).local();
  let duration = Math.floor(moment.duration(eta.diff(now)).asMinutes());

  if (duration > 45)
    return (
      "ETA " +
      moment(eta).format("h:mm a") +
      checkAndGetTimezone(timezone, true)
    );
  else if (duration > 2) return "ETA " + duration + " minutes";
  else if (duration <= 2) return "ORDER ARRIVING";
};

export const uncateringShipmentStatus = (
  shipment,
  timezone = "America/New_York",
  mealTargetTime
) => {
  return shipment?.status == "completed"
    ? "Delivered"
    : shipment?.status == "cancelled"
    ? "Cancelled"
    : (shipment?.status == "sent" || shipment?.status == "confirmed") &&
      !shipment?.eta?.delivery_eta
    ? `Expected delivery time:
      ${dateTime.to12hourWithFormat(
        dateTime?.onlyTime(mealTargetTime),
        timezone,
        "hh:mm A"
      )}
      `
    : (shipment?.status == "sent" || shipment?.status == "confirmed") &&
      shipment?.eta?.delivery_eta
    ? extractETA(shipment?.eta, timezone)
    : shipment?.stopped
    ? "At capacity (no additional orders)"
    : shipment?.status == "preview"
    ? "Accepting orders (no cancellations)"
    : shipment?.status == "No orders"
    ? "No orders"
    : shipment?.declined_at
    ? "Cancelled"
    : (shipment?.published_at || shipment?.confirmed_at) && "Accepting orders";
};

export const getETA = (eta, timezone) => {
  return `${dateTime.localWithUTCoffset(
    eta?.delivery_eta,
    timezone,
    "hh:mm a"
  )} ${dateTime.checkAndGetTimezone(timezone, true)}`;
};

export const getETAString = (eta, timezone) => {
  if (eta?.delivery_eta) {
    let now = moment(new Date());
    let eta = moment(eta?.delivery_eta).utc();
    let duration = Math.floor(moment.duration(eta.diff(now)).asMinutes());
    if (duration < 5) return "Arriving";
    return `ETA ${getETA(eta, timezone)}`;
  }
};

export const cateringShipmentStatus = (
  shipment,
  timezone = "America/New_York"
) => {
  return shipment?.status == "completed"
    ? "Delivered"
    : shipment?.status == "cancelled"
    ? "Cancelled"
    : shipment?.status == "confirmed" && !shipment?.eta?.delivery_eta
    ? "Order arriving"
    : shipment?.status == "confirmed" && shipment?.eta?.delivery_eta
    ? getETAString(shipment?.eta, timezone)
    : shipment?.status == "sent" || shipment?.status == "confirmed"
    ? "In Progress"
    : shipment?.status == "preview"
    ? "In Progress"
    : shipment?.declined_at
    ? "Cancelled"
    : shipment?.confirmed_at
    ? "Order Confirmed"
    : shipment?.published_at
    ? "Order Sent to Restaurant"
    : "";
};

export const groupedShipmentStatus = (
  shipment,
  timezone = "America/New_York"
) => {
  return shipment?.status == "completed"
    ? "Delivered"
    : shipment?.status == "cancelled"
    ? "Cancelled"
    : shipment?.status == "confirmed" &&
      shipment?.eta?.delivery_eta == null &&
      shipment?.eta?.delivery_started == false
    ? "In Progress"
    : shipment?.status == "confirmed" && shipment?.eta?.delivery_eta
    ? getETAString(shipment?.eta, timezone)
    : shipment?.confirmed_at
    ? "Order Confirmed"
    : shipment?.status == "sent" ||
      shipment?.status == "confirmed" ||
      shipment?.status == "preview"
    ? "In Progress"
    : shipment?.declined_at
    ? "Cancelled"
    : shipment?.published_at
    ? "Order Sent to Restaurant"
    : "";
};
