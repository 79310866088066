import moment from "moment-timezone";

export const getOrderStatus = (status, shipmentAcceptanceStatus) => {
  switch (status) {
    case "completed":
      return "Delivered";
    case "preview":
    case "sent":
    case "confirmed":
      return "In Progress";
    case "pending":
      return !shipmentAcceptanceStatus ? "Order Placed" : "Confirmed";

    default:
      return status;
  }
};

export const getOrderStatusForAttendee = (attendeeStatus, orderStatus) => {
  if (orderStatus == "confirmed") {
    switch (attendeeStatus) {
      case "placed":
        return "Confirmed";
      default:
        return attendeeStatus;
    }
  } else return attendeeStatus;
};

export const checkIfCapacityReached = (str) => {
  return str?.includes("cannot accept additional orders");
};

export const checkAndGetTimezone = (timezone, dash) => {
  let timeZoneAbbre = moment().tz(timezone).zoneAbbr();

  return moment().tz(timezone).utcOffset() === moment().local().utcOffset()
    ? ""
    : `${dash ? " - " : ""}${timeZoneAbbre}`;
};

export const extractETA = (data, timezone = "America/New_York") => {
  // Do not change the order of these if statements
  // This is to catch a special case when we don't want to display any ETA info
  if (!data?.delivery_started && data?.delivered)
    return "ORDER SENT TO RESTAURANT";
  if (data?.delivered) return "ORDER DELIVERED";
  if (!data?.delivery_eta) return "ORDER SENT TO RESTAURANT";
  if (data?.delivery_delay_reason && data?.delivery_delay_reason?.trim() !== "")
    return data?.delivery_delay_reason;
  let now = moment(new Date());
  let eta = moment(data?.delivery_eta).local();
  let duration = Math.floor(moment.duration(eta.diff(now)).asMinutes());

  if (duration > 45)
    return (
      "ETA " +
      moment(eta).format("h:mm a") +
      checkAndGetTimezone(timezone, true)
    );
  else if (duration > 2) return "ETA " + duration + " minutes";
  else if (duration <= 2) return "ORDER ARRIVING";
};

export const creditApplicable = (cart) => {
  const credit = parseFloat(cart?.available_credit);
  if (credit <= 0) return 0;

  return needToPayBeforeCredit(cart) < credit
    ? needToPayBeforeCredit(cart)
    : getAdjustedCredit(cart);
};

export const getAdjustedCredit = (cart) => {
  const credit = parseFloat(cart?.available_credit);
  const creditDifference = getCreditDifference();

  if (creditDifference <= 0.2) return creditDifference + credit;
  else if (needToPayBeforeCredit(cart) > credit) return credit;
  else return 0;
};

export const getCreditDifference = (cart) => {
  const credit = parseFloat(cart?.available_credit);

  return parseFloat((needToPayBeforeCredit(cart) - credit).toFixed(2));
};

export const needToPayBeforeCredit = (cart) => {
  return parseFloat(cart?.payable_amount);
};

export const needToPay = (cart) => {
  let amountToPay = (
    needToPayBeforeCredit(cart) - creditApplicable(cart)
  ).toFixed(2);

  if (amountToPay <= parseFloat(0.2) && amountToPay > 0) {
    return 0;
  } else {
    return needToPayBeforeCredit(cart) - creditApplicable(cart);
  }
};
