import { set, toJS } from "mobx";
import { observer } from "mobx-react";
import { useContext, useEffect, useReducer, useState } from "react";
import { MobXProviderContext } from "mobx-react";
import moment from "moment-timezone";
import {
  dateFormat,
  dateTimeFormatLong,
  deadlineFormat,
  longDateFormatWithLongDay,
  timeFormat,
} from "utils/helpers/dateTime";
import { handleKeyDown } from "utils/helpers/validation";
import classNames from "classnames";
import { isPresent } from "utils/helpers/array";
import { TIP_VALUES_DESKTOP, TIP_VALUES_MOBILE } from "utils/constants/common";
import TipButton from "components/TipButton";
import { preventMinus, preventPasteNegative } from "utils/helpers/validation";
import dateTime from "utils/helpers/dateTime";
import { useNavigate } from "react-router-dom";
import TooltipNew from "components/Tooltip";
import mealManagementStore from "mobXstore/mealManagementStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const notificationMethods = [
  { id: "no", title: "No, only use these instructions for this order" },
  {
    id: "yes",
    title: "Yes, use for this and all future orders with this address",
  },
];

const NewGroupOrder = () => {
  const initialState = {
    groupOrderName: null,
    perPersonBudget: null,
    expectedAttendees: null,
    selectedDate: null,
    selectedTime: null,
    selectedDateUser: null,
    selectedTimeUser: null,
    times: [],
    userTimes: [],
    dateError: false,
    timeError: false,
    userDateError: false,
    userTimeError: false,
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    error: false,
    phoneError: "",
    tip: "15",
    otherTip: "",
    deliveryInstructionsLevel: "no",
    emailError: "",
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const cartStore = toJS(store?.cartStore);
  const restaurantStore = toJS(store?.restaurantStore);
  const paymentInfoStore = toJS(store?.paymentInfoStore);
  const deliveryInfoStore = toJS(store?.deliveryInfoStore);
  const [addressDeliveryInstructions, setAddressDeliveryInstructions] =
    useState("");
  const [navigationResponse, setNavigationResponse] = useState(null);
  const [existingDeliveryAddress, setExistingDeliveryAddress] = useState("");

  let {
    currentUser,
    selectedAddress,
    selectedDate,
    selectedTime,
    defaultLocation,
  } = userStore ?? {};
  let { activeRestaurant } = restaurantStore;
  let { defaultSelectedCard, defaultPaymentMethodId } = paymentInfoStore;
  let { locationAddresses } = deliveryInfoStore;
  let { orderDeadline, orderDeadlineTime, currentGroupOrder } =
    mealManagementStore;

  let { cart } = cartStore ?? {};
  const router = null;
  const navigate = useNavigate();

  let {
    groupOrderName,
    perPersonBudget,
    expectedAttendees,
    selectedDateUser,
    selectedTimeUser,
    dateError,
    timeError,
    userDateError,
    userTimeError,
    otherTip,
  } = provider;

  const moveToRestaurantDetails = () => {
    navigate(-1);
    store.userStore?.setLoader(true);
  };

  useEffect(() => {
    if (navigationResponse?.data?.attributes) {
      navigate(
        `/group-order/confirmation/${navigationResponse?.data?.attributes?.id}`
      );
    }
  }, [navigationResponse]);

  useEffect(() => {
    if (selectedDate && dateError)
      dispatch({ field: "dateError", value: false });
    if (selectedTime && timeError)
      dispatch({ field: "timeError", value: false });
    if (selectedDateUser && userDateError)
      dispatch({ field: "userDateError", value: false });
    if (selectedTimeUser && userTimeError)
      dispatch({ field: "userTimeError", value: false });
  }, [selectedDate, selectedTime, selectedDateUser, selectedTimeUser]);

  useEffect(() => {
    if (isPresent(locationAddresses) && selectedAddress?.value) {
      const address = locationAddresses?.find(
        (address) => address.id === selectedAddress?.value
      );
      const deliveryInstruction = address?.attributes?.delivery_instructions;
      setAddressDeliveryInstructions(deliveryInstruction ?? "");
      setExistingDeliveryAddress(deliveryInstruction ?? "");
    }
  }, [store.userStore.isHydrated, router?.isReady]);

  useEffect(() => {
    dispatch({ field: "reset", value: null });
    if (currentGroupOrder?.attributes) {
      setValues();
    }

    if (defaultLocation?.role !== "team_admin") {
      store?.userStore?.setLoader(true);
      store.paymentInfoStore
        .getLocationCreditCards({
          location_id: defaultLocation?.organizationId,
        })
        .then(() => store?.userStore?.setLoader(false));
    }

    fetchOrderDeadline();
  }, []);

  useEffect(() => {
    if (selectedDate && selectedDateUser) {
      getGroupOrderTimes(selectedDate, true);
      getGroupOrderTimes(selectedDateUser, false);
    }
  }, [selectedDate, selectedDateUser]);

  useEffect(() => {
    if (selectedDate && selectedDateUser) {
      getGroupOrderTimes(selectedDateUser, false);
    }
  }, [selectedTime]);

  const setValues = () => {
    const attendees = currentGroupOrder?.attributes?.expected_attendees;
    const budget = currentGroupOrder?.attributes?.per_person_budget;

    dispatch({
      field: "groupOrderName",
      value: currentGroupOrder?.attributes?.title,
    });

    dispatch({
      field: "perPersonBudget",
      value: budget,
    });

    dispatch({
      field: "expectedAttendees",
      value: attendees,
    });

    const adminDate = new Date(currentGroupOrder?.attributes?.admin_deadline);
    const isFutureDate = moment(adminDate).isSameOrAfter(moment());
    const adminTime = moment(
      currentGroupOrder?.attributes?.admin_deadline,
      deadlineFormat
    ).format(timeFormat);
    const adminDeadline = `${moment(adminDate).format(
      dateFormat
    )} ${adminTime}`;

    if (isFutureDate) {
      dispatch({
        field: "selectedDate",
        value: adminDate,
      });

      getGroupOrderTimes(adminDate, true, {
        expectedAttendees: attendees,
        perPersonBudget: budget,
      });

      getDatesForGroupOrder(attendees, budget, true);

      dispatch({
        field: "selectedTime",
        value: adminTime,
      });

      const userDate = new Date(
        currentGroupOrder?.attributes?.attendee_deadline
      );

      dispatch({
        field: "selectedDateUser",
        value: userDate,
      });

      dispatch({
        field: "selectedTimeUser",
        value: moment(
          currentGroupOrder?.attributes?.attendee_deadline,
          deadlineFormat
        ).format(timeFormat),
      });

      getDatesForGroupOrder(attendees, budget, false, adminDeadline);

      getGroupOrderTimes(userDate, false, {
        expectedAttendees: attendees,
        perPersonBudget: budget,
        adminDate,
        adminTime,
      });
    }
  };

  const submit = (e) => {
    e.preventDefault();

    fetchOrderDeadline();

    let payload = {
      meal_series: {
        service: "grouped",
        name: groupOrderName,
        address_id: selectedAddress?.value,
        subsidy: perPersonBudget,
        expected_bags: expectedAttendees,
        status: "active",
        cutoff: orderDeadlineTime,
        start_date: moment(selectedDate).format("YYYY-MM-DD"),
        dates_to_add: [moment(selectedDate).format("YYYY-MM-DD")],
        meals_attributes: [
          {
            subsidy: perPersonBudget,
            meal_date: moment(selectedDate).format("YYYY-MM-DD"),
            target_time: dateTime.to24hourformat(selectedTime),
            cutoff: orderDeadlineTime,
            tip: provider?.tip === "other" ? provider?.otherTip : provider?.tip,
            shipments_attributes: [
              {
                menu_id: activeRestaurant?.attributes?.id,
                service: "grouped",
                source_of_business: "hungerhub",
              },
            ],
            invitation_links_attributes: [
              {
                subsidy: perPersonBudget,
              },
            ],
          },
        ],
      },
    };

    if (provider.deliveryInstructionsLevel == "no") {
      payload.meal_series.delivery_instructions = addressDeliveryInstructions;
    }

    if (defaultPaymentMethodId) {
      payload.meal_series.stripe_card_id = defaultPaymentMethodId;
    }

    store?.userStore?.setLoader(true);

    if (currentGroupOrder?.attributes?.slug) {
      store?.mealManagementStore
        ?.updateGroupOrder(currentGroupOrder?.attributes?.slug, payload)
        .then((response) => {
          store?.userStore?.setLoader(false);

          setNavigationResponse(response);
        });
    } else {
      let params = {
        location_id: defaultLocation?.organizationId,
      };

      if (provider.deliveryInstructionsLevel == "yes")
        params.delivery_instructions = addressDeliveryInstructions;

      store?.mealManagementStore
        .createGroupOrder(payload, params)
        .then((response) => {
          store?.userStore?.setLoader(false);
          setNavigationResponse(response);
        });
    }
  };

  const getDatesForGroupOrder = (attendees, budget, adminDeadline) => {
    const _expectedAttendees = attendees || expectedAttendees;
    const _perPersonBudget = budget || perPersonBudget;

    if (_expectedAttendees && _perPersonBudget && router?.query?.menu_id) {
      let payload = {
        menu_id: router?.query?.menu_id,
        attendee: _expectedAttendees,
        budget_per_attendee: _perPersonBudget,
        order_date: moment(cart?.cartDate).format(dateFormat),
        delivery_time: cart?.cartTime,
        admin: true,
      };

      store?.groupOrderStore.getDatesForGroupOrder(payload);
    }
  };

  const getGroupOrderTimes = (date, data) => {
    let payload = {
      menu_id: router?.query?.menu_id,
      attendee: Math.abs(data?.expectedAttendees ?? expectedAttendees),
      budget_per_attendee: data?.perPersonBudget ?? perPersonBudget,
      selected_date: moment(cart?.cartDate).format(dateFormat),
      current_date_time: moment().format(dateTimeFormatLong),
      delivery_time: cart?.cartTime,
      admin: true,
      admin_date: moment(date).format(dateFormat),
    };

    store?.groupOrderStore.getTimesForGroupOrder(payload).then((response) => {
      dispatch({
        field: "times",
        value: response?.timeslots,
      });

      const isPastAdminDeadline = moment(
        `${moment(selectedDate).format(dateFormat)} ${selectedTime}`,
        "YYYY-MM-DD h:mm a"
      ).isBefore(moment());

      const isPastUserDeadline = moment(
        `${moment(selectedDateUser).format(dateFormat)} ${selectedTimeUser}`,
        "YYYY-MM-DD h:mm a"
      ).isBefore(moment());

      if (
        selectedTime &&
        (!isPresent(response?.timeslots) || isPastAdminDeadline)
      ) {
        dispatch({ field: "selectedTime", value: null });
      }
    });
  };

  const fieldsValidated = () => {
    return groupOrderName && perPersonBudget && expectedAttendees;
  };

  const fetchOrderDeadline = () => {
    if (provider.expectedAttendees && provider.perPersonBudget) {
      const _date = moment(selectedDate).format("YYYY-MM-DD");
      const _time = dateTime.to24hourformat(selectedTime);
      const params = {
        budget: provider.expectedAttendees * provider.perPersonBudget,
        target_time: dateTime.to24hourformat(_time),
        meal_date: _date,
      };

      if (_date && _time) {
        store?.userStore?.setLoader(true);

        store?.mealManagementStore
          .fetchOrderDeadline(activeRestaurant?.attributes?.id, params)
          .then(() => store?.userStore?.setLoader(false));
      }
    }
  };

  useEffect(() => {
    fetchOrderDeadline();
  }, [provider.expectedAttendees, provider.perPersonBudget]);

  return (
    <>
      <div
        className="flex justify-center bg-white min-h-[calc(100vh-200px)] mx-auto p-5 sm:p-10 md:p-8 sm:mt-[30px] md:mt-0"
        style={{ maxWidth: "1440px" }}
      >
        <div className="flex flex-col mt-[2%] w-full pb-40 sm:pb-0">
          <div
            className="text-[#232777] text-base font-inter-normal leading-tight cursor-pointer"
            onClick={moveToRestaurantDetails}
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              style={{ width: 14, height: 14 }}
            />
            <span className="ml-2">Back to restaurant</span>
          </div>

          <div className="flex flex-col text-center">
            <span className="text-md font-inter-semibold text-hh-accent-light">
              Step 2 of {defaultPaymentMethodId ? 3 : 4}
            </span>

            <span className="text-[24px] font-inter-semibold text-black-light mt-1.5">
              Complete Group Order Details
            </span>
          </div>

          <div className="bg-light-gray rounded-md w-full d-col p-[12px] mt-[32px]">
            <div className="mb-[12px] text-[#3a3a3a]">
              <small className="font-inter-medium text-base text-heading">
                Restaurant:{" "}
              </small>
              <span className="font-inter-normal text-base leading-tight">
                {activeRestaurant?.attributes?.display_name}
              </span>
            </div>

            <div className="mb-[12px] text-[#3a3a3a]">
              <small className="font-inter-medium text-base text-heading">
                Delivery Address:{" "}
              </small>
              <span className="font-inter-normal text-base leading-tight">
                {selectedAddress?.label}
              </span>
            </div>

            <div className="mb-[12px] text-[#3a3a3a]">
              <small className="font-inter-medium text-base text-heading">
                Delivery Date:{" "}
              </small>
              <span className="font-inter-normal text-base leading-tight">
                {moment(selectedDate).format(longDateFormatWithLongDay)}
              </span>
            </div>

            <div className="mb-[12px] text-[#3a3a3a]">
              <small className="font-inter-medium text-base text-heading">
                Delivery Time:{" "}
              </small>
              <span className="font-inter-normal text-base leading-tight">
                {selectedTime}
              </span>
            </div>

            <div className="mb-[12px] text-[#3a3a3a]">
              <small className="font-inter-medium text-base text-heading">
                Order deadline:{" "}
              </small>
              <span className="font-inter-normal text-base leading-tight">
                {orderDeadline || "-"}
              </span>
            </div>

            <div className="mb-[12px] text-[#3a3a3a] inline-flex">
              <small className="font-inter-medium text-base text-heading">
                Payment method{" "}
              </small>

              <span className="d-row">
                <TooltipNew
                  warningInfoIcon={!defaultPaymentMethodId}
                  indigoInfIcon={defaultPaymentMethodId}
                  tooltipText={
                    defaultPaymentMethodId
                      ? "Your selected payment method will be used to automatically process this order at the deadline. You can update it later in your Admin Panel settings."
                      : "You can update this later in your admin panel."
                  }
                  id="payment-method-tooltip"
                  backgroundColor="#EDEEF1"
                  textColor="#3A3A3A"
                  place="right"
                />
                {defaultPaymentMethodId
                  ? `: ${defaultSelectedCard?.brand} ending in ${defaultSelectedCard?.last4}`
                  : "None selected"}
              </span>
            </div>
          </div>

          <form onSubmit={submit} autoComplete="off" className="w-[593px]">
            <div className="flex flex-col mt-8">
              <span className="text-md2 font-inter-semibold">
                Group order name
              </span>

              <input
                required
                name={"groupOrderNameField"}
                className="input-gray-bg focus:ring-0 address-inputs my-0 rounded-xl text-md w-full bg-background pl-4 mt-3"
                placeholder="ex. Friday lunch & Learn"
                value={groupOrderName}
                onChange={(e) =>
                  dispatch({ field: "groupOrderName", value: e.target.value })
                }
              />
            </div>

            <div className="flex flex-col mt-6">
              <div className=" flex flex-col sm:grid sm:grid-cols-2 sm:gap-3">
                <div className="d-col">
                  <span className="text-md2 font-inter-semibold mb-3">
                    Budget per person
                  </span>

                  <div className="flex sm:mb-0 items-center text-md rounded-xl bg-background w-full py-2 px-3 h-12">
                    <span className="mt-[2px]">
                      {perPersonBudget ? "$" : ""}
                    </span>

                    <input
                      required
                      name={"perPersonBudgetField"}
                      className="input-gray-bg focus:ring-0 address-inputs my-0 rounded-xl text-md w-full bg-background"
                      value={perPersonBudget}
                      onWheelCapture={(e) => {
                        e.target.blur();
                      }}
                      onKeyDown={handleKeyDown}
                      type="number"
                      onChange={(e) =>
                        dispatch({
                          field: "perPersonBudget",
                          value: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="d-col">
                  <span className="text-md2 font-inter-semibold mb-3">
                    Expected number of invitees
                  </span>
                  <input
                    required
                    name={"expectedAttendeesField"}
                    className="input-gray-bg focus:ring-0 address-inputs my-0 rounded-xl text-sm w-full !text-md bg-background pl-4"
                    value={expectedAttendees}
                    onWheelCapture={(e) => {
                      e.target.blur();
                    }}
                    onKeyDown={handleKeyDown}
                    max={300}
                    type="number"
                    onChange={(e) =>
                      dispatch({
                        field: "expectedAttendees",
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="mt-[16px]">
              <div className="flex flex-col w-full items-start justify-between">
                <span className="text-md2 font-inter-semibold mb-3">Tip</span>

                <span className="text-sm mt-[6px]">
                  This will be used to calculate the tip amount based on your
                  group order items total. You can update this amount later in
                  your admin panel.
                </span>
              </div>

              <div className="hidden sm:flex w-full justify-between flex-wrap items-center">
                {TIP_VALUES_DESKTOP?.map((tipValue) => (
                  <TipButton
                    key={`${tipValue}-tip`}
                    onClick={() =>
                      dispatch({
                        field: "tip",
                        value: tipValue,
                      })
                    }
                    tipValue={tipValue}
                    tip={provider?.tip}
                  />
                ))}
              </div>

              <div className="flex sm:hidden w-full flex-wrap items-center">
                {TIP_VALUES_MOBILE?.map((tipValue) => (
                  <TipButton
                    key={`${tipValue}-tip-mob`}
                    onClick={() =>
                      dispatch({
                        field: "tip",
                        value: tipValue,
                      })
                    }
                    tipValue={tipValue}
                    tip={provider?.tip}
                  />
                ))}
              </div>

              {provider.tip == "Other" && (
                <div className="flex mt-[16px] mb-3 space-x-5">
                  <div className="flex items-center text-md rounded-xl bg-background w-full py-2 px-3 h-12 border border-border-gray">
                    <span className="mr-1">$</span>

                    <input
                      className="text-sm bg-background h-[46px] w-full focus:outline-none"
                      value={otherTip}
                      type="number"
                      min="0"
                      step=".01"
                      onKeyDown={preventMinus}
                      onPaste={preventPasteNegative}
                      onChange={(e) =>
                        dispatch({
                          field: "otherTip",
                          value:
                            e.target.value !== ""
                              ? Math.abs(e.target.value)
                              : e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <span className="text-md2 font-inter-semibold mt-[16px]">
              Delivery Instructions (optional)
            </span>

            <textarea
              placeholder="Ex. Park in the back, go in and take the elevator on the right to the 4th and...."
              className="input-gray-bg resize mt-3 focus:ring-0 address-inputs my-0 h-[130px] rounded-xl text-md w-full bg-background pl-4"
              type="text"
              value={addressDeliveryInstructions}
              onChange={(e) => setAddressDeliveryInstructions(e.target.value)}
            />

            {existingDeliveryAddress !== addressDeliveryInstructions && (
              <fieldset>
                <p className="text-md2 font-inter-semibold mt-[16px]">
                  Should we apply these instructions to all deliveries with this
                  address?
                </p>

                <div className="mt-[12px] space-y-2">
                  {notificationMethods.map((notificationMethod) => (
                    <div
                      key={notificationMethod.id}
                      className="flex items-center"
                    >
                      <input
                        id={notificationMethod.id}
                        name="notification-method"
                        type="radio"
                        defaultChecked={
                          provider.deliveryInstructionsLevel ===
                          notificationMethod.id
                        }
                        onChange={(e) => {
                          dispatch({
                            field: "deliveryInstructionsLevel",
                            value: e.target.id,
                          });
                        }}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor={notificationMethod.id}
                        className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                      >
                        {notificationMethod.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            )}

            <div className="fixed sm:static p-5 sm:p-0 sm:flex bg-white bottom-0 right-0 left-0 mt-6 w-full">
              <button
                type="submit"
                className={classNames({
                  "rounded-md p-6 w-full text-white btn-style text-md font-inter-medium": true,
                  "bg-secondary-text ": !fieldsValidated(),
                  "bg-hh-accent-dark hover:bg-hh-accent-light":
                    fieldsValidated(),
                })}
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default observer(NewGroupOrder);
