import { observable, action, makeObservable } from "mobx";
import { makePersistable, clearPersistedStore } from "mobx-persist-store";
import { client } from "../store";
import api from "store/api";
import qs from "qs";
import { errorHandler } from "utils/middlewares/errorHandler";

const initialCart = {
  all_items: { data: [] },
  subsidized_tip: 0,
  subsidized_hst: 0,
  tip: 0,
  hst: 0,
  hst_rate: 0.13,
  subtotal: 0,
  subsidized_subtotal: 0,
  grand_total: 0,
  payable_amount: 0,
  is_weekly: true,
  available_credit: 0,
  isSubsidy: false,
};

class PlaceOrderStore {
  days = [];
  optionCategories = null;
  tip = { label: "0%", value: 0 };
  cart = initialCart;

  constructor() {
    makePersistable(this, {
      name: "PlaceOrderStore",
      properties: ["days"],
      storage: window.localStorage,
    });
    makeObservable(this, {
      days: observable,
      optionCategories: observable,
      cart: observable,

      getDays: action,
      getOptionCategories: action,
      getCart: action,
    });
  }

  async getDays(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.days()}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async getMeals(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menus()}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async getMenuDetails(id, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menus()}/${id}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  clearOptionCategories() {
    this.optionCategories = null;
  }

  async getOptionCategories(itemId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.optionCategories()}/${itemId}?${paramsString}`)
      .then(
        action("fetchSuccess", (response) => {
          this.optionCategories = response?.data?.data;
          return response?.data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async addItemToCart(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.cartItems()}?${paramsString}`, payload)
      .then(
        action("fetchSuccess", (response) => {
          return response;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async getCart(showTip, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(
        `${api.cartItems()}?${paramsString}&tip_percentage=${
          this.tip.value / 100
        }`
      )
      .then(
        action("fetchSuccess", (response) => {
          this.cart = response?.data;
          return response?.data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  setTip(tip, showTip, params) {
    this.tip = tip;
    this.getCart(showTip, params);
  }

  resetCart() {
    this.cart = initialCart;
  }

  async removeItemFromCart(itemId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .delete(`${api.cartItems()}/${itemId}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async placeOrder(payload, params) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.uncateringOrder()}?${paramsString}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          return error?.response;
        })
      );
  }
}

const placeOrderStore = new PlaceOrderStore();
export default placeOrderStore;
