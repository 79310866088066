import { CalendarArrowDown, CalendarArrowUp, DownloadIcon } from "assets/img";

export const ORDER_ITEM_OPTIONS_COUNT = 2;
export const ODC_TAGLINE_LENGTH = 270;

export const TIP_VALUES_DESKTOP = ["10", "12", "15", "18", "20", "Other"];

export const TIP_VALUES_MOBILE = ["10", "15", "Other"];

export const ORDER_CANCELLATION_HOURS = 72;

export const IS_LARGE_SCREEN = window.innerWidth > 1024;

export const userOrderStatus = [
  { label: "Placed", value: "placed" },
  { label: "Delivered", value: "delivered" },
  { label: "No Order", value: "no order" },
  { label: "Cancelled", value: "cancelled" },
];

export const sortingOptions = [
  { label: "Ascending", value: "date_asc" },
  { label: "Descending", value: "date_desc" },
];

export const userRoles = [
  { label: "Account Admin", value: "admin" },
  { label: "Team Admin", value: "team_admin" },
  { label: "User", value: "full_time" },
];

export const rolesForTeamAdmin = [
  { label: "Team Admin", value: "team_admin" },
  { label: "User", value: "full_time" },
];

export const reminderOptions = [
  { label: "No Reminder", value: "no reminder" },
  { label: "15 Minutes", value: "15" },
  { label: "30 Minutes", value: "30" },
  { label: "45 Minutes", value: "45" },
  { label: "60 Minutes", value: "60" },
  { label: "90 Minutes", value: "90" },
  { label: "120 Minutes", value: "120" },
  { label: "180 Minutes", value: "180" },
];

export const dotsDropdownOptions = [
  {
    label: "Invoice",
    value: "invoice",
    icon: <img src={DownloadIcon} alt="download-icon" />,
  },
  {
    label: "Sales report",
    value: "sales_report",
    icon: <img src={DownloadIcon} alt="download-icon" />,
  },
];

export const dueDatesOptions = [
  {
    label: "Nearest first",
    value: "due_date asc",
    icon: <img src={CalendarArrowUp} alt="calendar-arrow-up" />,
  },
  {
    label: "Farthest first",
    value: "due_date desc",
    icon: <img src={CalendarArrowDown} alt="calendar-arrow-down" />,
  },
];

export const invoiceDatesOptions = [
  {
    label: "Newest first",
    value: "invoice_date asc",
    icon: <img src={CalendarArrowDown} alt="calendar-arrow-up" />,
  },
  {
    label: "Oldest first",
    value: "invoice_date desc",
    icon: <img src={CalendarArrowUp} alt="calendar-arrow-up" />,
  },
];

export const orderStatusOptions = [
  { label: "Open", value: "1" },
  { label: "Overdue", value: "5" },
  { label: "Paid", value: "3" },
];

export const orderUncateringTypeOptions = [
  { label: "Catering", value: "2" },
  { label: "Uncatering", value: "1" },
  { label: "Group Order", value: "3" },
];

export const orderCateringTypeOptions = [
  { label: "Catering", value: "2" },
  { label: "Group Order", value: "3" },
];
