import React, { useRef, useState, useEffect } from "react";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select, { components } from "react-select";

function MultiSelect({
  options,
  selectedOptions,
  handleChange,
  placeholderValue = "Select",
  handleRemoveOption,
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const controlRef = useRef(null);
  const menuListRef = useRef(null);

  const filteredOptions = options?.filter(
    (opt) =>
      !selectedOptions?.some((selectedOpt) => selectedOpt.value == opt.value)
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRemove = (valToRemove) => {
    const updatedSelectedOptions = selectedOptions?.filter(
      (opt) => opt.value !== valToRemove?.value
    );

    handleRemoveOption(updatedSelectedOptions);
  };

  const handleClickOutside = (event) => {
    if (
      controlRef?.current &&
      !controlRef?.current?.contains(event.target) &&
      menuListRef?.current &&
      !menuListRef?.current?.contains(event.target)
    ) {
      setMenuIsOpen(false);
    }
  };

  const CustomControl = ({ children, innerRef, innerProps, ...props }) => {
    const handleCustomClick = () => {
      setMenuIsOpen((prev) => !prev);
      if (props?.selectProps?.onMenuOpen) {
        props.selectProps.onMenuOpen();
      }
    };

    return (
      <components.Control
        {...props}
        innerRef={innerRef}
        innerProps={innerProps}
      >
        <div
          ref={controlRef}
          onClick={handleCustomClick}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span className="font-inter-normal text-[#2d3958]">
            {placeholderValue}
          </span>
          <components.DropdownIndicator {...props} />
        </div>
      </components.Control>
    );
  };

  return (
    <>
      <div className="flex flex-wrap gap-2 mb-4">
        {selectedOptions?.map((opt) => {
          return (
            <div
              key={opt?.value}
              className="h-10 px-4 py-3 bg-[#f7f9ff] rounded-3xl justify-start items-center gap-2 inline-flex"
            >
              <div className="text-[#2d3958] text-sm font-inter-medium leading-[14px]">
                {opt?.label}
                <span
                  className="text-xl text-[#CCD2E3] ml-2 cursor-pointer"
                  onClick={() => handleRemove(opt)}
                >
                  &times;
                </span>
              </div>
            </div>
          );
        })}
      </div>

      <Select
        required
        styles={customStylesSubsidy({ preferences: true, noBorder: true })}
        aria-labelledby="sort-by"
        aria-label="sort-by"
        placeholder={placeholderValue}
        closeMenuOnSelect={true}
        value={selectedOptions}
        onChange={handleChange}
        options={filteredOptions}
        className="w-full"
        isMulti
        isClearable={false}
        components={{
          Control: CustomControl,
          MenuList: (props) => (
            <components.MenuList {...props} innerRef={menuListRef} />
          ),
        }}
        menuShouldScrollIntoView={true}
        maxMenuHeight={140}
        menuIsOpen={menuIsOpen}
        onMenuClose={() => setMenuIsOpen(false)}
        onMenuOpen={() => setMenuIsOpen(true)}
      />
    </>
  );
}

export default MultiSelect;
