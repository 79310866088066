import React, {
  Fragment,
  useReducer,
  useState,
  useEffect,
  useContext,
} from "react";
import Screen from "utils/helpers/ScreenSize";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {
  DisabledRadioButton,
  MasterCardIcon,
  RadioActive,
  RadioInActive,
  VisaCardIcon,
} from "assets/img";
import { MobXProviderContext } from "mobx-react";
import { errorHandler } from "utils/middlewares/errorHandler";
import ConfirmationModal from "components/ConfirmationModal";
import { stripePromise } from "utils/helpers/stripe";

function PlaceOrderConfirmationModal({
  visible,
  needToPay,
  orderTotal,
  subsidizedSubtotal,
  setModal,
  hanldeAddCreditCard,
  temporaryCard,
  overrideSubsidy,
  temporaryCardSelected,
  handleOverrideSubsidy,
  setIsCardSelected,
  isCardSelected,
  handleTemporaryCard,
  cutlery,
  tip,
  selectedOptions,
  cart,
  selectedOffice,
  resetAll,
  clearCart,
  setShowSuccessModal,
  setOrder,
}) {
  const screenType = Screen.ScreenType();

  const initialState = {
    defaultCard: "",
    locationCreditCards: "",
    cardId: "",
    expiredCardIds: [],
    meal: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const store = useContext(MobXProviderContext);
  const userStore = store?.userStore;
  const [provider, dispatch] = useReducer(reducer, initialState);
  let { defaultLocation } = userStore;
  let { defaultCard, locationCreditCards, expiredCardIds } = provider;
  const paymentInfoStore = store?.paymentInfoStore;
  let { cardElement } = paymentInfoStore;
  const [bypassPlaceorder, setBypassPlaceorder] = useState(false);

  useEffect(() => {
    if (defaultLocation?.role !== "team_admin") {
      const timeoutId = setTimeout(() => {
        store.userStore.setLoader(true);

        store.paymentInfoStore
          .getLocationCreditCards({
            location_id: defaultLocation?.organizationId,
          })
          .then((response) => {
            if (response?.payment_methods?.data?.length > 0) {
              dispatch({
                field: "locationCreditCards",
                value: response?.payment_methods,
              });

              dispatch({
                field: "defaultCard",
                value:
                  response?.customer?.invoice_settings?.default_payment_method,
              });
            } else {
              handleOverrideSubsidy(true);
            }

            store.userStore.setLoader(false);
          })
          .catch(() => store.userStore?.setLoader(false));
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [defaultLocation.organizationId, visible]);

  const closeModal = () => {
    setModal(false);
  };

  const handleDefaultStripeCard = (paymentID) => {
    store.userStore.setLoader(true);
    store.paymentInfoStore.updateDefaultLocationCreditCards(paymentID, {
      location_id: defaultLocation?.organizationId,
      default: true,
    });
    store.userStore.setLoader(false);
  };

  const placeOrder = (payload, skipStopShipment) => {
    store.placeOrderStore
      .placeOrder(payload, {
        location_id: defaultLocation?.organizationId,
        location_employee_id: selectedOffice?.value,
      })
      .then((response) => {
        const errorMessage = response?.message || "";
        const isInvalid = !response?.valid;

        store.userStore.setLoader(false);
        setBypassPlaceorder(false);

        if (response?.orders) {
          resetAll();
          store.placeOrderStore.resetCart();
          setOrder(response.orders);
          setShowSuccessModal(true);
        } else {
          if (
            isInvalid &&
            errorMessage?.includes("The order deadline has passed.")
          ) {
            store.userStore.setLoader(true);

            handlePlaceOrder(skipStopShipment, true);
          } else if (
            isInvalid &&
            errorMessage?.includes(
              "There is a cart item with a stopped shipment"
            )
          )
            setBypassPlaceorder(true);
          else if (isInvalid && errorMessage?.includes("Unfortunately")) {
            errorHandler({ title: errorMessage });
            clearCart();
          } else {
            errorHandler({ title: errorMessage });
          }
        }
      });
    setModal(false);
  };

  const handlePlaceOrder = async (
    skipStopShipment = false,
    skipCuttoff = false
  ) => {
    const stripe = await stripePromise;

    if (!isCardSelected && !overrideSubsidy) {
      errorHandler({ title: "Please select a payment method." });
      return;
    }

    let payload = {
      amount: needToPay,
      paid_by_invoice: overrideSubsidy ?? false,
      cutlery: cutlery,
      tip_percentage: parseFloat(tip?.value / 100),
      user_agent: window.navigator.userAgent,
      skip_stopped_shipment_check: skipStopShipment,
      order_billing_references_attributes: selectedOptions,
    };

    if (skipCuttoff) payload.skip_cutoff_check = true;
    let currentItemsCount = cart?.all_items?.data?.length;

    store.userStore.setLoader(true);

    store.placeOrderStore
      .getCart(
        cart?.billing_setting && cart?.billing_setting?.tip_fee_display_to_user,
        {
          location_employee_id: selectedOffice?.value,
          location_id: defaultLocation?.organizationId,
        }
      )
      .then((response) => {
        if (currentItemsCount !== response?.all_items?.data?.length) {
          errorHandler({
            title: `${
              selectedOffice?.label ?? "This user"
            } has made changes to the cart while this order was being placed, please review the updated cart before placing the order and then update the cart.`,
          });
          store.userStore.setLoader(false);
        } else {
          if (overrideSubsidy && !isCardSelected) {
            placeOrder(payload, skipStopShipment);
          } else if (
            !overrideSubsidy &&
            isCardSelected &&
            !temporaryCardSelected
          ) {
            if (defaultCard) payload.card_id = defaultCard;
            placeOrder(payload, skipStopShipment);
          } else {
            store.paymentInfoStore
              .createLocationPaymentIntent({
                location_id: defaultLocation?.organizationId,
                employee_id: selectedOffice?.employeeId,
                tip: tip?.value,
                cutlery: cutlery,
                order_type: "uncatering_admin",
              })
              .then((response) => {
                store.userStore?.setLoader(false);

                const intentId = response?.id;
                const client_secret = response?.client_secret;

                stripe
                  .confirmCardPayment(client_secret, {
                    payment_method: {
                      card: cardElement,
                    },
                  })
                  .then(function (result) {
                    if (result?.error) {
                      errorHandler({ title: result?.error?.message });
                    } else {
                      if (result?.paymentIntent?.status == "succeeded") {
                        payload.payment_intent_id = intentId;
                        placeOrder(payload, skipStopShipment);
                      }
                    }
                  });
              });
          }
        }
      });
  };

  const getRadioButtonSrc = (
    overrideSubsidy,
    isCardSelected,
    locationCreditCards,
    temporaryCardSelected
  ) => {
    return (overrideSubsidy && !isCardSelected) ||
      (locationCreditCards?.data?.length <= 0 && !temporaryCardSelected)
      ? RadioActive
      : RadioInActive;
  };

  const getCardRadioButtonImage = (isExpiredCard, data) => {
    if (isExpiredCard && data?.id !== defaultCard) {
      return DisabledRadioButton;
    }
    if (
      data?.id === defaultCard &&
      !overrideSubsidy &&
      isCardSelected &&
      !temporaryCardSelected
    ) {
      return RadioActive;
    }
    return RadioInActive;
  };

  return (
    <>
      <Transition appear show={visible} as={Fragment}>
        <Dialog as="div" className="relative z-[50]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-5/12 transform overflow-hidden bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all rounded-3xl">
                  <div className="flex justify-end items-center">
                    <button
                      type="button"
                      onClick={() => closeModal(false)}
                      className="btn-style"
                    >
                      <FontAwesomeIcon
                        style={{ width: 16, height: 26 }}
                        icon={faXmark}
                        size="2x"
                        color="#BBC0C5"
                      />
                    </button>
                  </div>

                  <div className="flex flex-col mt-7 justify-center items-start">
                    <Dialog.Title
                      as="h3"
                      style={{
                        fontSize: screenType?.isTabletOrMobile ? 16 : 24,
                      }}
                      className="font-inter-semibold text-start leading-6"
                    >
                      Place User Order
                    </Dialog.Title>
                  </div>

                  <div className="w-full p-6 bg-[#f7f9ff] rounded-3xl border border-[#f7f9ff] flex-col justify-start items-start gap-6 inline-flex mt-6">
                    <div className="px-6 py-2 d-col w-full gap-4">
                      <div className="text-[#2d3958] font-inter-normal border-b border-[#dee2e6] justify-between flex py-3">
                        Order Total <span>$ {orderTotal}</span>
                      </div>

                      <div className="text-[#2d3958] font-inter-normal border-b border-[#dee2e6] justify-between flex py-3">
                        Subsidized Total <span>$ {subsidizedSubtotal}</span>
                      </div>

                      <div className="text-[#2d3958] font-inter-normal justify-between flex">
                        Need to pay <span>$ {needToPay}</span>
                      </div>
                    </div>
                  </div>

                  <div className="w-full text-[#2d3958] text-xl font-inter-semibold mt-6">
                    Select a Payment Method
                  </div>

                  {defaultLocation?.role !== "team_admin" && (
                    <div className="w-full p-3 bg-[#f7f9ff] rounded-xl border border-[#f7f9ff] flex-col justify-start items-start gap-6 inline-flex mt-6">
                      <div className="px-2 d-col w-full">
                        {locationCreditCards?.data?.length > 0 ? (
                          <div className="text-[#2d3958] font-inter-semibold text-lg justify-between flex py-3 items-center">
                            Credit cards on file{" "}
                            <div
                              className="text-[#232777] text-sm font-inter-medium rounded-xl border border-[#232777] h-10 items-center flex px-4 cursor-pointer"
                              onClick={() => {
                                hanldeAddCreditCard();
                                setModal(false);
                              }}
                            >
                              + Add a new card
                            </div>
                          </div>
                        ) : (
                          <div className="text-[#2d3958] font-inter-semibold text-lg flex flex-col py-3 items-start gap-2">
                            Credit card
                            <div
                              className="text-[#232777] text-sm font-inter-medium rounded-xl border border-[#232777] h-10 items-center flex px-4 cursor-pointer bg-white"
                              onClick={() => {
                                hanldeAddCreditCard();
                                setModal(false);
                              }}
                            >
                              + Add a Credit Card to Enable This Payment Method
                            </div>
                          </div>
                        )}

                        {locationCreditCards?.data?.map((data, index) => {
                          const isExpiredCard = expiredCardIds?.includes(
                            data?.id
                          );

                          return (
                            <div
                              className="rounded-xl mt-[12px] px-[12px] py-[16px] d-row justify-between bg-white border border-gray-300"
                              key={index}
                            >
                              <div
                                key="photo"
                                className="w-full flex items-center"
                              >
                                <div className="w-full flex justify-between">
                                  <div className="d-row items-center ml-3">
                                    <div
                                      className={classNames(
                                        "flex items-center mr-4",
                                        {
                                          "cursor-pointer": !isExpiredCard,
                                        }
                                      )}
                                      onClick={() => {
                                        if (!isExpiredCard) {
                                          handleDefaultStripeCard(data?.id);
                                          dispatch({
                                            field: "defaultCard",
                                            value: data?.id,
                                          });
                                          setIsCardSelected(true);
                                          handleOverrideSubsidy(false);
                                          handleTemporaryCard(false);
                                        }
                                      }}
                                    >
                                      <img
                                        alt="radio-button"
                                        src={getCardRadioButtonImage(
                                          isExpiredCard,
                                          data
                                        )}
                                      />
                                    </div>

                                    <img
                                      className="relative w-[40px] h-[40px]"
                                      alt="Frame"
                                      src={
                                        data?.card?.display_brand == "visa"
                                          ? VisaCardIcon
                                          : MasterCardIcon
                                      }
                                    />

                                    <div className="d-col ml-3">
                                      <small
                                        className={classNames(
                                          "text-sm font-inter-medium",
                                          isExpiredCard
                                            ? "text-orange-400"
                                            : "text-[#2d3958]"
                                        )}
                                      >
                                        **** {data?.card?.last4}{" "}
                                        {isExpiredCard && (
                                          <small className="text-orange-400 text-sm font-inter-medium italic">
                                            Expired
                                          </small>
                                        )}
                                      </small>

                                      <div className="inline-flex gap-3 text-[#677489] text-sm font-inter-normal">
                                        Expires 06/32
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {temporaryCard && (
                          <div className="bg-white border border-gray-300 rounded-xl mt-[12px] px-[12px] py-[16px] d-row justify-between">
                            <div
                              key={"photo"}
                              className="w-full flex items-center"
                            >
                              <div className="w-full flex justify-between">
                                <div className="d-row items-center ml-3">
                                  <div
                                    className={classNames(
                                      "flex items-center mr-4 cursor-pointer"
                                    )}
                                    onClick={() => {
                                      setIsCardSelected(true);
                                      handleOverrideSubsidy(false);
                                      handleTemporaryCard(true);
                                    }}
                                  >
                                    <img
                                      alt="radio-button"
                                      src={
                                        temporaryCard &&
                                        temporaryCardSelected &&
                                        !overrideSubsidy
                                          ? RadioActive
                                          : RadioInActive
                                      }
                                    />
                                  </div>

                                  <img
                                    className="relative w-[40px] h-[40px]"
                                    alt="Frame"
                                    src={
                                      temporaryCard?.display_brand == "visa"
                                        ? VisaCardIcon
                                        : MasterCardIcon
                                    }
                                  />

                                  <div className="d-col ml-3">
                                    <small className="text-sm text-slate-700 font-inter-medium">
                                      **** {temporaryCard?.last4}
                                    </small>

                                    <small className="text-sm text-slate-500 font-inter-normal">
                                      Expires{" "}
                                      {`${
                                        temporaryCard?.exp_month
                                      }/${temporaryCard?.exp_year
                                        .toString()
                                        .slice(-2)}`}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="rounded-lg mt-6 px-[12px] py-[16px] d-row bg-white border border-gray-300">
                    <div key="photo" className="w-full flex items-center">
                      <div className="w-full flex justify-between">
                        <div className="d-row items-center ml-3">
                          <div
                            className="flex items-center mr-6 cursor-pointer"
                            onClick={() => handleOverrideSubsidy(true)}
                          >
                            <img
                              alt="radio-button"
                              src={getRadioButtonSrc(
                                overrideSubsidy,
                                isCardSelected,
                                locationCreditCards,
                                temporaryCardSelected
                              )}
                            />

                            <span className="text-[#2d3958] text-lg font-inter-semibold ml-4">
                              Override subsidy and bill to account invoice
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center items-center mt-5">
                    <div className="flex w-full">
                      <button
                        className="btn-light btn-style py-3 px-5 w-1/2"
                        onClick={() => closeModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn-purple btn-style py-3 px-6 ml-3 w-1/2"
                        onClick={() => handlePlaceOrder()}
                      >
                        Place Order
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {bypassPlaceorder && (
        <ConfirmationModal
          visible={bypassPlaceorder}
          setModal={setBypassPlaceorder}
          title="Cart item with a stopped shipment"
          body="Restaurant has indicated that they cannot accept any additional orders at this time due to capacity limitations. Are you sure you want to place the order?"
          rightButtonText="Place Order"
          leftButtonText="Cancel"
          onClickRightButton={() => {
            handlePlaceOrder(true);
          }}
        />
      )}
    </>
  );
}

export default PlaceOrderConfirmationModal;
