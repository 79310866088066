import { Navigate } from "react-router-dom";
import Layout from "./Layout";
import { MealManagement } from "../containers/MealManagement";
import {
  ManageUsers,
  MealPreference,
  UpcomingGroupOrderDetail,
  CateringOrderDetail,
} from "containers/MealManagement";
import {
  Home,
  OrderDetail,
  AddUncateringMeals,
  AddCateringMeal,
  RestaurantPage,
  CheckoutPage,
  AddGroupMeal,
  NewGroupOrder,
  GroupOrderConfirmation,
  LoggedInLayout,
  OrdersPage,
  GroupOrderInstructions,
  Faqs,
  GroupOrderDetails,
  RequestChangesToMeal,
  ManageTeams,
  ViewOrder,
  InvitationLinks,
  GroupOrderCheckout,
  CheckoutLayout,
  GroupOrderThanks,
} from "containers/Home";
import DeliveryInfo from "containers/DeliveryInfo";
import PaymentInfo from "containers/PaymentInfo";
import DeliveryInfoDetail from "containers/DeliveryInfo/show";
import CreateNewAddress from "containers/DeliveryInfo/create";
import { ManagePlanProvider } from "contexts/ManagePlanContext";
import OurServices from "containers/OurServices";
import OrderHistory from "containers/OrderHistory";
import UncateringTeam from "containers/Uncatering/Team";
import UncateringInvoices from "containers/Uncatering/Invoices";
import MarketplacePaymentInfo from "containers/PaymentInfo/marketplacePaymentInfo";
import TeamPreferences from "containers/Uncatering/TeamPreferences";
import CateringOrderThanks from "containers/Home/cateringOrderThanks";
import GroupOrderLayout from "containers/Home/groupOrderLayout";
import PlaceOrder from "containers/Uncatering/PlaceOrder";
import ErrorPage from "components/CsvResponseModal/ErrorPage";

export default function privateRoutes() {
  return [
    {
      path: "/group-order/checkout/:id",
      element: <CheckoutLayout mainContent={<GroupOrderCheckout />} />,
    },
    {
      path: "/group-order/order-confirmation/:groupOrderId",
      element: <GroupOrderThanks />,
    },
    {
      path: "/order-confirmation/:slug",
      element: <CateringOrderThanks />,
    },
    {
      path: "/group-order/confirmation/:id",
      element: <GroupOrderLayout mainContent={<GroupOrderConfirmation />} />,
    },
    {
      path: "/group-order/new", //?date=2024-02-20&time=5%3A45+pm&address=Toronto+St%2C+Toronto%2C+ON+M5C%2C+Canada&id=ofbii&name=Ostro&menu_id=404
      element: <GroupOrderLayout mainContent={<NewGroupOrder />} />,
    },
    {
      path: "/uncatering/team/errors",
      element: <ErrorPage />,
    },
    {
      element: <Layout />,
      children: [
        //HOME
        // { path: "/", element: <Home /> },
        { path: "/home", element: <Home /> },
        // { path: "/home", element: <div /> },
        { path: "/", element: <Home /> },
        { path: "/home/uncatering-meal/:mealId", element: <OrderDetail /> },
        { path: "/home/order-detail/:shipmentId", element: <ViewOrder /> },
        { path: "/delivery-info", element: <DeliveryInfo /> },
        { path: "/billing/payment-info", element: <PaymentInfo /> },
        { path: "/delivery-info/:id", element: <DeliveryInfoDetail /> },
        { path: "/our-services", element: <OurServices /> },
        { path: "/delivery-info/create", element: <CreateNewAddress /> },
        { path: "/order-history", element: <OrderHistory /> },
        {
          path: "home/invitation-links/:mealId",
          element: <InvitationLinks />,
        },
        {
          path: "/order-history/uncatering-order/:mealId",
          element: <OrderDetail />,
        },
        {
          path: "/order-history/uncatering/restaurant-order-detail/:shipment_id",
          element: <ViewOrder viewOrder={true} />,
        },
        {
          path: "/home/request-meal-changes/:mealId",
          element: (
            <ManagePlanProvider>
              <RequestChangesToMeal />
            </ManagePlanProvider>
          ),
        },
        {
          path: "/home/manage-teams/:mealId",
          element: (
            <ManagePlanProvider>
              <ManageTeams />
            </ManagePlanProvider>
          ),
        },
        //Uncatering
        {
          path: "/uncatering/team",
          element: <UncateringTeam />,
        },
        {
          path: "/billing/invoices",
          element: <UncateringInvoices />,
        },
        {
          path: "/uncatering/place-orders",
          element: <PlaceOrder />,
        },
        {
          path: "/uncatering/team-preferences",
          element: <TeamPreferences />,
        },

        // MEAL MANAGEMENT
        {
          path: "/meal-management",
          element: (
            <ManagePlanProvider>
              <MealManagement />
            </ManagePlanProvider>
          ),
        },
        {
          path: "/meal-management/manage-teams/:mealPlanId",
          element: (
            <ManagePlanProvider>
              <ManageUsers />
            </ManagePlanProvider>
          ),
        },

        {
          path: "/meal-management/meal-preference",
          element: <MealPreference />,
        },
        {
          path: "/meal-management/group-order/:id",
          element: <UpcomingGroupOrderDetail />,
        },
        {
          path: "/meal-management/catering-order/:slug/:locationId",
          element: <CateringOrderDetail />,
        },

        // ADD MEAL Uncatering, Catering & Group
        { path: "/get-started-uncatering", element: <AddUncateringMeals /> },
        { path: "/get-started-catering", element: <AddCateringMeal /> },
        {
          path: "/get-started-catering/restaurant/:code",
          element: <RestaurantPage />,
        },
        {
          path: "/get-started-catering/checkout/:code",
          element: <CheckoutPage />,
        },
        {
          path: "/get-started-group",
          element: <AddCateringMeal isGroup={true} />,
        },
        {
          path: "/get-started-group/restaurant/:code",
          element: <RestaurantPage />,
        },

        {
          path: "/group-order/admin/orders/:groupOrderId/:locationId",
          // element: <LoggedInLayout mainContent={<GroupOrderDetails />} />,
          element: <GroupOrderDetails />,
        },
        {
          path: "/group-order/orders",
          element: <LoggedInLayout mainContent={<OrdersPage />} />,
        },
        {
          path: "/group-order/admin/group-order-new",
          element: <LoggedInLayout mainContent={<GroupOrderInstructions />} />,
        },
        {
          path: "/group-order/faqs",
          element: <LoggedInLayout mainContent={<Faqs />} />,
        },
        {
          path: "/billing/marketplace/payment-info",
          element: <MarketplacePaymentInfo />,
        },

        { path: "*", element: <Navigate to="/" replace /> },
      ],
    },
  ];
}
