import { components } from "react-select";
import { CalendarIcon } from "assets/img";

const CustomDropdownIndicator = ({ innerRef, innerProps, ...props }) => {
  return (
    <components.DropdownIndicator
      {...props}
      innerRef={innerRef}
      innerProps={innerProps}
    >
      <img src={CalendarIcon} alt="calendar-icon" width={20} height={20} />
    </components.DropdownIndicator>
  );
};

export default CustomDropdownIndicator;
