import { makeAutoObservable, observable, action } from "mobx";
import { client } from "../store";
import api from "store/api";
import qs from "qs";
import { errorHandler } from "utils/middlewares/errorHandler";

class GroupOrderStore {
  orders = {};
  ordersPagy = {};

  constructor() {
    makeAutoObservable(this, {
      orders: observable,
      getAllOrders: action,
    });
  }

  async getAllOrders(params, locationId) {
    let _params = qs.stringify(params, { arrayFormat: "brackets" });

    try {
      const response = await client().get(
        `${api.groupOrders()}?location_id=${locationId}&type=history&${_params}`
      );
      this.orders = response?.data;
      this.ordersPagy = response?.data?.pagy;

      return response?.data;
    } catch (error) {
      errorHandler(error?.response);
      return error;
    }
  }
}

const groupOrderStore = new GroupOrderStore();
export default groupOrderStore;
