import React, { Fragment } from "react";
import Screen from "utils/helpers/ScreenSize";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { GroupOrderStep3 } from "assets/img";
import { useNavigate } from "react-router-dom";

function PlaceOrderSuccessModal({ visible, setModal, meal }) {
  const screenType = Screen.ScreenType();
  const navigate = useNavigate();

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[593px] transform overflow-hidden bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all rounded-3xl">
                <div className="flex justify-end items-center">
                  <button
                    type="button"
                    onClick={() => setModal(false)}
                    className="btn-style"
                  >
                    <FontAwesomeIcon
                      style={{ width: 20, height: 30 }}
                      icon={faXmark}
                      size="3xl"
                      color="#BBC0C5"
                    />
                  </button>
                </div>

                <div className="flex justify-center items-center">
                  <img
                    src={GroupOrderStep3}
                    className="w-[202.27px] h-[136.31px]"
                  />
                </div>

                <div className="flex flex-col mt-7 justify-center items-center">
                  <Dialog.Title
                    as="h3"
                    style={{ fontSize: screenType?.isTabletOrMobile ? 16 : 24 }}
                    className="font-inter-semibold text-center leading-6"
                  >
                    Order succesfully placed
                  </Dialog.Title>
                </div>

                <div className="flex justify-center items-center mt-8">
                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3 w-[355px]"
                    onClick={() => {
                      navigate(`/home/order-detail/${meal?.shipment_id}`);
                    }}
                  >
                    View order
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default PlaceOrderSuccessModal;
