import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext } from "react";
import { CateringBag, GroupOrderServices, UncateringBag } from "assets/img";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function AddMeal({
  visible,
  setModal,
  avoidClose,
  closeModal,
  handleAddressTimeModal,
}) {
  let navigate = useNavigate();

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => {
          setModal(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[739px] h-[485px] d-col items-center justify-center rounded-lg md:w-5/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                {!avoidClose && (
                  <div className="flex justify-between items-center w-full mb-4">
                    <p className="font-inter-semibold text-[32px]">Add Meal</p>

                    <button
                      type="button"
                      onClick={() => setModal(false)}
                      className="btn-style"
                    >
                      <FontAwesomeIcon
                        style={{ width: 16, height: 26 }}
                        icon={faXmark}
                        size="2x"
                        color="#BBC0C5"
                      />
                    </button>
                  </div>
                )}

                <div className="d-row items-start w-full mb-4">
                  <p className="font-inter-semibold text-2xl">
                    What type of meal?
                  </p>
                </div>

                <div className="d-row w-full justify-center">
                  <div
                    className="d-col items-center bg-light-gray p-[24px] rounded-lg cursor-pointer"
                    onClick={() => {
                      navigate("/get-started-uncatering");
                    }}
                  >
                    <img src={UncateringBag} className="h-[72px] w-[72px]" />
                    <strong>Uncatering</strong>

                    <small className="max-w-[165px] text-slate-500 font-inter-normal text-center">
                      Recurring meals placed by users with individual accounts
                    </small>
                  </div>

                  <div
                    className="d-col items-center bg-light-gray mx-[12px] p-[24px] rounded-lg cursor-pointer"
                    onClick={() => {
                      setModal(false);
                      handleAddressTimeModal(true);
                    }}
                  >
                    <img
                      src={GroupOrderServices}
                      className="h-[72px] w-[72px]"
                    />
                    <strong>Group order</strong>

                    <small className="max-w-[165px] text-slate-500 font-inter-normal text-center">
                      One time meal with a link to invite others to order on a
                      shared cart without creating an account
                    </small>
                  </div>

                  <div
                    className="d-col items-center bg-light-gray p-[24px] rounded-lg cursor-pointer"
                    onClick={() => {
                      setModal(false);
                      handleAddressTimeModal(true);
                    }}
                  >
                    <img src={CateringBag} className="h-[72px] w-[72px]" />
                    <strong>Catering</strong>

                    <small className="max-w-[165px] text-slate-500 font-inter-normal text-center">
                      Place a full catering order on behalf of a group
                    </small>
                  </div>
                </div>

                <button
                  onClick={() => {
                    navigate("/our-services");
                  }}
                  className="text-catering text-sm font-inter-medium py-10"
                >
                  Learn more about our services
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AddMeal;
