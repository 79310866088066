import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useMemo,
  useRef,
} from "react";
import classNames from "classnames";
import { NotFound, SearchBar } from "components";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select from "react-select";
import { UploadCSV, CreateTeam } from "alerts";
import { MobXProviderContext, observer } from "mobx-react";
import {
  FolderOpenIcon,
  UploadFileIcon,
  CrossImg,
  EditPencilIcon,
} from "assets/img";
import { isPresent } from "utils/helpers/array";
import AddUserToTeam from "alerts/addUserToTeam";
import TooltipNew from "components/Tooltip";
import BulkUserEdit from "alerts/bulkUserEdit";
import CsvResponseModal from "components/CsvResponseModal";

const UserRoleTooltip = ({ role }) => {
  let tooltipText =
    role == "Team admin"
      ? "Team admins refers to a user with the <br /> privileges to manage and oversee a specific <br /> team of users in a meal plan"
      : "The account admin is responsible for <br /> managing the entire meal program at an <br /> organizational level";

  return (
    <span className="inline-flex">
      <TooltipNew
        darkGrayIcon
        tooltipText={tooltipText}
        id={`${role?.toLowerCase().replace(" ", "-")}-tooltip`}
        backgroundColor="white"
        textColor="#2d3958"
        place="right"
        className="w-[120px]"
      />
    </span>
  );
};

function UncateringTeam() {
  const initialState = {
    teams: [],
    selectedTeam: null,
    teamAdmins: [],
    teamUsers: [],
    teamOptions: [],
    searchText: "",
    selectedMeal: null,
    selectedStatus: null,
    selectedRole: null,
    filteredTeams: [],
    filteredTeamUsers: [],
    mealOptions: null,
    teamMealOptions: null,
    allUsers: [],
    filteredUsers: [],
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [uploadCSV, setUploadCSV] = useState(false);
  const [createTeam, setCreateTeam] = useState(false);
  const [showAllUsers, setShowAllUsers] = useState(true);
  const [addUser, setAddUser] = useState(false);
  const [bulkUsers, setBulkUsers] = useState([]);
  const [editTeam, setEditTeam] = useState(false);
  const [userAdded, setUserAdded] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [editBulkUsers, setEditBulkUsers] = useState(false);
  const [teamAdded, setTeamAdded] = useState(false);
  const [provider, dispatch] = useReducer(reducer, initialState);
  const store = useContext(MobXProviderContext);
  const userStore = store?.userStore;
  let { defaultLocation } = userStore;
  let {
    teams,
    selectedTeam,
    teamAdmins,
    teamUsers,
    teamOptions,
    filteredTeams,
    filteredTeamUsers,
  } = provider;
  const [errors, setErrors] = useState([]);
  const [recordError, setRecordError] = useState(false);
  const [isSuccessResponse, setIsSuccessResponse] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(false);
  const containerRef = useRef(null);

  const statusOptions = [
    { label: "Sent", value: "Sent" },
    { label: "Activated", value: "Activated" },
  ];

  const UserRoleOptions = [
    { label: "Account Admin", value: "admin" },
    { label: "Team Admin", value: "team_admin" },
    { label: "User", value: "full_time" },
  ];

  useEffect(() => {
    store.userStore.setLoader(true);

    store.mealManagementStore
      .fetchMealPlans(defaultLocation?.organizationId, {
        service_eq: "uncatering",
      })
      .then((response) => {
        const options = response?.data?.map((meal) => {
          return {
            label: meal?.attributes?.name,
            value: meal?.id,
          };
        });
        dispatch({
          field: "mealOptions",
          value: options,
        });
      });

    store.teamStore
      ?.fetchTeams({
        location_id: defaultLocation?.organizationId,
        employee_id: defaultLocation?.value,
      })
      .then((response) => {
        if (isPresent(response?.data)) {
          dispatch({ field: "teams", value: response?.data });
          dispatch({ field: "filteredTeams", value: response?.data });

          const teamOptions = response?.data?.map((team) => {
            return {
              value: team.id,
              label: team?.attributes?.name,
            };
          });
          dispatch({ field: "teamOptions", value: teamOptions });
          filterTeams(response?.data);
        }
      });

    store.teamStore
      .fetchUsers({
        location_id: defaultLocation?.organizationId,
        team_and_meal_details: true,
      })
      .then((response) => {
        const users = response?.data
          ?.map((user) => (user?.attributes?.teams?.length > 0 ? user : null))
          .filter((user) => user !== null);

        dispatch({ field: "allUsers", value: users });
        dispatch({ field: "filteredUsers", value: users });
      });

    store.userStore.setLoader(false);
  }, [defaultLocation.organizationId, userAdded, teamAdded]);

  useEffect(() => {
    filterTeams(teams);
  }, [showAllUsers, selectedTeam]);

  const filteredUsers = useMemo(() => {
    const matchesSearchTerm = (value) =>
      provider.searchText
        ? value?.toLowerCase().includes(provider.searchText?.toLowerCase())
        : true;

    const users = showAllUsers ? provider.allUsers : teamUsers;

    const filteredUsers = users?.filter((user) => {
      const { first_name, last_name, email, role, status, meals, teams } =
        user.attributes;

      const matchesRole = provider.selectedRole
        ? role?.toLowerCase() == provider.selectedRole?.label?.toLowerCase()
        : true;

      const matchesStatus = provider.selectedStatus
        ? status == provider.selectedStatus?.value
        : true;

      const matchesMeal = provider.selectedMeal
        ? meals?.some((meal) =>
            meal
              ?.toLowerCase()
              ?.includes(provider.selectedMeal?.label?.toLowerCase())
          )
        : true;

      const matchesTeam = provider.searchText
        ? teams?.some((team) => matchesSearchTerm(team?.name))
        : true;

      const matchesSearchText = provider.searchText
        ? matchesSearchTerm(first_name) ||
          matchesSearchTerm(last_name) ||
          matchesSearchTerm(email) ||
          matchesTeam
        : true;

      return matchesRole && matchesStatus && matchesMeal && matchesSearchText;
    });

    dispatch({ field: "filteredUsers", value: filteredUsers });
    dispatch({ field: "filteredTeamUsers", value: filteredUsers });
  }, [
    provider.selectedRole,
    provider.selectedStatus,
    provider.selectedMeal,
    provider.searchText,
    showAllUsers,
  ]);

  const onEmployeeChecked = (e, person) => {
    const selectedUser = provider.allUsers?.find(
      (user) => user.id == person?.id
    );

    if (e.target.checked) {
      let isPresent = bulkUsers?.find((user) => user.id == selectedUser?.id);

      if (!isPresent) {
        setBulkUsers([...bulkUsers, { ...selectedUser }]);
      }
    } else {
      let filtered = bulkUsers?.filter((user) => user?.id !== selectedUser?.id);
      setBulkUsers(filtered);
    }
  };

  const filterTeams = (teams) => {
    let teamAdmin = [];
    let teamUsers = [];

    if (!showAllUsers) {
      teams?.forEach((team) => {
        if (team.id == selectedTeam.value) {
          team?.attributes?.admins?.forEach((admin) => {
            teamAdmin.push(admin);
          });

          team?.attributes?.employees?.forEach((employee) => {
            teamUsers.push(employee);
          });
        }
      });

      dispatch({ field: "teamAdmins", value: teamAdmin });
      dispatch({ field: "teamUsers", value: teamUsers });
    }
  };

  const handleTeamAdded = (team) => {
    setCreateTeam(false);
    setEditTeam(false);
    setTeamAdded((prev) => !prev);
    if (team) {
      setShowAllUsers(false);

      handleTeamSelect(team);
    } else {
      setShowAllUsers(true);
    }
  };

  const handleOnChange = (e) => {
    dispatch({ field: "searchText", value: e?.target?.value });
  };

  const handleClearFilters = () => {
    dispatch({ field: "selectedMeal", value: null });
    dispatch({ field: "selectedStatus", value: null });
    dispatch({ field: "selectedRole", value: null });
  };

  const handleTeamSelect = (team) => {
    setShowAllUsers(false);
    dispatch({
      field: "selectedTeam",
      value: { label: team?.attributes?.name, value: team?.id },
    });
    setBulkUsers([]);
    handleClearFilters();

    store.userStore.setLoader(true);
    store.teamStore
      .fetchUsers({
        location_id: defaultLocation?.organizationId,
        team_and_meal_details: true,
        team_id: team?.id,
      })
      .then((response) => {
        const users = response?.data
          ?.map((user) => (user?.attributes?.teams?.length > 0 ? user : null))
          .filter((user) => user !== null);

        const adminUsers = users?.filter(
          (user) => user?.attributes?.role?.toLowerCase() !== "user"
        );

        const fulltimeUsers = users?.filter(
          (user) => user?.attributes?.role?.toLowerCase() == "user"
        );

        dispatch({ field: "teamUsers", value: fulltimeUsers });
        dispatch({ field: "filteredTeamUsers", value: fulltimeUsers });
        dispatch({ field: "teamAdmins", value: adminUsers });
      });

    store.userStore.setLoader(false);
  };

  const handleAllClick = () => {
    setShowAllUsers(true);
    dispatch({
      field: "selectedTeam",
      value: null,
    });
    setBulkUsers([]);
    handleClearFilters();
    dispatch({ field: "teamUsers", value: [] });
    dispatch({ field: "teamAdmins", value: [] });
  };

  const handleScrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    }
  };
  function UsersTable({ users, allTeams }) {
    return (
      <div className="mt-8 w-full flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="bg-light-gray">
                  <th
                    scope="col"
                    className="py-3.5 pl-2 pr-2 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                  ></th>
                  <th
                    scope="col"
                    className="py-3.5 pl-2 pr-1 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                  >
                    First name
                  </th>
                  <th
                    scope="col"
                    className="px-1 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Last name
                  </th>
                  <th
                    scope="col"
                    className="px-1 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-1 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Meal
                  </th>
                  {allTeams && (
                    <th
                      scope="col"
                      className="px-1 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                    >
                      Team
                    </th>
                  )}

                  <th
                    scope="col"
                    className="px-1 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Status
                  </th>

                  <th
                    scope="col"
                    className="px-1 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Role
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {users?.map((user) => {
                  const {
                    first_name,
                    last_name,
                    email,
                    role,
                    status,
                    meals,
                    teams,
                  } = user?.attributes;
                  return (
                    <>
                      <tr key={user?.id} className="even:bg-light-gray">
                        <td className="py-4 pl-1 pr-2 sm:pl-3">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox text-blue-600 h-4 w-4"
                              checked={bulkUsers?.some((u) => u.id == user?.id)}
                              onChange={(e) => {
                                onEmployeeChecked(e, user);
                              }}
                            />
                          </label>
                        </td>
                        <td className="py-4 pl-1 pr-1 text-sm font-inter-medium text-dark-gray sm:pl-3">
                          {first_name}
                        </td>
                        <td className="px-1 py-4 text-sm font-inter-medium text-dark-gray">
                          {last_name}
                        </td>
                        <td className="px-1 py-4 text-sm font-inter-medium text-dark-gray">
                          {email}
                        </td>
                        <td className="px-1 py-4 text-sm font-inter-medium text-dark-gray">
                          {isPresent(meals)
                            ? meals?.map((meal) => (
                                <>
                                  {meal}
                                  <br />
                                </>
                              ))
                            : "-"}
                        </td>
                        {allTeams && (
                          <td className="px-1 py-4 text-sm font-inter-medium capitalize text-[#232777]">
                            {teams?.map((team) => (
                              <>
                                {team?.name}
                                <br />
                              </>
                            ))}
                          </td>
                        )}
                        <td className="px-1 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                          {status ?? "-"}
                        </td>
                        <td className="px-1 py-4 text-sm font-inter-medium capitalize text-[#232777]">
                          <span className="inline-flex">
                            {role ?? "-"}
                            {role !== "User" && (
                              <div className="relative mt-[2px]">
                                <UserRoleTooltip role={role} />
                              </div>
                            )}{" "}
                          </span>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-col w-full justify-start items-start py-[24px] px-[32px]">
      <div className="w-full justify-start items-center flex inline-flex">
        <div
          className="mr-2 h-10 flex bg-[#f7f9ff] rounded-xl text-[16px] p-[12px] items-center justify-center cursor-pointer"
          onClick={handleScrollLeft}
        >
          <div>{"<"}</div>
        </div>

        <div
          className={classNames(
            "px-4 flex justify-start items-center gap-4 whitespace-nowrap overflow-hidden",
            defaultLocation?.role == "team_admin" ? "w-full" : "w-5/6"
          )}
          ref={containerRef}
        >
          <button
            className={classNames({
              "text-heading font-inter-medium text-[16px] p-[12px] rounded-xl capitalize": true,
              "bg-background": showAllUsers,
            })}
            onClick={() => handleAllClick()}
          >
            All
          </button>

          {filteredTeams?.map((team) => (
            <button
              key={team?.attributes?.name}
              className={classNames({
                "text-heading font-inter-medium text-[16px] p-[12px] rounded-lg capitalize flex": true,
                "bg-background": team?.attributes?.name == selectedTeam?.label,
              })}
              onClick={() => handleTeamSelect(team)}
            >
              {team?.attributes?.name}
              {team?.attributes?.name == selectedTeam?.label && (
                <span
                  className="ml-1 mt-1 flex justify-center w-[25px]"
                  onClick={() => {
                    setEditTeam(true);
                    setCreateTeam(false);
                  }}
                >
                  <img src={EditPencilIcon} width="15px" height="15px" />
                </span>
              )}
            </button>
          ))}
        </div>

        <div
          className="ml-4 h-10 flex bg-[#f7f9ff] rounded-xl text-[16px] p-[12px] items-center justify-center cursor-pointer"
          onClick={handleScrollRight}
        >
          <div>{">"}</div>
        </div>

        {defaultLocation?.role !== "team_admin" && (
          <div className="w-1/6 flex justify-end">
            <button
              onClick={() => {
                setCreateTeam(true);
                setEditTeam(false);
              }}
              className="btn-outline bg-background border-0 font-inter-medium h-full p-[12px] rounded-xl"
            >
              + Create new team
            </button>
          </div>
        )}
      </div>

      {!showAllUsers && (
        <div className="flex-col justify-start items-start mt-[53px] w-full">
          <div className="font-inter-semibold text-[20px] text-dark-gray">
            Admins
          </div>

          {isPresent(teamAdmins) ? (
            <UsersTable users={teamAdmins} allTeams={false} />
          ) : (
            <div className="d-col w-full items-center justify-center py-12">
              <div className="text-sm w-1/2 font-inter-semibold text-[#566884] text-center">
                No team assigned yet. Add users to the team to get started. Once
                added, you can assign one user as the Team Admin by setting
                their role.
              </div>
            </div>
          )}
        </div>
      )}

      <div className="flex-col justify-start items-start mt-[53px] w-full">
        <div className="d-row justify-between w-full">
          <div className="font-inter-semibold text-[20px] text-dark-gray">
            Users
          </div>

          <div className="justify-end items-center gap-3 flex">
            <div className="justify-end items-center gap-3 flex">
              <button
                className="btn-light-gray d-row justify-center items-center text-heading text-[16px]"
                onClick={() => setUploadCSV(true)}
              >
                <img src={FolderOpenIcon} className="mr-2" />
                Bulk upload users
              </button>

              {showAllUsers && (
                <button className="btn-light-gray d-row justify-center items-center text-heading text-[16px]">
                  <img src={UploadFileIcon} className="mr-2" />
                  Export user data
                </button>
              )}

              <button
                className="btn-light-gray text-[16px] text-white bg-catering"
                onClick={() => setAddUser(true)}
              >
                {showAllUsers ? "+ Add users" : "+ Add user"}
              </button>
            </div>
          </div>
        </div>

        <div className="mt-[24px] flex-col items-start gap-6 flex">
          <div className="self-stretch justify-between items-center inline-flex">
            <div className="justify-end items-center gap-2 flex">
              <SearchBar
                width="w-[360px]"
                placeholder="Search"
                handleOnChange={handleOnChange}
                value={provider.searchText}
              />

              <div className="text-heading font-inter-normal text-sm">
                Filter by:
              </div>

              <Select
                styles={customStylesSubsidy({ preferences: true })}
                aria-labelledby="sort-by"
                aria-label="sort-by"
                placeholder="Meal"
                closeMenuOnSelect
                value={provider.selectedMeal}
                onChange={(option) => {
                  dispatch({ field: "selectedMeal", value: option });
                }}
                options={provider.mealOptions}
                className="min-w-[146px] rounded-xl"
              />

              <Select
                styles={customStylesSubsidy({ preferences: true })}
                aria-labelledby="sort-by"
                aria-label="sort-by"
                placeholder="Status"
                closeMenuOnSelect
                value={provider.selectedStatus}
                onChange={(option) => {
                  dispatch({ field: "selectedStatus", value: option });
                }}
                options={statusOptions}
                className="min-w-[126px] rounded-xl"
              />

              {showAllUsers && (
                <Select
                  styles={customStylesSubsidy({ preferences: true })}
                  aria-labelledby="sort-by"
                  aria-label="sort-by"
                  placeholder="Role"
                  closeMenuOnSelect
                  value={provider.selectedRole}
                  onChange={(option) => {
                    dispatch({ field: "selectedRole", value: option });
                  }}
                  options={UserRoleOptions}
                  className="min-w-[146px] text-sm font-inter-regular rounded-xl"
                />
              )}
            </div>

            <button
              className="btn-light-gray d-row justify-center items-center font-inter-regular w-auto px-[16px] text-heading text-[16px]"
              onClick={handleClearFilters}
            >
              <img src={CrossImg} className="mr-2" />
              Clear Filters
            </button>
          </div>

          {showAllUsers ? (
            isPresent(provider.filteredUsers) ? (
              <UsersTable users={provider.filteredUsers} allTeams={true} />
            ) : (
              <div className="d-col w-full items-center mt-4">
                <NotFound
                  data={provider.filteredUsers}
                  message="No users in this team yet"
                />
                <button className="btn-disabled-mode bg-[#f7f9ff] border-0">
                  + Add users
                </button>
              </div>
            )
          ) : isPresent(filteredTeamUsers) ? (
            <UsersTable users={filteredTeamUsers} allTeams={false} />
          ) : (
            <div className="d-col w-full items-center mt-4">
              <NotFound
                data={filteredTeamUsers}
                message="No users in this team yet"
              />
              <button
                className="btn-disabled-mode bg-[#f7f9ff] border-0"
                onClick={() => setAddUser(true)}
              >
                + Add users
              </button>
            </div>
          )}
        </div>
      </div>

      {isPresent(bulkUsers) && (
        <div className="fixed bottom-0 right-0 d-row items-center justify-center left-0 ">
          <div className="btn-blue-accent rounded-3xl px-[32px] py-[16px] d-row items-center hover:bg-catering h-[48px] ">
            <small className="font-inter-medium text-[16px]">
              {bulkUsers?.length} user selected
            </small>

            <button
              className="font-inter-medium text-white underline text-[16px] py-0 ml-4"
              onClick={() => {
                if (bulkUsers?.length == 1) {
                  setEditUser(true);
                } else {
                  setEditBulkUsers(true);
                }
              }}
            >
              Edit
            </button>
          </div>
        </div>
      )}

      {uploadCSV && (
        <UploadCSV
          visible={uploadCSV}
          setModal={setUploadCSV}
          setErrors={setErrors}
          setRecordError={setRecordError}
          setIsSuccessResponse={setIsSuccessResponse}
          setIsErrorResponse={setIsErrorResponse}
        />
      )}

      {(createTeam || editTeam) && (
        <CreateTeam
          visible={createTeam || editTeam}
          setModal={() => {
            setCreateTeam(false);
            setEditTeam(false);
          }}
          isEdit={createTeam ? null : editTeam}
          selectedTeam={selectedTeam}
          setTeamAdded={handleTeamAdded}
        />
      )}

      <AddUserToTeam
        visible={addUser || editUser}
        setModal={() => {
          setAddUser(false);
          setEditUser(false);
        }}
        isEdit={editUser}
        teams={teamOptions}
        handleAddUser={() => {
          setUserAdded((prev) => !prev);
          setAddUser(false);
          setEditUser(false);
          setBulkUsers([]);
        }}
        editUser={bulkUsers[0]}
      />

      {editBulkUsers && (
        <BulkUserEdit
          visible={editBulkUsers}
          setModal={setEditBulkUsers}
          teams={teamOptions}
          bulkUsers={bulkUsers}
          handleBulkUpdate={() => {
            setBulkUsers([]);
            setUserAdded((prev) => !prev);
          }}
        />
      )}

      {(isErrorResponse || isSuccessResponse) && (
        <CsvResponseModal
          visible={isErrorResponse || isSuccessResponse}
          title={`${
            isSuccessResponse
              ? "CSV File Uploaded Successfully!"
              : recordError
              ? "Error in Uploading Some Lines"
              : "Error in Uploading"
          }`}
          body={`${
            isSuccessResponse
              ? ""
              : recordError
              ? "Some lines couldn't be uploaded due to errors. Please review and correct the listed issues before re-uploading the file. The other lines have been uploaded successfully. Don't worry—if you re-upload the entire file, duplicate entries will not be created."
              : "The file couldn't be uploaded. Please ensure you are using the correct template and try re-uploading."
          }`}
          errors={errors ?? []}
          isSuccess={isSuccessResponse}
          dismissAlert={() => {
            setIsErrorResponse(false);
            setIsSuccessResponse(false);
          }}
          handleUploadAnotherFile={() => {
            setIsErrorResponse(false);
            setIsSuccessResponse(false);
            setUploadCSV(true);
          }}
        />
      )}
    </div>
  );
}

export default observer(UncateringTeam);
