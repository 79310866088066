import React, { useEffect, useContext } from "react";
import { MobXProviderContext } from "mobx-react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function Authentication() {
  const navigate = useNavigate();
  const { userStore } = useContext(MobXProviderContext);

  const cookiesInfoPresent =
    !!Cookies.get("user-token") &&
    !!Cookies.get("employee-id") &&
    !!Cookies.get("email");

  useEffect(() => {
    if (cookiesInfoPresent) {
      userStore
        .getProfile(
          Cookies.get("employee-id"),
          Cookies.get("user-token"),
          Cookies.get("email")
        )
        .then((response) => {
          if (response?.id) {
            const redirectPath = Cookies.get("redirectPath") || "/home";

            Cookies.remove("redirectPath", { path: "/", domain: "localhost" });

            window.location.href = redirectPath;
          }
        });
    } else {
      window.location.href = process.env.REACT_APP_UNCATERING_APP_LINK;
    }
  }, [cookiesInfoPresent, navigate, userStore]);

  return (
    <div className="flex flex-col min-w-screen">
      <div className="flex justify-center items-center min-h-screen">
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-secondary fill-secondary"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539..."
              fill="currentFill"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Authentication;
