import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";

function ImageLightBox({ visible, imageSrc, closeModal }) {
  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[10000]"
        onClose={() => closeModal()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <button
              className="fixed z-[10000] top-6 right-8 text-white text-5xl font-bold outline-none"
              onClick={closeModal}
            >
              &times;
            </button>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="flex items-center select-none transform overflow-hidden rounded-2xl  px-8 py-5 text-left align-middle transition-all">
                <div className="flex flex-col items-center flex-wrap mt-3">
                  <img
                    id="modal-img"
                    src={imageSrc}
                    className="max-w-[90%] sm:max-w-[600px]  max-h-[600px] object-cover"
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>

    // <div
    //   id="modal"
    //   className={classNames({
    //     "fixed top-0 left-0 z-[100000] w-screen h-screen bg-black/70 flex justify-center items-center": true,
    //     hidden: !visible
    //   })}
    // >
    //   <button
    //     className="fixed z-90 top-6 right-8 text-white text-5xl font-bold"
    //     onClick={closeModal}
    //   >
    //     &times;
    //   </button>

    //   <img
    //     id="modal-img"
    //     src={imageSrc}
    //     class="max-w-[800px] max-h-[600px] object-cover"
    //   />
    // </div>
  );
}

export default ImageLightBox;
