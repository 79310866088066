import { toJS } from "mobx";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const reducer = (state, action, initialState) => {
  switch (action.type) {
    case "reset":
      return initialState ?? {};
  }
  return {
    ...state,
    [action.field]: action.value,
  };
};

export const groupBy = (x, f) =>
  x?.reduce((a, b, i) => ((a[f(b, i, x)] ||= []).push(b), a), {});

export const isPresent = (arr) => {
  return Array.isArray(arr) && arr?.length > 0;
};

export const getFiltersQuery = (dietaryQuery) => {
  return Array.isArray(dietaryQuery)
    ? dietaryQuery
    : dietaryQuery
    ? [dietaryQuery]
    : [];
};

export const hasMinimumTwo = (arr) => {
  return Array.isArray(arr) && arr?.length > 2;
};

export const hasOneElement = (arr) => {
  return Array.isArray(arr) && arr?.length === 1;
};

export const hasMinimumOne = (arr) => {
  return Array.isArray(arr) && arr?.length > 1;
};
export const hasOneItem = (arr) => {
  return Array.isArray(arr) && arr?.length === 1;
};

export const getLocationEmployees = (currentUser) => {
  let locationOrganisations = currentUser?.attributes?.employees?.filter(
    (employee) =>
      employee?.attributes?.organization_type === "Location" &&
      employee?.attributes?.role &&
      !employee?.attributes?.soft_deleted &&
      employee?.attributes?.active
  );

  const options = locationOrganisations?.map((location) => {
    return {
      value: location?.attributes?.organization_id,
      label: location?.attributes?.organization_name,
      street: location?.attributes?.organization_name,
      role: location?.attributes?.role,
      softDeleted: location?.attributes?.soft_deleted,
      destinationCode: location?.attributes?.destination_code,
    };
  });

  return options;
};

export const getLocationEmployeesWithAdmin = (currentUser) => {
  let locationOrganisations = currentUser?.attributes?.employees?.filter(
    (employee) =>
      employee?.attributes?.organization_type === "Location" &&
      employee?.attributes?.role &&
      (employee?.attributes?.role == "admin" ||
        employee?.attributes?.role == "team_admin") &&
      !employee?.attributes?.soft_deleted &&
      employee?.attributes?.active
  );

  const options = locationOrganisations?.map((location) => {
    return {
      value: location.id,
      label: location?.attributes?.organization_name,
      street: location?.attributes?.organization_name,
      role: location?.attributes?.role,
      softDeleted: location?.attributes?.soft_deleted,
      destinationCode: location?.attributes?.destination_code,
      organizationId: location?.attributes?.organization_id,
      uncatering: location?.attributes?.uncatering,
      accountPhone: location?.attributes?.organization_contact_number,
      invoicePayments: location?.attributes?.invoice_payments,
      account_billing_name: location?.attributes?.account_billing_name,
      account_billing_address: location?.attributes?.account_billing_address,
      account_billing_emails: location?.attributes?.account_billing_emails,
      tzinfo: location?.attributes?.tzinfo,
      accountBillingAddress: location?.attributes?.account_billing_address,
      accountBillingEmail: location?.attributes?.account_billing_emails,
      accountBillingName: location?.attributes?.account_billing_name,
    };
  });

  return options;
};

export const getLocationsEligibleForUncatering = (currentUser) => {
  let locationOrganisations = currentUser?.attributes?.employees?.filter(
    (employee) =>
      employee?.attributes?.organization_type === "Location" &&
      employee?.attributes?.role &&
      employee?.attributes?.role == "full_time" &&
      employee?.attributes?.uncatering &&
      !employee?.attributes?.soft_deleted &&
      employee?.attributes?.active
  );

  const options = locationOrganisations?.map((location) => {
    return {
      value: location.id,
      label: location?.attributes?.organization_name,
      street: location?.attributes?.organization_name,
      role: location?.attributes?.role,
      softDeleted: location?.attributes?.soft_deleted,
      destinationCode: location?.attributes?.destination_code,
      uncatering: location?.attributes?.uncatering,
      accountPhone: location?.attributes?.organization_contact_number,
      invoicesExist: location?.attributes?.invoice_payments,
      accountBillingAddress: location?.attributes?.account_billing_address,
      accountBillingEmail: location?.attributes?.account_billing_emails,
      accountBillingName: location?.attributes?.account_billing_name,
    };
  });

  return options;
};

export const getUncateringLocation = (currentUser) => {
  let locationOrganisations = currentUser?.attributes?.employees?.filter(
    (employee) =>
      employee?.attributes?.organization_type === "Location" &&
      employee?.attributes?.uncatering &&
      !employee?.attributes?.soft_deleted &&
      employee?.attributes?.active
  );

  return isPresent(locationOrganisations);
};

export const getLocationID = (currentUser, id) => {
  const employee = currentUser?.attributes?.employees?.find(
    (employee) => employee?.id === id
  );

  const locationId = employee ? employee?.attributes?.organization_id : null;

  return { locationId, employee };
};

export const isEmpty = (arr) => {
  return Array.isArray(arr) && arr?.length == 0;
};
