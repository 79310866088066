import React, { useState, useContext, useEffect, useMemo } from "react";
import { MobXProviderContext, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import EmbedHTML from "components/shared/embedHTML";
import dateTime from "utils/helpers/dateTime";
import Select from "react-select";
import { customStylesSubsidy } from "utils/helpers/styles";
import {
  needToPay,
  creditApplicable,
  getCreditDifference,
} from "utils/helpers/order";
import { errorHandler } from "utils/middlewares/errorHandler";
import ConfirmationModal from "components/ConfirmationModal";

function PlaceOrderCart({
  cart,
  selectedOffice,
  removeItemFromCart,
  resetAll,
  setTipValue,
  resetCart,
  clearCart,
  tip,
  setPlaceOrderConfirm,
  setSelectedOptions,
  cutlery,
  setCutlery,
  setOrder,
  setShowSuccessModal,
}) {
  const store = useContext(MobXProviderContext);
  const [bypassPlaceorder, setBypassPlaceorder] = useState(false);
  const [singleSelectOptions, setSingleSelectOptions] = useState([]);
  const [textOptions, setTextOptions] = useState([]);
  const [checkDefaultTipSet, setDefaultTipCheck] = useState(false);

  const userStore = store.userStore;
  const { defaultLocation } = userStore;
  const tipFeeUserSelectOptions =
    cart?.billing_setting?.tip_fee_user_select_options;
  const tipFeeType = cart?.billing_setting?.tip_fee_type;
  const tipFeeDisplayToUser = cart?.billing_setting?.tip_fee_display_to_user;

  useEffect(() => {
    if (
      tipFeeUserSelectOptions?.length > 0 &&
      tipFeeType == "user_select" &&
      tipFeeDisplayToUser &&
      !checkDefaultTipSet
    ) {
      const defaultTip = [...tipFeeUserSelectOptions].shift();

      setDefaultTipCheck(true);
      setTipValue({ label: `${defaultTip}%`, value: defaultTip });
    }

    let singleOptions = cart?.billing_references
      ? cart?.billing_references?.filter(
          (ref) => ref?.checkout_option_type == "single_select"
        )
      : [];

    let txtOptions = cart?.billing_references
      ? cart?.billing_references?.filter(
          (ref) => ref?.checkout_option_type == "text"
        )
      : [];

    if (singleOptions.length != singleSelectOptions.length) {
      setSingleSelectOptions(
        singleOptions?.map((ref) => ({
          ...ref,
          selected: ref?.default_value,
        })) || []
      );
    }

    if (txtOptions.length != textOptions.length)
      setTextOptions(
        txtOptions?.map((ref) => ({
          ...ref,
          value: ref?.default_value,
        })) || []
      );
  }, [cart]);

  useEffect(() => {
    if (tipFeeType !== "user_select" && checkDefaultTipSet) {
      setTipValue({ label: "0%", value: 0 });
    }
  }, [tipFeeType]);

  const tipLabels = useMemo(() => {
    return (
      tipFeeUserSelectOptions?.map((tip) => ({
        label: `${tip}%`,
        value: tip,
      })) || []
    );
  }, [tipFeeUserSelectOptions]);

  const subsidyRemaining = useMemo(() => {
    const subsidyRemaining = parseFloat(cart?.remaining_subsidy).toFixed(2);

    if (subsidyRemaining < 0) {
      return 0.0;
    } else {
      return Number(subsidyRemaining).toFixed(2) || 0.0;
    }
  }, [cart?.remaining_subsidy]);

  const cartPrice = () => {
    const amount = needToPay(cart);
    return amount === 0 ? "" : `($${amount.toFixed(2)})`;
  };

  const checkout = (skipStopShipment = false, skipCuttoff = false) => {
    const requiredOptions = singleSelectOptions?.filter((op) => op.required);
    const notSelected = requiredOptions?.filter((op) => !op.selected);

    if (requiredOptions?.length > 0 && notSelected?.length > 0) {
      errorHandler({
        title: `You cannot check out without select ${notSelected
          ?.map((op) => op.external_checkout_option_name)
          ?.join(", ")}.`,
      });
      return;
    }

    const requiredText = textOptions?.filter((op) => op.required);
    const notEntered = requiredText?.filter((op) => !op.value);

    if (requiredText?.length > 0 && notEntered?.length > 0) {
      errorHandler({
        title: `You cannot check out without providing ${notEntered
          ?.map((op) => op.external_checkout_option_name)
          ?.join(", ")}.`,
      });
      return;
    }

    let selectedReferences = singleSelectOptions
      ?.filter((ref) => ref.selected)
      ?.map((ref) => ({ billing_reference_id: ref.id, value: ref.selected }))
      .concat(
        textOptions
          ?.filter((ref) => ref.value)
          ?.map((ref) => ({ billing_reference_id: ref.id, value: ref.value }))
      );

    setSelectedOptions(selectedReferences);

    if (parseFloat(needToPay(cart)) > 0.2) {
      setPlaceOrderConfirm(true);
    } else {
      let payload = {
        amount:
          Number(needToPay(cart).toFixed(2)) +
          getCreditDifference(cart).toFixed(2),
        user_agent: window.navigator.userAgent,
        cutlery: cutlery,
        skip_stopped_shipment_check: skipStopShipment,
        tip_percentage: parseFloat(tip?.value / 100),
        order_billing_references_attributes: selectedReferences,
      };

      if (skipCuttoff) payload.skip_cutoff_check = true;
      let currentItemsCount = cart?.all_items?.data?.length;

      store.userStore.setLoader(true);

      store.placeOrderStore
        .getCart(cart?.billing_setting && tipFeeDisplayToUser, {
          location_employee_id: selectedOffice?.value,
          location_id: defaultLocation?.organizationId,
        })
        .then((response) => {
          if (currentItemsCount !== response?.all_items?.data?.length) {
            errorHandler({
              title: `${
                selectedOffice?.label ?? "This user"
              } has made changes to the cart while this order was being placed, please review the updated cart before placing the order and then update the cart.`,
            });

            store.userStore.setLoader(false);
            return;
          } else {
            store.placeOrderStore
              .placeOrder(payload, {
                location_id: defaultLocation?.organizationId,
                location_employee_id: selectedOffice?.value,
              })
              .then((response) => {
                const errorMessage = response?.message || "";
                const isInvalid = !response?.valid;

                store.userStore.setLoader(false);
                setBypassPlaceorder(false);

                if (response?.orders) {
                  resetAll();
                  resetCart();
                  setOrder(response?.orders);
                  setShowSuccessModal(true);
                } else {
                  if (
                    isInvalid &&
                    errorMessage?.includes("The order deadline has passed.")
                  ) {
                    store.userStore.setLoader(true);

                    checkout(skipStopShipment, true);
                  } else if (
                    isInvalid &&
                    errorMessage?.includes(
                      "There is a cart item with a stopped shipment"
                    )
                  )
                    setBypassPlaceorder(true);
                  else if (
                    isInvalid &&
                    errorMessage?.includes("Unfortunately")
                  ) {
                    errorHandler({ title: errorMessage });
                    clearCart();
                  } else {
                    errorHandler({ title: errorMessage });
                  }
                }
              });
          }
        });
    }
  };

  const showTip = useMemo(() => {
    return (
      cart?.billing_setting &&
      cart.billing_setting.tip_fee_type === "user_select" &&
      cart.billing_setting.tip_fee_display_to_user
    );
  }, [cart]);

  const orderDate = (item, cart) => {
    const orderDate = item?.attributes?.order_date;
    const cuttOff = item?.attributes?.cutoff;
    const cutoffDate = dateTime.onlyDate(cuttOff);
    const format = "dddd, Do MMMM YYYY";

    return orderDate !== cutoffDate
      ? `${dateTime.formatDate(
          orderDate,
          format
        )} - Order deadline: ${dateTime.formatDate(
          cutoffDate,
          format
        )}, ${dateTime.to12hourformat(
          dateTime.localWithUTCoffset(cuttOff, item?.attributes?.tzinfo),
          cart?.tzinfo
        )}`
      : `Order deadline: ${dateTime.formatDate(
          orderDate,
          format
        )}, ${dateTime.to12hourformat(
          dateTime.localWithUTCoffset(cuttOff, item?.attributes?.tzinfo),
          cart?.tzinfo
        )}`;
  };

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 overflow-y-auto h-[556px] w-full scrollbar-custom">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="d-col w-full rounded-lg border border-[#dee2e6] py-3">
          {cart?.all_items?.data?.map((item) => (
            <div className="d-col mb-4 px-4">
              <div className="d-col w-full">
                <div className="d-col  mt-2">
                  <span className="text-sm">{orderDate(item, cart)}</span>

                  <span className="text-xs text-gray-500">
                    {item?.attributes?.restaurant_name}
                  </span>
                </div>

                <div className="d-row justify-between mt-3">
                  <div className="d-row">
                    <FontAwesomeIcon
                      onClick={() => removeItemFromCart(item.id)}
                      icon={faTrashAlt}
                      color={"red"}
                      size="sm"
                      className="mr-3 mt-1"
                    />

                    <div className="d-col flex mt-[4px]">
                      <EmbedHTML
                        text={
                          item?.attributes?.menu_item?.display_name_with_html
                        }
                        className="text-black text-xs"
                      />

                      <small className="input-label">
                        {item?.attributes?.special_instructions}
                      </small>
                    </div>
                  </div>
                  <small className="input-label">
                    $ {parseFloat(item.attributes.total_price).toFixed(2)}
                  </small>
                </div>
              </div>
            </div>
          ))}

          <div className="border my-3" />

          <div className="d-row justify-between mt-3 px-4">
            <span className="input-label">Food</span>
            <small className="input-label">
              $ {parseFloat(cart?.subtotal).toFixed(2)}
            </small>
          </div>

          <div className="d-row justify-between mt-2 px-4">
            <span className="input-label">HST</span>
            <small className="input-label">
              $ {parseFloat(cart?.hst).toFixed(2)}
            </small>
          </div>

          <label className="inline-flex items-center mt-3 px-4">
            <input
              autoComplete="off"
              type="checkbox"
              className="form-checkbox"
              checked={cutlery}
              onChange={(e) => setCutlery(!cutlery)}
            />
            <span className="ml-2 font-inter-regular text-xs text-dark-gray">
              Cutlery Required
            </span>
          </label>

          {singleSelectOptions?.map((reference, index) => (
            <div
              className="flex flex-col py-2 px-4"
              key={`singleSelect-${reference.id}`}
            >
              <small className="input-label capitalize">
                {reference?.external_checkout_option_name}
              </small>

              <Select
                styles={customStylesSubsidy({ preferences: true })}
                placeholder={`Select ${reference?.external_checkout_option_name}`}
                className="input-label capitalize overflow-visible mt-1"
                options={reference?.options_for_single_select?.map((item) => ({
                  label: item,
                  value: item,
                }))}
                value={{
                  label: singleSelectOptions[index]?.selected,
                  value: singleSelectOptions[index]?.selected,
                }}
                onChange={(selected) => {
                  setSingleSelectOptions(
                    singleSelectOptions?.map((ref) =>
                      ref.id == reference.id
                        ? { ...reference, selected: selected?.value }
                        : ref
                    )
                  );
                }}
              />
            </div>
          ))}

          {textOptions?.map((reference, index) => (
            <div
              className="flex flex-col py-2 px-4"
              key={`textOption-${reference.id}`}
            >
              <small className="input-label capitalize">
                {reference?.external_checkout_option_name}
              </small>

              <input
                autoComplete="off"
                className="input-light text-md text-[#3A3A3A] border-none rounded-xl bg-[#F7F9FF] h-12"
                value={reference?.value}
                onChange={(e) =>
                  setTextOptions(
                    textOptions?.map((ref) =>
                      ref.id == reference.id
                        ? { ...reference, value: e.target?.value }
                        : ref
                    )
                  )
                }
                required
              />
            </div>
          ))}

          {showTip && (
            <div className="d-row justify-between mt-3 px-4">
              <span className="input-label items-center flex capitalize">
                {cart?.billing_setting?.external_tip_fee_name}
              </span>

              <div className="w-1/2">
                <Select
                  placeholder="Select tip"
                  closeMenuOnSelect
                  styles={customStylesSubsidy({ preferences: true })}
                  options={tipLabels}
                  selected={tip}
                  onChange={(selected) => setTipValue(selected)}
                />
              </div>
            </div>
          )}

          {showTip && (
            <div className="d-row justify-between mt-3 px-4">
              <span className="input-label capitalize">
                {cart?.billing_setting?.external_tip_fee_name} Amount
              </span>
              <small className="input-label">
                $ {parseFloat(cart?.tip).toFixed(2)}
              </small>
            </div>
          )}

          <div className="border my-3" />

          <div className="d-row justify-between mt-3 px-4">
            <span className="input-label">Total</span>
            <small className="input-label">
              $ {parseFloat(cart?.grand_total).toFixed(2)}
            </small>
          </div>

          <div className="d-row justify-between mt-3 px-4">
            <span className="input-label">Subsidized Food</span>
            <small className="input-label">
              $ {parseFloat(cart?.subsidized_subtotal).toFixed(2)}
            </small>
          </div>

          <div className="d-row justify-between mt-3 px-4">
            <span className="input-label">Subsidized Tax</span>
            <small className="input-label">
              $ {parseFloat(cart?.subsidized_hst).toFixed(2)}
            </small>
          </div>

          {showTip && (
            <div className="d-row justify-between mt-3 px-4">
              <span className="input-label">Subsidized Tip</span>
              <small className="input-label">
                $ {parseFloat(cart?.subsidized_tip).toFixed(2)}
              </small>
            </div>
          )}

          {cart?.display_remaining_subsidy && (
            <div className="d-row justify-between mt-3 px-4">
              <span className="input-label">Subsidy Remaining</span>
              <small className="input-label">$ {subsidyRemaining}</small>
            </div>
          )}

          {creditApplicable(cart) > 0 && (
            <div className="d-row justify-between mt-3 px-4">
              <span className="input-label">Credit Applied</span>
              <small className="input-label">
                $ {creditApplicable(cart).toFixed(2)}
              </small>
            </div>
          )}

          <div className="border my-3" />

          <div className="d-row justify-between mt-3 px-4">
            <span className="input-label font-inter-medium">Need to Pay</span>
            <small className="input-label font-inter-medium">
              $ {needToPay(cart).toFixed(2)}
            </small>
          </div>
        </div>

        <div className="d-col flex-end mt-5 px-4 items-end">
          <button
            onClick={() => checkout()}
            className="btn-blue-accent text-md w-1/3"
          >
            {needToPay(cart).toFixed(2) > 0.2
              ? "Next"
              : `Place Order ${cartPrice()}`}
          </button>
        </div>

        {bypassPlaceorder && (
          <ConfirmationModal
            visible={bypassPlaceorder}
            setModal={setBypassPlaceorder}
            title="Cart item with a stopped shipment"
            body="Restaurant has indicated that they cannot accept any additional orders at this time due to capacity limitations. Are you sure you want to place the order?"
            rightButtonText="Place Order"
            leftButtonText="Cancel"
            onClickRightButton={() => {
              checkout(true);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default observer(PlaceOrderCart);
