import React, { useState, useEffect, useRef, useContext } from "react";
import Rangeslider from "./rangeSlider";
import MyCalendar from "components/calendar";
import ActiveStepIndicator from "./activeStepIndicator";
import { weekDays } from "utils/constants/onboardingStep";
import { MobXProviderContext } from "mobx-react";
import { toJS } from "mobx";
import {
  CalendarIcon,
  calendarIcon,
  CalendarWhiteIcon,
  dangerIcon,
} from "assets/img";
import classNames from "classnames";
import { useEarliestDate } from "utils/hooks/useEarliestDate";

const Step3 = ({ disableButton }) => {
  const store = useContext(MobXProviderContext);
  const onboardingStore = toJS(store?.onboardingStore);
  const {
    selectedMealArray,
    enable_meal_delivery,
    recieving_days,
    isNotSureSelected,
    default_date,
    meal_allowance_value,
    meal_allowance_type,
  } = onboardingStore;

  const [defaultDate, setDefaultDate] = useState(default_date || "");
  const [selectDateHover, setSelectDateHover] = useState(false);
  const [mealDeliveryOption, setMealDeliveryOption] = useState(
    enable_meal_delivery || "every week"
  );
  const [mealRecievingDays, setMealRecievingDays] = useState(
    recieving_days || []
  );
  const [mealStartDate, setMealStartDate] = useState(
    isNotSureSelected
      ? "I am not sure yet"
      : default_date !== ""
      ? "select date"
      : ""
  );
  const [mealAllowanceType, setMealAllowanceType] = useState(
    meal_allowance_type || ""
  );
  const [mealAllowanceValue, setMealAllowanceValue] = useState(
    meal_allowance_value || 0
  );
  const [showCalendar, setShowCalendar] = useState(
    mealStartDate !== "" &&
      mealStartDate?.toLowerCase() !== "i am not sure yet" &&
      enable_meal_delivery?.toLowerCase() === "every week"
  );
  const calendarRef = useRef(null);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentMonthIndex = new Date().getMonth();
  const nextMonthIndex = (currentMonthIndex + 1) % 12;
  const nextNextMonthIndex = (currentMonthIndex + 2) % 12;

  const currentMonthName = monthNames[currentMonthIndex];
  const nextMonthName = monthNames[nextMonthIndex];
  const nextNextMonthName = monthNames[nextNextMonthIndex];

  const initialMealSelectedDates = [
    { [currentMonthName]: [] },
    { [nextMonthName]: [] },
    { [nextNextMonthName]: [] },
  ];

  const [mealSelectedDates, setMealSelectedDates] = useState(
    onboardingStore.recieving_dates || initialMealSelectedDates
  );

  let [
    mealSelectedDatesMonth1,
    mealSelectedDatesMonth2,
    mealSelectedDatesMonth3,
  ] = mealSelectedDates;

  let { earliestDate } = useEarliestDate();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    if (mealAllowanceValue === 0 && mealAllowanceType === "daily") {
      handleMealAllowance(20);
    } else if (mealAllowanceValue === 0 && mealAllowanceType === "weekly") {
      handleMealAllowance(100);
    }
  }, [mealAllowanceType, mealAllowanceValue]);

  useEffect(() => {
    if (mealDeliveryOption === "every week") {
      if (
        mealRecievingDays.length !== 0 &&
        mealStartDate !== "" &&
        mealAllowanceType !== ""
      ) {
        disableButton(false);
      } else {
        disableButton(true);
      }
    } else if (mealDeliveryOption === "i will set my own dates") {
      const allEmpty = mealSelectedDates.every(
        (month) => Object.values(month)[0]?.length == 0
      );

      if (!allEmpty && mealAllowanceType !== "") {
        disableButton(false);
      } else {
        disableButton(true);
      }
    }
  }, [
    mealDeliveryOption,
    mealSelectedDates,
    mealRecievingDays,
    mealAllowanceType,
    mealStartDate,
    disableButton,
  ]);

  useEffect(() => {
    let isEveryWeekSelected = false;
    let selectedEarliestDate = earliestDate(mealSelectedDates);

    if (mealDeliveryOption?.toLowerCase() == "i will set my own dates") {
      isEveryWeekSelected = false;
    } else if (mealDeliveryOption?.toLowerCase() == "every week") {
      isEveryWeekSelected = true;
    }
    store.onboardingStore.handleMealSeriesAndSubsidiesData(
      isEveryWeekSelected ? mealRecievingDays : [],
      isEveryWeekSelected
        ? mealStartDate == "select date"
          ? defaultDate
          : mealStartDate
        : selectedEarliestDate,
      !isEveryWeekSelected ? mealSelectedDates : [],
      mealAllowanceType,
      mealAllowanceValue,
      mealDeliveryOption
    );
  }, [
    onboardingStore,
    mealRecievingDays,
    mealStartDate,
    mealSelectedDates,
    mealAllowanceType,
    mealAllowanceValue,
    mealDeliveryOption,
    earliestDate,
    defaultDate,
  ]);

  const handleRecievingDaysClick = (value) => {
    if (mealRecievingDays.includes(value)) {
      setMealRecievingDays((prevDays) =>
        prevDays.filter((day) => day !== value)
      );
    } else {
      setMealRecievingDays((prevDays) => [...prevDays, value]);
    }
  };

  const handleMealAllowance = (value) => {
    const maxValue = mealAllowanceType?.toLowerCase() === "daily" ? 50 : 200;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (value >= 0 && value <= maxValue) {
      if (regex.test(value)) {
        setMealAllowanceValue(value);
      } else {
        setMealAllowanceValue(value);
      }
    }
  };

  const handleMealAllowanceType = (value) => {
    setMealAllowanceType(value);
    if (value === "daily") {
      setMealAllowanceValue(20);
    } else if (value === "weekly") {
      setMealAllowanceValue(100);
    } else {
      setMealAllowanceValue(0);
    }
  };

  const handleMultipleDateChange = (calendarKey, newDate) => {
    const updatedMonthsArray = mealSelectedDates.map((month) => ({
      ...month,
    }));

    if (newDate?.length > 0) {
      const selectedMonthIndex = newDate[0]?.month.number - 1;
      const selectedMonthName = monthNames[selectedMonthIndex];

      updatedMonthsArray[calendarKey - 1] = {
        [selectedMonthName]: newDate.map(
          (date) => `${date.day}-${date.month.number}-${date.year}`
        ),
      };
    } else {
      updatedMonthsArray[calendarKey - 1] = {
        [Object.keys(updatedMonthsArray[calendarKey - 1])[0]]: [],
      };
    }

    setMealSelectedDates(updatedMonthsArray);
  };

  const handleDateChange = (value) => {
    const parsedDate = new Date(value);
    setDefaultDate(
      parsedDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    );

    setMealAllowanceType("daily");
    setMealAllowanceValue(20);
  };

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  return (
    <div className="pb-4 mt-4">
      <ActiveStepIndicator step={3} />

      <div className="flex-col flex gap-6">
        <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
          How often do you want to enable {selectedMealArray?.meal_name}{" "}
          delivery?
        </div>

        <div className="mb-0.5 w-full screen-xs:w-full-215">
          <div className="screen-lg:inline-flex screen-md:inline-flex">
            <div
              className={`screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                mealDeliveryOption === "every week"
                  ? "bg-indigo-500 text-white "
                  : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
              } rounded-3xl justify-center gap-2 flex`}
              onClick={() => {
                setMealDeliveryOption("every week");
                if (defaultDate !== "") setShowCalendar(true);
                setMealRecievingDays([]);
                setMealStartDate("");
                setMealAllowanceType("");
                setDefaultDate("");
              }}
            >
              Every week
            </div>

            <div
              className={`screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                mealDeliveryOption === "i will set my own dates"
                  ? "bg-indigo-500 text-white "
                  : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
              } rounded-3xl justify-center gap-2 flex`}
              onClick={() => {
                setMealDeliveryOption("i will set my own dates");
                setShowCalendar(false);
                setMealAllowanceType("daily");
                setMealAllowanceValue(20);
                setMealSelectedDates([]);
                setDefaultDate("");
              }}
            >
              I will set my own dates
            </div>
          </div>
        </div>

        {mealDeliveryOption === "every week" && (
          <>
            <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
              What days?
            </div>

            <div className="mb-0.5 w-full screen-xs:w-full-215">
              <div className="screen-lg:inline-flex screen-md:inline-flex">
                {weekDays.map((value, index) => (
                  <div
                    key={index}
                    className={`screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] capitalize ${
                      mealRecievingDays.some((day) => day === value)
                        ? "bg-indigo-500 text-white "
                        : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                    } rounded-3xl justify-center gap-2 flex`}
                    onClick={() => handleRecievingDaysClick(value)}
                  >
                    {value}
                  </div>
                ))}
              </div>
            </div>

            <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
              When would you like to start?
            </div>

            <div className="mb-0.5 w-full screen-xs:w-full-215">
              <div className="screen-lg:inline-flex screen-md:inline-flex flex">
                <div className="mb-0.5 w-full screen-xs:w-full-215">
                  <div className="screen-lg:inline-flex screen-md:inline-flex">
                    <div
                      className={classNames(
                        "hidden screen-lg:flex screen-md:flex screen-lg:w-46 screen-md:w-46 h-12 screen-lg:mr-3 screen-md:mr-3 mt-4 px-4 py-3 rounded-3xl items-center justify-between cursor-pointer gap-2",
                        {
                          "bg-indigo-500 text-white ":
                            mealStartDate == "select date",
                          "bg-slate-50 text-slate-700 hover:bg-indigo-500 hover:text-white ":
                            mealStartDate !== "select date",
                        }
                      )}
                      onClick={() => {
                        setMealStartDate("select date");
                        setShowCalendar(true);
                      }}
                      onMouseEnter={() => setSelectDateHover(true)}
                      onMouseLeave={() => setSelectDateHover(false)}
                    >
                      Select date
                      <span>
                        <img
                          className="w-[20px] h-[20px] "
                          src={
                            mealStartDate == "select date" || selectDateHover
                              ? CalendarWhiteIcon
                              : CalendarIcon
                          }
                          alt="CalendarIcon"
                        />
                      </span>
                    </div>

                    <div
                      className={classNames(
                        "screen-lg:hidden screen-md:hidden flex h-12 mt-4 px-4 py-3 rounded-xl items-center justify-between cursor-pointer gap-2 bg-slate-50 text-slate-700"
                      )}
                      onClick={() => {
                        setMealStartDate("select date");
                        setShowCalendar(true);
                      }}
                      onMouseEnter={() => setSelectDateHover(true)}
                      onMouseLeave={() => setSelectDateHover(false)}
                    >
                      {defaultDate !== "" &&
                      defaultDate?.toLowerCase() !== "invalid date"
                        ? defaultDate
                        : "Select date"}
                      <span>
                        <img
                          className="w-[20px] h-[20px] "
                          src={calendarIcon}
                          alt="CalendarIcon"
                        />
                      </span>
                    </div>

                    <div
                      className={`screen-lg:w-40 screen-md:w-40 h-12 screen-lg:mr-3 screen-md:mr-3 mt-4 px-4 py-3 hover:cursor-pointer text-indigo-500 font-inter-medium text-sm leading-[14px] ${
                        mealStartDate?.toLowerCase() === "i am not sure yet"
                          ? "bg-indigo-500 text-white "
                          : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                      } screen-lg:rounded-3xl screen-md:rounded-3xl rounded-xl items-center justify-center gap-2 flex`}
                      onClick={() => {
                        setShowCalendar(false);
                        setMealStartDate("I am not sure yet");
                        setDefaultDate("");
                      }}
                    >
                      I am not sure yet
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {showCalendar && (
          <div className="w-44" ref={calendarRef}>
            <MyCalendar
              highlightToday={false}
              disableMonthYearPicker={true}
              isMultiple={false}
              showButtons={false}
              handleDatePicker={handleDateChange}
              value={1}
              selectedValue={defaultDate}
            />
          </div>
        )}

        {mealDeliveryOption === "i will set my own dates" && (
          <div className="flex inline-flex gap-5 overflow-x-auto z-0">
            <MyCalendar
              highlightToday={false}
              disableMonthYearPicker={true}
              isMultiple={true}
              showButtons={false}
              handleMultipleDates={(dates) =>
                handleMultipleDateChange(1, dates)
              }
              value={1}
              multipleValues={mealSelectedDatesMonth1}
              isOwnDates={true}
            />

            <MyCalendar
              highlightToday={false}
              disableMonthYearPicker={true}
              isMultiple={true}
              showButtons={false}
              handleMultipleDates={(dates) =>
                handleMultipleDateChange(2, dates)
              }
              value={2}
              multipleValues={mealSelectedDatesMonth2}
              isOwnDates={true}
            />

            <MyCalendar
              highlightToday={false}
              disableMonthYearPicker={true}
              isMultiple={true}
              showButtons={false}
              handleMultipleDates={(dates) =>
                handleMultipleDateChange(3, dates)
              }
              value={3}
              multipleValues={mealSelectedDatesMonth3}
              isOwnDates={true}
            />
          </div>
        )}

        {(mealDeliveryOption === "i will set my own dates" ||
          mealDeliveryOption === "every week") && (
          <>
            <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
              Meal allowance
            </div>

            <div className="mb-0.5 w-full screen-xs:w-full-215">
              <div className="screen-lg:inline-flex screen-md:inline-flex">
                <div
                  className={`screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                    mealAllowanceType === "daily"
                      ? "bg-indigo-500 text-white "
                      : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                  } rounded-3xl justify-center gap-2 flex`}
                  onClick={() => handleMealAllowanceType("daily")}
                >
                  Daily
                </div>

                <div
                  className={`screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                    mealAllowanceType === "weekly"
                      ? "bg-indigo-500 text-white "
                      : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                  } rounded-3xl justify-center gap-2 flex`}
                  onClick={() => handleMealAllowanceType("weekly")}
                >
                  Weekly
                </div>
              </div>
            </div>
          </>
        )}

        {mealAllowanceType !== "" && (
          <div className="screen-lg:w-[541px] screen-md:w-[541px] w-full  px-2 py-4 bg-slate-50 rounded-lg flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight">
              {capitalizeFirstLetter(mealAllowanceType)} per person allowance
            </div>

            <div className="hidden screen-lg:flex screen-md:flex screen-sm:hidden w-full screen-lg:w-[512px] screen-md:w-[512px] justify-between items-center gap-6 inline-flex">
              <div className="text-slate-700 text-base font-inter-normal leading-7">
                $0
              </div>

              <Rangeslider
                minValue={0}
                maxValue={mealAllowanceType === "daily" ? "50" : "200"}
                currentValue={mealAllowanceValue}
                setMealAllowance={handleMealAllowance}
              />

              <div className="text-slate-700 text-base font-inter-normal leading-7">
                {mealAllowanceType === "daily" ? "$50" : "$200"}
              </div>
            </div>

            <div className="screen-lg:w-[512px] screen-md:w-[512px] w-full flex-col gap-1 flex">
              <div className="h-[46px] rounded-xl mb-2 bg-white">
                <span className="ml-4 text-slate-700 font-inter-medium">$</span>

                <input
                  type="text"
                  id="otherMeal"
                  value={mealAllowanceValue}
                  min={0}
                  max={mealAllowanceType?.toLowerCase() === "daily" ? 50 : 200}
                  onChange={(e) => handleMealAllowance(e.target.value)}
                  className="h-[42px] w-1/2 pl-0 p-4 rounded-xl text-base font-inter-medium leading-7 text-slate-700 outline-none"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step3;
