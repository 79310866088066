import React, { useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const TimePicker = ({
  text,
  placeholder,
  otherMealName,
  mealName,
  isCompulsary,
  flexibleWidth = false,
  backgroundColor,
  editIcon,
  handleEditClick,
  handleSave,
  step2,
}) => {
  const [isInputClicked, setIsInputClicked] = useState(false);
  const asteric = <span style={{ color: "red" }}>*</span>;

  const labelText =
    isInputClicked && !mealName
      ? `${placeholder}${asteric.props.children}`
      : `${placeholder}*`;

  const handleInputChange = (event) => {
    if (isCompulsary) setIsInputClicked(true);
    otherMealName(event.target.value);
  };

  return (
    <div
      className={` ${
        (!flexibleWidth ? "lg:w-[586px] md:w-[586px]" : "",
        backgroundColor ? backgroundColor : "bg-slate-50")
      }  h-full px-2 py-4 rounded-lg flex-col justify-start items-start gap-2.5 inline-flex`}
    >
      <label className="text-gray-800 text-lg font-inter-semibold leading-normal">
        {text}
      </label>

      <div className="relative w-full lg:w-[512px] md:w-[512px]">
        <input
          type="text"
          id="otherMeal"
          className={classNames(
            "w-full h-[60px] pr-10 pl-4 p-4 rounded-xl text-base font-inter-medium leading-7",
            mealName ? "text-slate-700" : "text-zinc-200",
            isCompulsary && isInputClicked && !mealName
              ? "outline-red-400"
              : "",
            !editIcon && "bg-white"
          )}
          placeholder={isCompulsary ? labelText : placeholder}
          value={mealName}
          onChange={handleInputChange}
          disabled={!editIcon}
        />

        {!editIcon && (
          <FontAwesomeIcon
            icon={faEdit}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-slate-700"
            style={{ width: 16, height: 16 }}
            onClick={handleEditClick}
          />
        )}

        {editIcon && mealName && !step2 && (
          <div className="flex justify-end mt-2">
            <button
              onClick={handleSave}
              className="btn-light-gray text-[16px] w-[146px] text-white bg-catering rounded-xl"
            >
              Save
            </button>
          </div>
        )}
      </div>

      {isCompulsary && isInputClicked && !mealName && (
        <div className="px-4 text-red-400 text-xs font-inter-medium leading-[14px]">
          This field needs to be filled
        </div>
      )}
    </div>
  );
};

export default TimePicker;
