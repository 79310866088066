import React, { useState, useEffect } from "react";
import { NotFound } from "components";
import { Transition } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "assets/img";
import { useNavigate } from "react-router-dom";
import dateTime from "utils/helpers/dateTime";
import { isEmpty, isPresent } from "utils/helpers/array";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import {
  uncateringShipmentStatus,
  cateringShipmentStatus,
  groupedShipmentStatus,
} from "utils/helpers/shipment";

const ExpandableRow = ({ meal, locationId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const service = meal?.attributes?.service;

  let userOrdersIds =
    (meal?.attributes?.orders?.length &&
      meal?.attributes?.orders?.map(
        (order) => order.attributes?.employee_id
      )) ||
    [];

  const notCancelledOrders = meal?.attributes?.orders?.filter(
    (order) => order?.attributes?.status !== "cancelled"
  );

  const currentTimeInTz = dayjs().tz(meal?.attributes?.tzinfo);

  const cutoffTime = dayjs(meal?.attributes?.cutoff).tz(
    meal?.attributes?.tzinfo,
    true
  );

  const oneOrderInProgress = meal?.attributes?.orders?.filter(
    (order) =>
      order?.attributes?.status != "cancelled" &&
      order?.attributes?.status != "completed"
  );

  const allOrdersCompleted =
    isPresent(meal?.attributes?.orders) &&
    meal?.attributes?.orders?.every(
      (order) => order?.attributes?.status == "completed"
    )
      ? true
      : false;

  return (
    <div className="w-full">
      {/* Row Header */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="grid grid-cols-8 w-full bg-light-gray min-h-[68px] items-center px-[12px] mt-[12px] rounded-md">
          <div className="col-span-1">
            <span
              className={`font-inter-semibold capitalize text-[14px] ${
                meal.attributes?.service === "uncatering"
                  ? "text-uncatering"
                  : meal.attributes?.service === "catering"
                  ? "text-catering"
                  : "text-group-order"
              }`}
            >
              {meal?.attributes?.service == "odc" ||
              meal?.attributes?.service == "catering"
                ? "Catering"
                : meal.attributes?.service == "grouped"
                ? "Group Order"
                : meal.attributes?.service ?? "Not specified"}
            </span>
          </div>

          <div className="col-span-1">
            <span className="font-inter-semibold text-[14px] text-dark-gray normal-case">
              {meal?.attributes?.name}
            </span>
          </div>

          <div className="col-span-1">
            <p className="font-inter-semibold pr-2 text-[14px] break-normal text-dark-gray break-all">
              {(!meal?.attributes?.under_review || service != "uncatering") && (
                <>
                  {meal?.attributes?.address?.attributes?.nickname ?? "-"} (
                  {meal?.attributes?.address?.attributes?.destination_code})
                </>
              )}
            </p>
          </div>

          <div className="col-span-1 overflow-scroll text-no-wrap">
            <p className="font-inter-semibold text-[14px] text-dark-gray break-normal">
              {!meal?.attributes?.under_review && (
                <>
                  {service == "uncatering" ? (
                    isPresent(meal?.attributes?.shipments) && !isOpen ? (
                      <div className="d-col">
                        <small className="text-[13px] font-inter-semibold text-dark-gray">
                          {meal?.attributes?.shipments[0]?.menu_name}
                        </small>

                        {meal?.attributes?.shipments?.length > 1 && (
                          <small className="font-inter-medium text-[13px] underline">
                            + {meal?.attributes?.shipments?.length} more
                          </small>
                        )}
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    "Restaurant"
                  )}
                </>
              )}
            </p>
          </div>

          <div className="col-span-1">
            <span className="font-inter-semibold text-[14px] text-dark-gray">
              {(!meal?.attributes?.under_review || service != "uncatering") &&
                new Set(userOrdersIds).size}
            </span>
          </div>

          <div className="col-span-1">
            {meal?.attributes?.under_review && service == "uncatering" ? (
              <small className="font-inter-medium text-[12px] text-[#E39F55] pb-[4px]">
                Under review
              </small>
            ) : (
              <span className="font-inter-semibold text-[14px] text-dark-gray">
                {!meal?.attributes?.published &&
                  service != "grouped" &&
                  "Restaurants to be picked soon"}

                {!meal?.attributes?.published &&
                  service == "grouped" &&
                  "Deadline to order: " +
                    dateTime.formatTimeWithFormat(
                      meal?.attributes?.cutoff,
                      "hh:mm a",
                      meal?.attributes?.tzinfo
                    )}

                {meal?.attributes?.published &&
                  isEmpty(meal?.attributes?.orders) &&
                  currentTimeInTz.isBefore(cutoffTime) &&
                  "No orders placed"}

                {meal?.attributes?.published &&
                  isPresent(meal?.attributes?.orders) &&
                  isEmpty(notCancelledOrders) &&
                  "No orders placed"}

                {meal?.attributes?.published &&
                  isPresent(meal?.attributes?.orders) &&
                  currentTimeInTz.isAfter(cutoffTime) &&
                  isPresent(oneOrderInProgress) &&
                  "In Progress"}

                {meal?.attributes?.published &&
                  isPresent(meal?.attributes?.orders) &&
                  currentTimeInTz.isBefore(cutoffTime) &&
                  isPresent(oneOrderInProgress) &&
                  "Deadline to order: " +
                    dateTime.formatTimeWithFormat(
                      meal?.attributes?.cutoff,
                      "hh:mm a",
                      meal?.attributes?.tzinfo
                    )}

                {meal?.attributes?.published &&
                  allOrdersCompleted &&
                  "Complete"}
              </span>
            )}
          </div>

          <div className="col-span-1">
            {(service == "catering" || service == "odc") &&
              meal?.attributes?.shipments?.length <= 1 && (
                <div className="d-row items-center justify-center md:px-1">
                  <button
                    onClick={() => {
                      navigate(
                        `/meal-management/catering-order/${meal?.attributes?.orders[0]?.attributes?.slug}/${locationId}`
                      );
                    }}
                    className="btn-outline w-[93px] font-inter-medium border-2 text-catering px-0"
                  >
                    View order
                  </button>
                </div>
              )}

            {service != "catering" &&
              service != "odc" &&
              (!meal?.attributes?.under_review || service != "uncatering") && (
                <div className="d-row items-center justify-center md:px-1">
                  <button
                    onClick={() => {
                      service == "grouped"
                        ? navigate(
                            `/group-order/admin/orders/${meal?.attributes?.meal_series_id}/${locationId}`
                          )
                        : navigate(`/home/uncatering-meal/${meal?.id}`);
                    }}
                    className="btn-outline w-[93px] font-inter-medium border-2 text-catering px-0"
                  >
                    View order
                  </button>
                </div>
              )}
          </div>

          <div className="col-span-1">
            {(!meal?.attributes?.under_review || service != "uncatering") &&
              meal?.attributes?.shipments?.length > 0 && (
                <div className="d-row items-center justify-center">
                  <img
                    src={isOpen ? ChevronLeftIcon : ChevronRightIcon}
                    className="h-[20px] w-[20px] transform-left transform rotate-90"
                  />
                </div>
              )}
          </div>
        </div>
      </div>

      {/* Expandable Content */}
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        {meal?.attributes?.shipments?.map((shipment, ind, arr) => {
          return (
            <div className="grid grid-cols-8 w-full bg-white min-h-[68px] items-center px-[12px] mt-[12px] rounded-md">
              <span></span>
              <span></span>
              <span></span>
              <p
                title={shipment?.menu_name}
                className="font-inter-medium capitalize text-[14px] text-dark-gray pr-2 break-normal"
              >
                {shipment?.menu_name}
              </p>
              <span className={`font-inter-medium text-[14px] text-dark-gray`}>
                {shipment?.users_ordered_count}
              </span>

              <span className="font-inter-medium text-[14px] text-dark-gray capitalize">
                {service == "uncatering"
                  ? uncateringShipmentStatus(
                      shipment,
                      meal?.attributes?.tzinfo,
                      meal?.attributes?.target_time
                    )
                  : service == "grouped"
                  ? groupedShipmentStatus(shipment, meal?.attributes?.tzinfo)
                  : cateringShipmentStatus(shipment, meal?.attributes?.tzinfo)}
              </span>

              <div className="d-row items-center justify-center md:px-1">
                {(service == "odc" || service == "catering") &&
                  arr?.length > 1 && (
                    <button
                      onClick={() => {
                        navigate(
                          `/meal-management/catering-order/${meal?.attributes?.orders[ind]?.attributes?.slug}/${locationId}`
                        );
                      }}
                      className="btn-outline w-[93px] font-inter-medium border-2 text-catering px-0"
                    >
                      View order
                    </button>
                  )}
              </div>

              <div></div>
            </div>
          );
        })}
      </Transition>
    </div>
  );
};

function ActiveMeals({ data, locationId }) {
  const [screenSize, setScreenSize] = useState("md");
  const [expandData, setExpandData] = useState(false);
  const navigate = useNavigate();

  let meals = data?.length > 5 && !expandData ? data?.slice(0, 5) : data;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setScreenSize("sm");
      } else if (window.innerWidth < 1024) {
        setScreenSize("md");
      } else {
        setScreenSize("lg");
      }
    };

    handleResize(); // Initial call to set the initial screen size

    window.addEventListener("resize", handleResize); // Listen for window resize events

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isPresent(data)) {
    return (
      <div className="flex-col w-full items-center flex">
        <div className="grid grid-cols-8 w-full bg-light-gray h-[46px] items-center px-[12px] rounded-md mt-[24px]">
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Type
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Name
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Address
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Restaurant
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Users Ordered
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Status
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray"></span>
          <span className="font-inter-bold text-[14px] text-dark-gray"></span>
        </div>

        <div className=" mx-auto w-full">
          <div className="w-full">
            {meals?.map((meal, index) => (
              <ExpandableRow key={index} meal={meal} locationId={locationId} />
            ))}
          </div>

          {data?.length > 5 && (
            <div className="d-row justify-center w-full">
              <button
                onClick={() => {
                  setExpandData(!expandData);

                  window.scroll({ top: 0, left: 0, behavior: "smooth" });
                }}
                className="text-catering d-row items-center font-inter-medium text-[16px] mt-[18px]"
              >
                {expandData ? "Show Less Orders" : "Show All Orders"}

                <FontAwesomeIcon
                  icon={expandData ? faChevronLeft : faChevronRight}
                  style={{
                    color: "#575BE8",
                  }}
                  className="transform-left ml-2 transform rotate-90"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="d-col items-center">
      <NotFound data={data} message="No meals" />

      <button
        onClick={() => {
          navigate("/our-services");
        }}
        className="btn-blue-accent"
      >
        Learn about our services
      </button>
    </div>
  );
}

export default ActiveMeals;
