import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function SearchBar({
  style,
  className,
  handleOnChange,
  handleOnBlur,
  placeholder = "Search by restaurant name",
  value,
}) {
  return (
    <div
      style={{ height: "48px", ...style }}
      className={`flex items-center justify-between  shadow-sm rounded-lg bg-background overflow-hidden h-10 md:h-9 md:mr-2 ${
        className ? className : "md:w-80"
      }`}
    >
      <input
        type="text"
        value={value}
        autoComplete="off"
        placeholder={placeholder}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        className="w-full text-md font-inter-regular pl-5 focus:outline-none bg-background text-black h-full"
      />

      <button aria-label="search restaurant" className="px-3 btn-style">
        <FontAwesomeIcon
          icon={faSearch}
          color={"#2D3958"}
          size="1x"
          className="small-icon"
        />
      </button>
    </div>
  );
}

export default SearchBar;
