import { faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import EmbedHTML from "components/shared/embedHTML";
import { observer } from "mobx-react";
import { useState } from "react";
import { ORDER_ITEM_OPTIONS_COUNT } from "utils/constants/common";
import { hasMinimumTwo } from "utils/helpers/array";
import { priceFormatter } from "utils/helpers/validation";

function GroupOrderCheckoutItem({ orderItem, moreThanOneItemInCategory }) {
  const [optionsShown, setOptionsShown] = useState(ORDER_ITEM_OPTIONS_COUNT);

  let orderItemSpecialInstructions =
    orderItem?.attributes?.special_instructions;

  return (
    <div
      className={classNames(`flex flex-col last:border-b-0 mb-2 md:mb-0`, {
        "border-b pb-4 border-border-gray": moreThanOneItemInCategory,
        "border-b-0": !moreThanOneItemInCategory,
      })}
    >
      <div key={orderItem?.id} className="mb-3">
        <div className="my-3">
          <div className="flex justify-between">
            <span className="text-md text-primary-black font-inter-medium w-3/5">
              <EmbedHTML
                text={`<div className="flex"><p className="mr-[5px]">${orderItem?.attributes?.quantity}x</p> ${orderItem?.attributes?.menu_item?.display_name_with_html}</div>`}
                className="text-primary-black"
              />
            </span>

            <div className="flex flex-col w-2/5">
              <span
                className="text-primary-black font-inter-medium text-right"
                style={{ fontSize: 17 }}
              >
                {priceFormatter(orderItem?.attributes?.total_price)}
              </span>

              {orderItem?.attributes?.quantity > 1 && (
                <span
                  className="text-sm text-right"
                  style={{ color: "#8F979F" }}
                >
                  {priceFormatter(orderItem?.attributes?.menu_item?.price)} per
                  item
                </span>
              )}
            </div>
          </div>

          {orderItem?.attributes?.order_item_options
            ?.slice(0, optionsShown)
            ?.map((option, index) => (
              <div className="flex" key={option.id}>
                <span className="d-row text-sm">
                  <EmbedHTML
                    isRow
                    text={
                      option?.attributes?.option_group_display_name_with_html
                    }
                    append={": "}
                    appendClass="mr-1"
                    className="text-primary-black"
                  />
                  <EmbedHTML
                    text={option?.attributes?.option_display_name_with_html}
                    className="text-primary-black"
                  />
                </span>
              </div>
            ))}

          {hasMinimumTwo(orderItem?.attributes?.order_item_options) &&
            optionsShown == ORDER_ITEM_OPTIONS_COUNT && (
              <button
                className="text-sm underline btn-style"
                onClick={() =>
                  setOptionsShown(
                    orderItem.attributes.order_item_options.length
                  )
                }
              >
                +{orderItem?.attributes?.order_item_options.length - 2} Show all
              </button>
            )}
        </div>

        {orderItemSpecialInstructions && (
          <div className="flex flex-col bg-background p-2 rounded-md">
            {orderItemSpecialInstructions && (
              <div className="flex items-center ">
                <div className="flex" style={{ width: 12, height: 12 }}>
                  <FontAwesomeIcon
                    icon={faComment}
                    size="1x"
                    className="small-icon"
                  />
                </div>

                <span className="text-sm ml-2">
                  Instructions:{" "}
                  <span className="font-inter-medium">
                    {orderItemSpecialInstructions}
                  </span>
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(GroupOrderCheckoutItem);
