import { ChevronDown, ChevronUp, FilterIcon } from "assets/img";
import classNames from "classnames";
import { useCallback } from "react";
import { useState, useRef } from "react";

function MultiSelectDropdown({ options, prompt, onChange, selectedOption }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const optionsListRef = useRef(null);

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    const option = e.target.value;

    const selectedOptionSet = new Set(selectedOptions);
    isChecked
      ? selectedOptionSet.add(option)
      : selectedOptionSet.delete(option);

    const newSelectedOptions = Array.from(selectedOptionSet);

    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen((prev) => !prev);
  }, []);

  return (
    <div
      className={classNames("relative w-[196px]", {
        "border rounded-xl": !isDropdownOpen,
      })}
    >
      <div
        className={classNames(
          "cursor-pointer w-full h-12 rounded-t-xl justify-between bg-background after:text-xs after:ml-1 after:inline-flex after:items-center peer-checked:after:-rotate-180 after:transition-transform inline-flex px-2 py-2",
          { "rounded-t-xl border-t border-l border-r": isDropdownOpen },
          { "rounded-xl": !isDropdownOpen }
        )}
        onClick={toggleDropdown}
      >
        <div className="flex items-center">
          <img
            src={FilterIcon}
            alt="filter-icon"
            className="w-[20px] h-[16px]"
          />
        </div>

        <div className="flex items-center font-inter-normal text-neutral-700">
          <span className="ml-2">{prompt}</span>
          {selectedOptions?.length > 0 && (
            <span className="ml-1 font-inter-semibold">{`(${selectedOptions?.length})`}</span>
          )}
        </div>

        <div className="flex items-center">
          <img
            src={isDropdownOpen ? ChevronUp : ChevronDown}
            alt="filter-icon"
            className="w-[20px] h-[16px] justify-center"
          />
        </div>
      </div>

      {isDropdownOpen && (
        <div className="rounded-b-xl absolute z-10 bg-white w-full border-b border-l border-r">
          <ul ref={optionsListRef}>
            {options?.map((option, i) => {
              return (
                <li key={option}>
                  <label
                    className={`flex whitespace-nowrap cursor-pointer px-2 py-1`}
                  >
                    <input
                      type="checkbox"
                      value={option}
                      checked={selectedOption?.includes(option)}
                      className="cursor-pointer"
                      onChange={handleChange}
                    />
                    <span
                      className={classNames("ml-3 font-inter-medium", {
                        "text-pink-600": option === "Uncatering",
                        "text-indigo-900": option !== "Uncatering",
                      })}
                    >
                      {option}
                    </span>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default MultiSelectDropdown;
