import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ArrowDownIcon } from "assets/img";

const DPicker = ({
  selectedDate,
  dateFormat = "MMMM d, yyyy",
  className,
  pickerClassName,
  minDate,
  activeRestaurant,
  availableDates,
  getAvailableDates,
  getAvailableTimes,
  fromModal,
  internalAddress,
  setSelectedDate,
  isGroupOrder,
  placeholderText = "Select Date",
  showIcon = true,
  iconPadding = true,
  isInvoice = false,
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <div
      className={classNames(className, { "cursor-pointer": true })}
      onClick={() => setOpened(true)}
    >
      <DatePicker
        // open={opened}
        forceShowMonthNavigation={true}
        includeDates={availableDates?.map((date) => moment(date).toDate())}
        onMonthChange={(date) => {
          if (!isInvoice) getAvailableDates(internalAddress, date);
        }}
        onCalendarOpen={() => {
          if (selectedDate && !isInvoice)
            getAvailableDates(internalAddress, selectedDate);
        }}
        placeholderText={placeholderText}
        selected={selectedDate ? new Date(moment(selectedDate)) : ""}
        dateFormat={dateFormat}
        onFocus={(e) => e.target.blur()}
        minDate={minDate}
        onClickOutside={() => setOpened(false)}
        onChange={(date) => {
          setSelectedDate(moment(date).format("YYYY-MM-DD"));
          if (!isInvoice) getAvailableTimes(internalAddress, date);
        }}
        className={classNames(
          "placeholder-[#566884] text-[#2D3958] rounded-md pl-2 py-1 cursor-pointer text-left focus:outline-none",
          pickerClassName,
          {
            "font-inter-regular": !pickerClassName,
            "bg-white": isInvoice,
            "bg-light-gray": !isInvoice,
          }
        )}
        formatWeekDay={(nameOfDay) =>
          nameOfDay == "Thursday" ? nameOfDay.slice(0, 2) : nameOfDay.charAt(0)
        }
        popperPlacement="auto"
      />

      {!showIcon && (
        <img
          src={ArrowDownIcon}
          alt="arrow-down"
          className={`${iconPadding ? "pr-8" : ""}`}
        />
      )}

      {showIcon && (
        <div
          className={classNames(
            "overflow-hidden",
            "rounded-md",
            isInvoice ? "bg-white" : "bg-light-gray"
          )}
        >
          <FontAwesomeIcon
            icon={faCalendar}
            color={"#2D3958"}
            size="1x"
            className="m-1.5 small-icon overflow-hidden"
          />
        </div>
      )}
    </div>
  );
};

export default observer(DPicker);
