import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { MobXProviderContext } from "mobx-react";
import MultiSelect from "components/MultiSelect";
import { errorHandler } from "utils/middlewares/errorHandler";

function AssignTeam({ visible, setModal, teams, mealPlanId, fromMeal, meal }) {
  const [selectedTeams, setSelectedTeams] = useState([]);

  const { mealManagementStore } = useContext(MobXProviderContext);
  const { homeStore } = useContext(MobXProviderContext);
  const { userStore } = useContext(MobXProviderContext);

  const { locationID } = userStore;

  let labels = teams?.map((team) => {
    return { label: team?.attributes?.name, value: team?.id };
  });

  const applyPressed = () => {
    if (fromMeal) {
      homeStore
        ?.updateMeal(
          meal?.id,
          {
            meal: {
              team_meals_attributes: selectedTeams?.map((team) => {
                return { team_id: team?.value };
              }),
            },
          },
          locationID
        )
        .then(() => setModal(false))
        .catch((error) => {
          setModal(false);
          errorHandler(error.response);
        });
    } else
      mealManagementStore
        .updateMealPlan(locationID, mealPlanId, {
          meal_series: {
            team_meal_plans_attributes: selectedTeams?.map((team) => {
              return { team_id: team?.value };
            }),
          },
        })
        .then(() => setModal(false))
        .catch((error) => {
          setModal(false);
          errorHandler(error.response);
        });
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[667px] h-[461px] d-col justify-between rounded-xl transform overflow-scroll sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="d-col">
                  <div className="d-row justify-between items-center">
                    <h2 className="text-2xl font-inter-bold">Team</h2>
                    <button
                      onClick={() => setModal(false)}
                      className="text-dark-gray hover:text-yellow-600"
                      aria-label="Close"
                    >
                      <span className="text-xl">&times;</span>
                    </button>
                  </div>

                  <MultiSelect
                    options={labels}
                    selectedOptions={selectedTeams}
                    handleChange={(values) => setSelectedTeams(values)}
                    placeholder={
                      fromMeal
                        ? "Choose teams to attach to your meal"
                        : "Choose teams to attach to your meal plan"
                    }
                    handleRemoveOption={(values) => setSelectedTeams(values)}
                    className="w-full mt-[24px]"
                  />
                </div>

                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3"
                    onClick={applyPressed}
                  >
                    Apply
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AssignTeam;
