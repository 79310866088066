import React, {
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import Screen from "utils/helpers/ScreenSize";
import { Dialog, Transition } from "@headlessui/react";
import { MobXProviderContext } from "mobx-react";
import { toJS } from "mobx";
import { priceFormatter } from "utils/helpers/validation";
import { observer } from "mobx-react";
import EmbedHTML from "components/shared/embedHTML";
import ConfirmationModal from "components/ConfirmationModal";
import classNames from "classnames";
import { useMenuItemSelection } from "utils/hooks/useMenuItemSelection";
import CloseButton from "components/CloseButton";
import SubmitActions from "components/AddItemModal/SubmitActions";

function AddGroupItemModal({
  visible,
  setModal,
  selectedItem,
  openedItems,
  addGroupOrderItemToCart,
  updateItem,
  edit,
  setImage,
  editItem,
  attendeeToUpdate,
  onClickRemoveAttendee,
  showEditSection = false,
  setModalKey,
}) {
  const screenType = Screen.ScreenType();
  const [attachLabelAlert, setAttachLabelAlert] = useState(null);
  const [isMultipleItemArray, setIsMultipleItemArray] = useState(
    editItem && editItem.length > 1
  );

  const calculateInitialPrice = () => {
    if (edit) {
      if (isMultipleItemArray) {
        return editItem?.map((item) => ({
          [item.attributes.menu_item.id]: item.attributes.total_price,
        }));
      } else {
        const { menu_item, total_price } = editItem?.attributes;
        return { [menu_item?.id]: total_price };
      }
    } else return openedItems?.[selectedItem]?.attributes.price;
  };

  const [getItemTotalPrice, setItemTotalPrice] = useState(
    calculateInitialPrice()
  );

  const [optionTypesPrice, setOptionTypesPrice] = useState(edit ? {} : 0);

  const store = useContext(MobXProviderContext);
  const groupOrderStore = toJS(store?.groupOrderStore);
  let { setSelectedOptions, initRequiredCheck, updateQuantity } =
    useMenuItemSelection();
  const menuItem = edit
    ? editItem?.attributes?.menu_item
    : openedItems?.[selectedItem]?.attributes;

  const router = null;
  const isAdmin = router?.query?.admin;

  const [fullName, setFullName] = useState(
    attendeeToUpdate?.attributes?.full_name || ""
  );

  const [foodTotalPrice, setFoodTotalPrice] = useState(
    attendeeToUpdate?.attributes?.subtotal
  );

  function createInitialProvider() {
    return {
      isInTime: true,
      optionTypes: {},
      instructions: "",
      nameLabel: "",
      quantity: 1,
      orderItemOptionId: "",
      addItem: {
        options: [],
        instructions: "",
      },
    };
  }

  let initialProvider = isMultipleItemArray
    ? editItem?.map(() => createInitialProvider())
    : createInitialProvider();

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialProvider;
      default:
        if (isMultipleItemArray) {
          return state.map((provider, index) =>
            index == action.index
              ? { ...provider, [action.field]: action.value }
              : provider
          );
        } else {
          return {
            ...state,
            [action.field]: action.value,
          };
        }
    }
  }

  const [provider, dispatch] = useReducer(reducer, initialProvider);

  let { nameLabel } = provider;

  let attendeeId,
    orderDetails = null;

  useEffect(() => {
    dispatch({ type: "reset" });

    if (!edit) initRequiredCheck(menuItem, dispatch);
    else {
      isMultipleItemArray
        ? editItem.forEach((item, index) => {
            setSelectedOptions(item, "order_item_options", dispatch, index);
          })
        : setSelectedOptions(editItem, "order_item_options", dispatch);
    }
  }, [editItem]);

  const getOptionTypeIDs = (provider) => {
    const options = provider?.optionTypes
      ? Object.values(provider?.optionTypes).flat()
      : [];
    dispatch({
      field: "addItem",
      value: {
        ...provider?.addItem,
        options: options,
      },
    });
  };

  useEffect(() => {
    if (edit) setModalKey((prev) => prev + 1);
  }, [foodTotalPrice, getItemTotalPrice, menuItem, provider]);

  const updateTotalPrices = (menuItem, provider, updatedOptionTypesPrice) => {
    let selectedOptionsSum = 0;
    let updatedTotalPrice = { ...getItemTotalPrice };

    selectedOptionsSum = Number(menuItem?.price);

    let totalItemPrice = parseFloat(
      parseFloat(
        edit ? updatedOptionTypesPrice[menuItem?.id] : updatedOptionTypesPrice
      ) + (selectedOptionsSum || parseFloat(menuItem?.price))
    ).toFixed(2);

    if (edit && isMultipleItemArray) {
      for (let key in updatedTotalPrice) {
        if (menuItem?.id in updatedTotalPrice[key]) {
          updatedTotalPrice[key][menuItem?.id] =
            totalItemPrice *
            (provider?.quantity == "" ? 1 : provider?.quantity);
        }
      }
      setItemTotalPrice(updatedTotalPrice);
    } else if (edit && !isMultipleItemArray) {
      updatedTotalPrice[menuItem?.id] =
        totalItemPrice * (provider?.quantity == "" ? 1 : provider?.quantity);
      setItemTotalPrice(updatedTotalPrice);
    } else {
      setItemTotalPrice(
        totalItemPrice * (provider?.quantity == "" ? 1 : provider?.quantity)
      );
    }

    let total = 0;
    Object.values(updatedTotalPrice).forEach((item) => {
      Object.values(item).forEach((value) => {
        total += parseFloat(value);
      });
    });

    setFoodTotalPrice(
      isMultipleItemArray ? total : updatedTotalPrice[menuItem?.id]
    );
  };

  const updatePrices = (menuItem, provider) => {
    const options = menuItem?.option_categories;
    getOptionTypeIDs(provider);

    const selectedOptionsValues = provider?.optionTypes
      ? Object.values(provider?.optionTypes).flat()
      : [];

    const updatedOptionTypesPrice = { ...optionTypesPrice };
    let price = 0;
    options?.forEach((e, index) => {
      e.attributes.options.forEach((option) => {
        if (selectedOptionsValues.includes(option.id)) {
          price += parseFloat(option.attributes.price);
        }
      });
    });

    if (edit) {
      if (updatedOptionTypesPrice?.hasOwnProperty(menuItem?.id)) {
        updatedOptionTypesPrice[menuItem?.id] = price;
      } else {
        updatedOptionTypesPrice[menuItem?.id] = price;
      }
      setOptionTypesPrice(updatedOptionTypesPrice);
    } else {
      setOptionTypesPrice(price);
    }

    updateTotalPrices(
      menuItem,
      provider,
      edit ? updatedOptionTypesPrice : price
    );
  };

  const radioCheck = (
    id,
    optionTypeId,
    type,
    required,
    provider,
    index,
    menuItem
  ) => {
    let existingOptions =
      provider?.optionTypes?.[optionTypeId]?.option_ids || [];

    if (
      existingOptions.some(
        (option) => option.option_id === id && !option._destroy
      )
    ) {
      console.log("Option already selected, doing nothing.");
      return;
    }

    const newOption = {
      option_id: id,
      option_group_id: optionTypeId,
      _destroy: false,
    };

    const updatedOptionIds = existingOptions.map((option) => {
      if (option.option_id === id) {
        return newOption;
      }
      return { ...option, _destroy: true };
    });

    if (!updatedOptionIds.some((option) => option.option_id === id)) {
      updatedOptionIds.push(newOption);
    }

    const orderItemOptionId =
      provider?.optionTypes?.[optionTypeId]?.orderItemOptionId;

    dispatch({
      field: "optionTypes",
      value: Object.assign({}, provider?.optionTypes, {
        [optionTypeId]: {
          option_ids: updatedOptionIds,
          orderItemOptionId: orderItemOptionId,
        },
      }),
      index: index,
    });
  };

  const onCheckboxChange = (
    id,
    optionType,
    optionTypesArr,
    event,
    provider,
    index,
    option
  ) => {
    if (
      optionType?.attributes?.required &&
      optionTypesArr?.option_ids?.length === 1 &&
      !event?.target?.checked
    ) {
      event.preventDefault();
      return;
    }

    let updatedOptionIds;

    if (event.target.checked) {
      updatedOptionIds =
        optionTypesArr?.option_ids?.length > 0
          ? optionTypesArr.option_ids.concat({
              option_id: option?.id,
              option_group_id: optionType?.id,
              _destroy: false,
            })
          : [
              {
                option_id: option?.id,
                option_group_id: optionType?.id,
                _destroy: false,
              },
            ];
    } else {
      updatedOptionIds = optionTypesArr?.option_ids?.map((option) => {
        if (Number(option.option_id) === Number(id)) {
          return { ...option, _destroy: true };
        }
        return option;
      });
    }

    let _optionType = {
      ...provider?.optionTypes,
      [optionType.id]: {
        ...provider?.optionTypes[optionType.id],
        option_ids: updatedOptionIds,
      },
    };

    dispatch({
      field: "optionTypes",
      value: _optionType,
      index: index,
    });
  };

  const addToCart = () => {
    if (showEditSection) {
    }

    if (edit && isMultipleItemArray) {
      let attendeeUncheckedOutOrder = {
        uncheckedout_order: {
          full_name: fullName,
          status: "placed",
          order_items_attributes: [],
        },
      };

      provider.forEach((providerItem, index) => {
        let selectedOptions = [];

        Object.keys(providerItem?.optionTypes).forEach(function (key) {
          let isArray = Array.isArray(providerItem?.optionTypes[key]);

          selectedOptions = [
            ...selectedOptions,
            isArray
              ? providerItem?.optionTypes[key]?.map((option_id) => {
                  return option_id;
                })
              : providerItem?.optionTypes[key]?.option_ids?.map((option_id) => {
                  return option_id;
                }),
          ];
        });

        attendeeUncheckedOutOrder.uncheckedout_order.order_items_attributes = [
          ...attendeeUncheckedOutOrder.uncheckedout_order
            .order_items_attributes,
          {
            menu_item_id: editItem[index].attributes?.menu_item?.id,
            quantity: providerItem?.quantity,
            menu_category_id: editItem[index].attributes?.category_id,
            special_instructions: providerItem?.instructions,
            order_item_options_attributes: selectedOptions.flat(),
            id: editItem[index]?.id,
          },
        ];
      });

      updateItem(attendeeToUpdate?.id, attendeeUncheckedOutOrder);
    } else {
      let selectedOptions = [];

      Object.keys(provider?.optionTypes).forEach(function (key) {
        let isArray = Array.isArray(provider?.optionTypes[key]);

        selectedOptions = [
          ...selectedOptions,
          isArray
            ? provider?.optionTypes[key]?.map((option_id) => {
                return option_id;
              })
            : provider?.optionTypes[key]?.option_ids?.map((option_id) => {
                return option_id;
              }),
        ];
      });

      let attendeeUncheckedOutOrder = {
        uncheckedout_order: {
          full_name: fullName,
          status: "placed",
          order_items_attributes: [
            {
              menu_item_id: menuItem?.id,
              quantity: provider?.quantity,
              special_instructions: provider?.instructions,
              menu_category_id: edit
                ? editItem?.attributes?.category_id
                : openedItems?.[selectedItem]?.menuCategoryId,
              order_item_options_attributes: selectedOptions.flat(),
              id: editItem?.id,
            },
          ],
        },
      };

      if (edit) updateItem(attendeeToUpdate?.id, attendeeUncheckedOutOrder);
    }

    setTimeout(() => {
      dispatch({ type: "reset" });
      initRequiredCheck(menuItem, dispatch);
    }, 1000);
  };

  const onReduceQuantity = () => {
    dispatch({
      field: "quantity",
      value: 1,
    });

    setAttachLabelAlert(null);
  };

  const submitActions = (provider, index, menuItem) => (
    <SubmitActions
      edit={edit}
      provider={provider}
      dispatch={dispatch}
      setModal={setModal}
      getItemTotalPrice={
        isMultipleItemArray
          ? getItemTotalPrice?.[index][menuItem?.id]
          : edit
          ? getItemTotalPrice[menuItem?.id]
          : getItemTotalPrice
      }
      updateQuantity={updateQuantity}
      setAttachLabelAlert={setAttachLabelAlert}
      addToCart={addToCart}
      isGroupOrder={true}
      isAdmin={isAdmin}
      menuItem={menuItem}
      providerIndex={index}
      updatePrices={updatePrices}
      showEditSection={showEditSection}
    />
  );

  const isDisabled = (option, provider, optionType, index) => {
    if (index) {
      return (
        option.attributes?.availability !== "active" ||
        (provider?.[index]?.optionTypes?.[optionType?.id]?.length >=
          optionType?.attributes?.choose_upto &&
          provider?.[index]?.optionTypes?.[optionType?.id]?.indexOf(
            option.id
          ) === -1)
      );
    } else {
      return (
        option.attributes?.availability !== "active" ||
        (provider?.optionTypes?.[optionType?.id]?.length >=
          optionType?.attributes?.choose_upto &&
          provider?.optionTypes?.[optionType?.id]?.indexOf(option.id) === -1)
      );
    }
  };

  function stripHtmlTags(html) {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  const children = (
    <>
      <Transition.Child
        // as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>
      <div className="fixed top-0 right-0 left-0 sm:inset-0">
        <div className="flex min-h-screen max-h-screen md:min-h-full md:items-center md:justify-center md:p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="flex flex-col max-h-screen overflow-auto flex-1 pb-44 md:pb-10 md:flex-initial w-full md:w-5/12 transform md:rounded-2xl bg-white py-10 px-8 text-left align-middle shadow-xl transition-all">
              <div className="hidden sm:flex sticky justify-between items-start mb-3">
                <div />
                <div className="flex justify-between flex-row items-center">
                  <div className="flex items-stretch items-center">
                    {menuItem?.image_url && !showEditSection && (
                      <div
                        className="flex rounded-xl overflow-hidden"
                        onClick={() => setImage(menuItem?.image_url)}
                      >
                        <img
                          src={menuItem?.image_url}
                          width={161}
                          height={188}
                          layout="fixed"
                          objectFit="cover"
                          alt="menu-item-image"
                        />
                      </div>
                    )}

                    <div
                      className={classNames({
                        "flex self-center": true,
                        "ml-6": menuItem?.image_url,
                      })}
                    >
                      <Dialog.Title
                        as="h3"
                        style={{
                          fontSize: screenType?.isTabletOrMobile ? 23 : 32,
                        }}
                        className="font-inter-semibold leading-loose text-black-light"
                      >
                        <EmbedHTML
                          text={
                            showEditSection
                              ? `${fullName} Meal`
                              : stripHtmlTags(menuItem?.display_name_with_html)
                          }
                          className={"text-black-light leading-tight"}
                        />
                      </Dialog.Title>
                      {!showEditSection && (
                        <span className="text-md mt-3">
                          <EmbedHTML
                            text={stripHtmlTags(
                              menuItem?.description_with_html
                            )}
                            className="text-black-light"
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <CloseButton setModal={setModal} />
              </div>

              <div className="flex sm:hidden sticky justify-between items-center mb-3">
                <Dialog.Title
                  as="h3"
                  style={{ fontSize: screenType?.isTabletOrMobile ? 23 : 32 }}
                  className="font-inter-semibold leading-6 text-black-light"
                >
                  <EmbedHTML
                    text={stripHtmlTags(menuItem?.display_name_with_html)}
                    className="text-black-light leading-9"
                  />
                </Dialog.Title>

                <CloseButton setModal={setModal} />
              </div>

              <span className="text-md sm:hidden">
                <EmbedHTML
                  text={
                    showEditSection
                      ? "Items"
                      : stripHtmlTags(menuItem?.description_with_html)
                  }
                  className="text-black-light"
                />
              </span>

              {edit && showEditSection && (
                <div className="flex flex-col">
                  <div className="flex justify-between items-center py-2">
                    <span className="text-slate-700 font-inter-semibold text-[20px] leading-tight">
                      Meal labelled as:
                    </span>
                  </div>

                  <div>
                    <input
                      className="input-light text-md focus:outline-none focus:ring-0 rounded-xl"
                      onChange={(e) => setFullName(e.target.value)}
                      value={fullName}
                    />
                  </div>

                  <div className="text-[20px] font-inter-semibold text-neutral-700 mb-3 mt-5">
                    Items in meal
                  </div>
                </div>
              )}

              <div className={`overflow-y-scroll flex flex-col gap-3`}>
                {edit && isMultipleItemArray ? (
                  <>
                    {editItem?.map((item, index) => {
                      const menuItem = item?.attributes?.menu_item;

                      return (
                        <React.Fragment>
                          <div
                            className={` ${
                              edit
                                ? "bg-white border border-slate-100 rounded-lg"
                                : "bg-white"
                            } p-3`}
                          >
                            <div className="text-neutral-700 text-lg font-inter-semibold leading-tight mb-3">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: menuItem.display_name_with_html,
                                }}
                              />
                            </div>

                            <div className="flex sm:hidden">
                              {menuItem?.image_url && (
                                <div
                                  className="flex rounded-xl overflow-hidden mt-5"
                                  style={{
                                    width: "100%",
                                    minHeight: 192,
                                    height: 192,
                                  }}
                                  onClick={() => setImage(menuItem?.image_url)}
                                >
                                  <img
                                    src={menuItem?.image_url}
                                    width={1500}
                                    height={192}
                                    objectFit="cover"
                                    alt="menu-item-image"
                                  />
                                </div>
                              )}
                            </div>

                            <div className="flex flex-col flex-wrap">
                              {menuItem?.option_categories?.map(
                                (optionType) => {
                                  return (
                                    <React.Fragment>
                                      {optionType?.attributes?.choose_upto ===
                                        0 && (
                                        <div
                                          key={optionType.id}
                                          className="flex flex-col mb-7"
                                        >
                                          <span className="uppercase text-md mb-1">
                                            <EmbedHTML
                                              isRow
                                              className="uppercase text-md mb-1"
                                              text={stripHtmlTags(
                                                optionType?.attributes
                                                  .display_name_with_html
                                              )}
                                              append={`(Unlimited) ${
                                                optionType.attributes.required
                                                  ? "*"
                                                  : ""
                                              }`}
                                              appendClass="ml-1"
                                            />
                                          </span>

                                          <div className="flex flex-col flex-wrap">
                                            {optionType?.attributes?.options?.map(
                                              (option) => (
                                                <label
                                                  key={option.id}
                                                  className="inline-flex items-center py-2 text-sm select-none mr-4"
                                                >
                                                  <input
                                                    autoComplete="off"
                                                    type="checkbox"
                                                    className="form-checkbox"
                                                    disabled={
                                                      option.attributes
                                                        ?.availability !==
                                                      "active"
                                                    }
                                                    value={option.id}
                                                    checked={provider?.[
                                                      index
                                                    ]?.optionTypes[
                                                      optionType.id
                                                    ]?.option_ids?.some(
                                                      (optionObj) =>
                                                        optionObj.option_id ===
                                                          option.id &&
                                                        !optionObj._destroy
                                                    )}
                                                    onChange={(e) => {
                                                      onCheckboxChange(
                                                        option.id,
                                                        optionType,
                                                        provider?.[index]
                                                          ?.optionTypes[
                                                          optionType.id
                                                        ],
                                                        e,
                                                        provider?.[index],
                                                        index,
                                                        option
                                                      );
                                                      updatePrices(
                                                        menuItem,
                                                        provider?.[index]
                                                      );
                                                    }}
                                                  />

                                                  <span className="ml-1 font-inter-regular text-md capitalize">
                                                    <EmbedHTML
                                                      isRow
                                                      className="text-primary-black"
                                                      text={stripHtmlTags(
                                                        option.attributes
                                                          .display_name_with_html
                                                      )}
                                                      append={
                                                        option?.attributes
                                                          ?.availability ===
                                                        "sold_out"
                                                          ? "Sold Out"
                                                          : `+${priceFormatter(
                                                              option.attributes
                                                                .price
                                                            )}`
                                                      }
                                                      appendClass={`${
                                                        edit
                                                          ? "text-zinc-200 font-inter-normal"
                                                          : "text-primary-black"
                                                      } text-md ml-1`}
                                                    />
                                                  </span>
                                                </label>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )}

                                      {optionType?.attributes?.choose_upto >=
                                        2 && (
                                        <div
                                          key={optionType.id}
                                          className="flex flex-col mb-7"
                                        >
                                          <EmbedHTML
                                            isRow
                                            className="uppercase text-md mb-1"
                                            text={stripHtmlTags(
                                              optionType?.attributes
                                                .display_name_with_html
                                            )}
                                            append={`(choose up to ${
                                              optionType.attributes.choose_upto
                                            }${
                                              optionType.attributes.required
                                                ? "*"
                                                : ""
                                            })`}
                                            appendClass="text-xs ml-1"
                                          />

                                          <div className="flex flex-col flex-wrap">
                                            {optionType?.attributes?.options?.map(
                                              (option) => (
                                                <label
                                                  key={option.id}
                                                  className="inline-flex items-center py-2 text-sm select-none mr-4"
                                                >
                                                  <input
                                                    autoComplete="off"
                                                    type="checkbox"
                                                    className="form-checkbox"
                                                    value={option.id}
                                                    checked={provider?.[
                                                      index
                                                    ]?.optionTypes[
                                                      optionType.id
                                                    ]?.option_ids?.some(
                                                      (optionObj) =>
                                                        optionObj.option_id ===
                                                          option.id &&
                                                        !optionObj._destroy
                                                    )}
                                                    disabled={isDisabled(
                                                      option,
                                                      provider,
                                                      optionType,
                                                      index
                                                    )}
                                                    onChange={(e) => {
                                                      onCheckboxChange(
                                                        option.id,
                                                        optionType,
                                                        provider?.[index]
                                                          ?.optionTypes[
                                                          optionType.id
                                                        ],
                                                        e,
                                                        provider?.[index],
                                                        index,
                                                        option
                                                      );
                                                      updatePrices(
                                                        menuItem,
                                                        provider?.[index]
                                                      );
                                                    }}
                                                  />

                                                  <div className="ml-1 font-inter-regular text-md capitalize">
                                                    <EmbedHTML
                                                      isRow
                                                      className="text-primary-black"
                                                      text={stripHtmlTags(
                                                        option.attributes
                                                          .display_name_with_html
                                                      )}
                                                      append={
                                                        option?.attributes
                                                          ?.availability ===
                                                        "sold_out"
                                                          ? "Sold Out"
                                                          : `+${priceFormatter(
                                                              option.attributes
                                                                .price
                                                            )}`
                                                      }
                                                      appendClass={`${
                                                        edit
                                                          ? "text-zinc-200 font-inter-normal"
                                                          : "text-primary-black"
                                                      } text-md ml-1`}
                                                    />
                                                  </div>
                                                </label>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )}

                                      {optionType?.attributes?.choose_upto ===
                                        1 && (
                                        <div
                                          key={optionType.id}
                                          className="flex flex-col mb-7"
                                        >
                                          <span className="uppercase text-md mb-1">
                                            <EmbedHTML
                                              isRow
                                              className="uppercase text-md mb-1"
                                              text={stripHtmlTags(
                                                optionType?.attributes
                                                  .display_name_with_html
                                              )}
                                              append={`${
                                                optionType.attributes.required
                                                  ? "*"
                                                  : ""
                                              }`}
                                              appendClass="ml-1"
                                            />
                                          </span>

                                          <div className="flex flex-col flex-wrap">
                                            {optionType?.attributes?.options?.map(
                                              (option) => (
                                                <label
                                                  key={option.id}
                                                  className="inline-flex items-center py-2 text-sm select-none mr-4"
                                                >
                                                  <input
                                                    autoComplete="off"
                                                    type="radio"
                                                    className="form-checkbox"
                                                    value={option.id}
                                                    name={
                                                      optionType.attributes.name
                                                    }
                                                    checked={provider?.[
                                                      index
                                                    ]?.optionTypes[
                                                      optionType.id
                                                    ]?.option_ids?.some(
                                                      (optionObj) =>
                                                        optionObj.option_id ===
                                                          option.id &&
                                                        !optionObj._destroy
                                                    )}
                                                    disabled={
                                                      option.attributes
                                                        ?.availability !==
                                                      "active"
                                                    }
                                                    onClick={(e) => {
                                                      radioCheck(
                                                        option.id,
                                                        optionType.id,
                                                        optionType.attributes
                                                          .name,
                                                        optionType.attributes
                                                          .required,
                                                        provider?.[index],
                                                        index
                                                      );
                                                      updatePrices(
                                                        menuItem,
                                                        provider?.[index]
                                                      );
                                                    }}
                                                  />
                                                  <span className="ml-1 font-inter-regular text-md capitalize">
                                                    <EmbedHTML
                                                      isRow
                                                      className="text-primary-black"
                                                      text={stripHtmlTags(
                                                        option.attributes
                                                          .display_name_with_html
                                                      )}
                                                      append={
                                                        option?.attributes
                                                          ?.availability ===
                                                        "sold_out"
                                                          ? "Sold Out"
                                                          : `+${priceFormatter(
                                                              option.attributes
                                                                .price
                                                            )}`
                                                      }
                                                      appendClass={`${
                                                        edit
                                                          ? "text-zinc-200 font-inter-normal"
                                                          : "text-primary-black"
                                                      } text-md ml-1`}
                                                    />
                                                  </span>
                                                </label>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </div>

                            <div className="flex flex-col">
                              <div className="flex justify-between items-center uppercase py-[11px]">
                                <span className="text-md font-inter-light">
                                  Special Instructions
                                </span>
                              </div>

                              <div>
                                <input
                                  className="input-light text-md focus:outline-none focus:ring-0"
                                  placeholder="Ex. do not add tomatoes"
                                  value={provider?.[index].instructions}
                                  onChange={(e) =>
                                    dispatch({
                                      field: "instructions",
                                      value: e.target.value,
                                      index: index,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            <div className="w-full">
                              {submitActions(
                                provider?.[index],
                                index,
                                menuItem
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <React.Fragment>
                    <div
                      className={` ${
                        showEditSection ? "bg-slate-50" : "bg-white"
                      } p-3`}
                    >
                      <div className="text-neutral-700 text-lg font-inter-semibold leading-tight mb-3">
                        {stripHtmlTags(menuItem?.display_name_with_html)}
                      </div>

                      <div className="flex sm:hidden">
                        {menuItem?.image_url && (
                          <div
                            className="flex rounded-xl overflow-hidden mt-5"
                            style={{
                              width: "100%",
                              minHeight: 192,
                              height: 192,
                            }}
                            onClick={() => setImage(menuItem?.image_url)}
                          >
                            <img
                              src={menuItem?.image_url}
                              width={1500}
                              height={192}
                              objectFit="cover"
                              alt="menu-item-image"
                            />
                          </div>
                        )}
                      </div>

                      <div className="flex flex-col flex-wrap">
                        {menuItem?.option_categories?.map((optionType) => (
                          <>
                            {optionType?.attributes?.choose_upto === 0 && (
                              <div
                                key={optionType.id}
                                className="flex flex-col mb-7"
                              >
                                <span className="uppercase text-md mb-1">
                                  <EmbedHTML
                                    isRow
                                    className="uppercase text-md mb-1"
                                    text={stripHtmlTags(
                                      optionType?.attributes
                                        .display_name_with_html
                                    )}
                                    append={`(Unlimited) ${
                                      optionType.attributes.required ? "*" : ""
                                    }`}
                                    appendClass="ml-1"
                                  />
                                </span>

                                <div className="flex flex-col flex-wrap">
                                  {optionType?.attributes?.options?.map(
                                    (option) => (
                                      <label
                                        key={option.id}
                                        className="inline-flex items-center py-2 text-sm select-none mr-4"
                                      >
                                        <input
                                          autoComplete="off"
                                          type="checkbox"
                                          className="form-checkbox"
                                          disabled={
                                            option.attributes?.availability !==
                                            "active"
                                          }
                                          value={option.id}
                                          checked={provider?.optionTypes[
                                            optionType.id
                                          ]?.option_ids?.some(
                                            (optionObj) =>
                                              optionObj.option_id ===
                                                option.id && !optionObj._destroy
                                          )}
                                          onChange={(e) => {
                                            onCheckboxChange(
                                              option.id,
                                              optionType,
                                              provider?.optionTypes[
                                                optionType.id
                                              ],
                                              e,
                                              provider,
                                              0,
                                              option
                                            );
                                            updatePrices(menuItem, provider);
                                          }}
                                        />

                                        <span className="ml-1 font-inter-regular text-md capitalize">
                                          <EmbedHTML
                                            isRow
                                            className="text-primary-black"
                                            text={stripHtmlTags(
                                              option.attributes
                                                .display_name_with_html
                                            )}
                                            append={
                                              option?.attributes
                                                ?.availability === "sold_out"
                                                ? "Sold Out"
                                                : `+${priceFormatter(
                                                    option.attributes.price
                                                  )}`
                                            }
                                            appendClass={`${
                                              edit
                                                ? "text-zinc-200 font-inter-normal"
                                                : "text-primary-black"
                                            } text-md ml-1`}
                                          />
                                        </span>
                                      </label>
                                    )
                                  )}
                                </div>
                              </div>
                            )}

                            {optionType?.attributes?.choose_upto >= 2 && (
                              <div
                                key={optionType.id}
                                className="flex flex-col mb-7"
                              >
                                <EmbedHTML
                                  isRow
                                  className="uppercase text-md mb-1"
                                  text={stripHtmlTags(
                                    optionType?.attributes
                                      .display_name_with_html
                                  )}
                                  append={`(choose up to ${
                                    optionType.attributes.choose_upto
                                  }${
                                    optionType.attributes.required ? "*" : ""
                                  })`}
                                  appendClass="text-xs ml-1"
                                />

                                <div className="flex flex-col flex-wrap">
                                  {optionType?.attributes?.options?.map(
                                    (option) => (
                                      <label
                                        key={option.id}
                                        className="inline-flex items-center py-2 text-sm select-none mr-4"
                                      >
                                        <input
                                          autoComplete="off"
                                          type="checkbox"
                                          className="form-checkbox"
                                          value={option.id}
                                          checked={provider?.optionTypes[
                                            optionType.id
                                          ]?.option_ids?.some(
                                            (optionObj) =>
                                              optionObj.option_id ===
                                                option.id && !optionObj._destroy
                                          )}
                                          disabled={isDisabled(
                                            option,
                                            provider,
                                            optionType
                                          )}
                                          onChange={(e) => {
                                            onCheckboxChange(
                                              option.id,
                                              optionType,
                                              provider?.optionTypes[
                                                optionType.id
                                              ],
                                              e,
                                              provider,
                                              0,
                                              option
                                            );
                                            updatePrices(menuItem, provider);
                                          }}
                                        />

                                        <div className="ml-1 font-inter-regular text-md capitalize">
                                          <EmbedHTML
                                            isRow
                                            className="text-primary-black"
                                            text={stripHtmlTags(
                                              option.attributes
                                                .display_name_with_html
                                            )}
                                            append={
                                              option?.attributes
                                                ?.availability === "sold_out"
                                                ? "Sold Out"
                                                : `+${priceFormatter(
                                                    option.attributes.price
                                                  )}`
                                            }
                                            appendClass={`${
                                              edit
                                                ? "text-zinc-200 font-inter-normal"
                                                : "text-primary-black"
                                            } text-md ml-1`}
                                          />
                                        </div>
                                      </label>
                                    )
                                  )}
                                </div>
                              </div>
                            )}

                            {optionType?.attributes?.choose_upto === 1 && (
                              <div
                                key={optionType.id}
                                className="flex flex-col mb-7"
                              >
                                <span className="uppercase text-md mb-1">
                                  <EmbedHTML
                                    isRow
                                    className="uppercase text-md mb-1"
                                    text={stripHtmlTags(
                                      optionType?.attributes
                                        .display_name_with_html
                                    )}
                                    append={`${
                                      optionType.attributes.required ? "*" : ""
                                    }`}
                                    appendClass="ml-1"
                                  />
                                </span>

                                <div className="flex flex-col flex-wrap">
                                  {optionType?.attributes?.options?.map(
                                    (option) => (
                                      <label
                                        key={option.id}
                                        className="inline-flex items-center py-2 text-sm select-none mr-4"
                                      >
                                        <input
                                          autoComplete="off"
                                          type="radio"
                                          className="form-checkbox"
                                          value={option.id}
                                          name={optionType.attributes.name}
                                          checked={provider?.optionTypes[
                                            optionType.id
                                          ]?.option_ids?.some(
                                            (optionObj) =>
                                              optionObj.option_id ===
                                                option.id && !optionObj._destroy
                                          )}
                                          disabled={
                                            option.attributes?.availability !==
                                            "active"
                                          }
                                          onClick={(e) => {
                                            radioCheck(
                                              option.id,
                                              optionType.id,
                                              optionType.attributes.name,
                                              optionType.attributes.required,
                                              provider
                                            );
                                            updatePrices(menuItem, provider);
                                          }}
                                        />
                                        <span className="ml-1 font-inter-regular text-md capitalize">
                                          <EmbedHTML
                                            isRow
                                            className="text-primary-black"
                                            text={stripHtmlTags(
                                              option.attributes
                                                .display_name_with_html
                                            )}
                                            append={
                                              option?.attributes
                                                ?.availability === "sold_out"
                                                ? "Sold Out"
                                                : `+${priceFormatter(
                                                    option.attributes.price
                                                  )}`
                                            }
                                            appendClass={`${
                                              edit
                                                ? "text-zinc-200 font-inter-normal"
                                                : "text-primary-black"
                                            } text-md ml-1`}
                                          />
                                        </span>
                                      </label>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        ))}

                        <div className="flex flex-col">
                          <div className="flex justify-between items-center uppercase py-[11px]">
                            <span className="text-md font-inter-light">
                              Special Instructions
                            </span>
                          </div>

                          <div>
                            <input
                              className="input-light text-md focus:outline-none focus:ring-0"
                              placeholder="Ex. do not add tomatoes"
                              onChange={(e) =>
                                dispatch({
                                  field: "instructions",
                                  value: e.target.value,
                                })
                              }
                              value={provider?.instructions}
                            />
                          </div>
                        </div>

                        {isAdmin && !edit && (
                          <div className="flex flex-col mt-2">
                            <div className="flex justify-between items-center uppercase py-[11px]">
                              <span className="text-md font-inter-light">
                                Attach a name to this item
                              </span>
                            </div>

                            <div>
                              <input
                                className="input-light text-md focus:outline-none focus:ring-0"
                                placeholder="Ex. Jane Doe"
                                onChange={(e) => {
                                  if (provider.quantity > 1)
                                    setAttachLabelAlert(true);
                                  else
                                    dispatch({
                                      field: "nameLabel",
                                      value: e.target.value,
                                    });
                                }}
                                value={provider?.nameLabel}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="w-full">
                        {submitActions(provider, "", menuItem)}
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>

              {showEditSection && (
                <React.Fragment>
                  <div className="flex flex-col gap-3 mt-4">
                    {edit && (
                      <div className="flex-col bg-white">
                        <div className="w-full px-3 py-6 bg-slate-50 rounded-xl flex justify-between">
                          <div className="text-slate-700 text-sm font-inter-normal">
                            Food Total
                          </div>

                          <div className="text-gray-800 text-base font-inter-semibold leading-tight">
                            {priceFormatter(foodTotalPrice)}
                          </div>
                        </div>

                        <div className="mt-[32px] flex justify-between items-center gap-3">
                          <button
                            className="p-2.5 rounded-xl text-red-400 text-sm font-inter-medium capitalize flex justify-center items-center"
                            onClick={() =>
                              onClickRemoveAttendee(attendeeToUpdate)
                            }
                          >
                            Delete this Meal
                          </button>

                          <div className="">
                            <button
                              className="w-[98px] h-12 mr-2 p-2.5 rounded-md border border-zinc-200 text-slate-700 font-inter-medium capitalize"
                              onClick={() => setModal(false)}
                            >
                              Cancel
                            </button>

                            <button
                              className="w-[164px] h-12 p-2.5 bg-indigo-900 rounded-md border border-indigo-900 text-white fornt-inter-medium capitalize"
                              onClick={() => addToCart()}
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>

      <Transition.Child
        as={"div"}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="md:hidden">
          <div className="fixed bg-white w-full p-5 bottom-0 z-[90] justify-center items-center border-t-2 border-gray-200">
            {submitActions}
          </div>
        </div>
      </Transition.Child>

      {attachLabelAlert && (
        <ConfirmationModal
          visible={attachLabelAlert}
          setModal={setAttachLabelAlert}
          title={"Assign a name to item"}
          body={`To individualize this item and attach a name to it - the total number of ${menuItem?.title} items in your cart will be reduced to 1. Add as many items with names individually, or unnamed items in bulk to your cart.`}
          rightButtonText={"Reduce To 1"}
          onClickRightButton={onReduceQuantity}
        />
      )}
    </>
  );

  return (
    <Transition appear show={visible} as={Fragment}>
      {screenType?.isTabletOrMobile ? (
        <Dialog
          as="div"
          open={visible}
          className="relative z-[90]"
          onClose={() => {}}
        >
          {children}
        </Dialog>
      ) : (
        <Dialog
          as="div"
          className="relative z-[90]"
          onClose={() => {
            screenType?.isTabletOrDesktop && setModal(false);
          }}
        >
          {children}
        </Dialog>
      )}
    </Transition>
  );
}

export default observer(AddGroupItemModal);
