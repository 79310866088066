import React, { useState, useCallback, useContext, useEffect } from "react";
import { SetSubsidy, GenerateLink } from "alerts";
import { ChevronLeftIcon } from "assets/img";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { isPresent } from "utils/helpers/array";
import { priceFormatter } from "utils/helpers/validation";
import { MobXProviderContext, observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import dateTime from "utils/helpers/dateTime";
import dayjs from "dayjs";
import classNames from "classnames";

function InvitationLinks() {
  const store = useContext(MobXProviderContext);
  const userStore = store?.userStore;

  const navigate = useNavigate();
  const [newInvitation, createNewInvitation] = useState(false);
  const [generateLink, setGenerateLink] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [mealDetails, setMealDetails] = useState(null);
  const { mealId } = useParams();
  const { locationID } = userStore;

  const { invitationLinkStore } = useContext(MobXProviderContext);

  const invitationLinks = invitationLinkStore.invitationLinks;

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["groupOrder", locationID, mealId],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return store.homeStore.fetchMealDetail(mealId, locationID);
    },
  });

  useEffect(() => {
    setMealDetails(data);
  }, [data]);

  const onGenerateLinkPressed = useCallback((subsidy) => {
    createNewInvitation(false);

    const payload = {
      invitation_link: {
        subsidy,
      },
    };
    invitationLinkStore
      .createInvitationLink(
        {
          location_id: locationID,
          meal_id: mealId,
        },
        payload
      )
      .then((response) => {
        if (response?.id) {
          invitationLinkStore.getInvitationLinks(mealId, locationID);
          setGenerateLink(response?.attributes?.slug);
        }
      });
  }, []);

  const CopyLinkUrlToDashboard = useCallback((link) => {
    const linkToCopy = `${process.env.REACT_APP_UNCATERING_APP_LINK}/guest/restaurants/${link?.attributes?.slug}`;
    navigator.clipboard.writeText(linkToCopy);
    setLinkCopied(true);

    setTimeout(() => {
      setLinkCopied(false);
    }, 1500);
  }, []);

  useEffect(() => {
    invitationLinkStore.getInvitationLinks(mealId, locationID);
  }, []);

  const cutoffTime = dayjs
    .utc(mealDetails?.attributes?.cutoff)
    .tz(mealDetails?.attributes?.tzinfo);

  const currentTime = dayjs().tz(mealDetails?.attributes?.tzinfo);

  const isExpired = currentTime.isAfter(cutoffTime);

  return (
    <div className="rounded-lg pl-[28px] pr-[85px] flex flex-col min-w-full relative">
      {linkCopied && (
        <div className="btn-blue-accent items-center d-row rounded-xl w-[230px] left-0 right-0 h-[40px] absolute top-[24px] mx-auto font-inter-semibold">
          <FontAwesomeIcon
            style={{ width: 16, height: 26 }}
            icon={faCircleCheck}
            size="2x"
            color="#fff"
            className="mr-[8px] animate-pulse"
          />
          Link copied succesfully
        </div>
      )}

      <button
        className="font-inter-medium d-row items-center mb-[22px] mt-[24px] text-sm cursor-pointer hover:text-gray-500"
        onClick={() => {
          navigate(-1);
        }}
      >
        <img src={ChevronLeftIcon} className="h-[20px] w-[20px]" />
        Back
      </button>

      <div className="d-col">
        <strong className="text-dark-gray text-[20px] font-inter-medium">
          {dateTime.formatDate(
            mealDetails?.attributes?.meal_date,
            "ddd, MMMM D, yyyy"
          )}{" "}
          {dateTime.checkAndGetTimezone(mealDetails?.attributes?.tzinfo)}
        </strong>

        <strong className="text-heading text-[32px] font-inter-semibold">
          {mealDetails?.attributes?.name}
        </strong>
      </div>

      <div className="overflow-scroll max-h-[calc(100vh/1.5)] ">
        {isPresent(invitationLinks) ? (
          <div className="d-col">
            <div className="d-row justify-between items-center my-[32px]">
              <strong className="text-heading text-[20px] font-inter-semibold">
                Generated links
              </strong>

              <button
                className={classNames({
                  "btn-blue-accent items-center d-row rounded-xl font-inter-semibold": true,
                  "opacity-30": isExpired,
                })}
                onClick={() => createNewInvitation(true)}
                disabled={isExpired}
              >
                <FontAwesomeIcon
                  style={{ width: 16, height: 26 }}
                  icon={faCopy}
                  size="2x"
                  color="#fff"
                  className="mr-[8px]"
                />
                Create new invitation link
              </button>
            </div>

            {invitationLinks?.map((link) => {
              return (
                <div className="d-row justify-between bg-light-gray h-[96px] mb-[24px] rounded-lg items-center p-[24px]">
                  <span className="font-inter-medium text-[16px] text-dark-gray">
                    {priceFormatter(link?.attributes?.subsidy)}{" "}
                    <small className="font-inter-regular text-[14px]">
                      Subsidy
                    </small>
                  </span>

                  <div className="flex justify-between text-md font-inter-regular rounded-xl bg-background max-w-[435px] overflow-hidden focus:outline-none h-12 mt-3">
                    <input
                      className="flex w-full bg-white text-secondary focus:outline-none rounded-l-md text-md px-3 truncate"
                      value={`${process.env.REACT_APP_UNCATERING_APP_LINK}/guest/restaurants/${link?.attributes?.slug}`}
                      disabled={true}
                    />

                    <button
                      type="button"
                      onClick={() => CopyLinkUrlToDashboard(link)}
                      className={classNames({
                        "btn-style w-[226px] bg-primary-dark hover:bg-hh-accent-light": true,
                        "opacity-30": isExpired,
                      })}
                      disabled={isExpired}
                    >
                      <div className="flex items-center justify-center">
                        <FontAwesomeIcon
                          style={{ width: 16, height: 26 }}
                          icon={faCopy}
                          size="2x"
                          color="#fff"
                          className="mr-[8px] w-[18px] h-[20px]"
                        />
                        <span className="text-white font-inter-medium text-[16px]">
                          Copy Link
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="d-col self-center items-center">
            <small className="font-inter-semibold text-dark-gray mb-[21px]">
              No invitation links created
            </small>

            <button
              className="btn-blue-accent items-center d-row rounded-xl font-inter-semibold"
              onClick={() => createNewInvitation(true)}
            >
              <FontAwesomeIcon
                style={{ width: 16, height: 26 }}
                icon={faCopy}
                size="2x"
                color="#fff"
                className="mr-[8px]"
              />
              Create new invitation link
            </button>
          </div>
        )}
      </div>

      <strong className="text-dark-gray text-[32px] mt-[47px] font-inter-semibold">
        What are guest links?
      </strong>
      <span className="font-inter-regular text-[16px] text-dark-gray">
        Guest links allow non-permanent users to order a specific meal without
        the need to create an account.
      </span>

      <div className="grid grid-cols-2 gap-x-6 gap-y-[57px] mt-[57px]">
        <div className="h-[218px] bg-[#F4F4F4] w-full rounded-2xl p-[24px] d-col">
          <span className="text-dark-gray font-inter-semibold text-[20px]">
            Do invitation links expire?
          </span>

          <div className="h-[1px] w-full bg-divider my-[24px]" />

          <p className="font-inter-regular text-dark-gray text-[16px] max-w-[490px]">
            Yes, each invitation link is tied to a specific meal. If you invite
            someone to place an order on Monday, they will not be able to use
            the same link to order on Tuesday.
          </p>
        </div>

        <div className="h-[218px] bg-[#F4F4F4] w-full rounded-2xl p-[24px] d-col">
          <span className="text-dark-gray font-inter-semibold text-[20px]">
            Can I attach a subsidy to guest links?
          </span>

          <div className="h-[1px] w-full bg-divider my-[24px]" />

          <p className="font-inter-regular text-dark-gray text-[16px] max-w-[360px]">
            Yes, you can create multiple invitation links, each with a
            designated subsidy.
          </p>
        </div>

        <div className="h-[228px] bg-[#F4F4F4] w-full rounded-2xl p-[24px] d-col">
          <span className="text-dark-gray font-inter-semibold text-[20px]">
            Can a link be used by multiple people?
          </span>

          <div className="h-[1px] w-full bg-divider mt-[40px] mb-[24px]" />

          <p className="font-inter-regular text-dark-gray text-[16px] max-w-[360px]">
            Yes, a link with an attached subsidy can be shared and used multiple
            times.
          </p>
        </div>

        <div className="h-[228px] bg-[#F4F4F4] w-full rounded-2xl p-[24px] d-col">
          <span className="text-dark-gray font-inter-semibold text-[20px]">
            How can I identify who ordered their meal using a guest link?
          </span>

          <div className="h-[1px] w-full bg-divider my-[24px]" />

          <p className="font-inter-regular text-dark-gray text-[16px] max-w-[490px]">
            At checkout, guests will be asked to provide their name. You will be
            able to view their order from the home page.
          </p>
        </div>

        <div className="h-[218px] bg-[#F4F4F4] w-full rounded-2xl p-[24px] d-col">
          <span className="text-dark-gray font-inter-semibold text-[20px]">
            Will the meals be labeled with my guests' names?
          </span>

          <div className="h-[1px] w-full bg-divider my-[24px]" />

          <p className="font-inter-regular text-dark-gray text-[16px] max-w-[360px]">
            Yes, meal labels will display the name the guest provided when
            placing their meal order.
          </p>
        </div>

        <div className="h-[218px] bg-[#F4F4F4] w-full rounded-2xl p-[24px] d-col">
          <span className="text-dark-gray font-inter-semibold text-[20px]">
            How do I share a guest link?
          </span>

          <div className="h-[1px] w-full bg-divider my-[24px]" />

          <p className="font-inter-regular text-dark-gray text-[16px] max-w-[360px]">
            Share the link with guests via email, Slack, Teams, or your
            preferred messaging app.
          </p>
        </div>
      </div>

      {newInvitation && (
        <SetSubsidy
          visible={newInvitation}
          setModal={createNewInvitation}
          onClickRightButton={onGenerateLinkPressed}
        />
      )}

      {generateLink && (
        <GenerateLink visible={generateLink} setModal={setGenerateLink} />
      )}
    </div>
  );
}

export default observer(InvitationLinks);
