import React, { useState, useContext } from "react";
import Screen from "utils/helpers/ScreenSize";
import { MobXProviderContext, observer } from "mobx-react";
import { priceFormatter } from "utils/helpers/validation";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import Icon from "utils/helpers/icon";
import { toJS } from "mobx";
import EmbedHTML from "components/shared/embedHTML";
import MenuElementStatus from "components/shared/MenuElementStatus";
import MenuOptions from "components/shared/MenuOptions";
import ProgressiveImage from "react-progressive-image";
import { Placeholder } from "assets/img";
import classNames from "classnames";

function PlaceOrderMenu({ restaurantDetail = {}, addItemToCart, employeeId }) {
  const store = useContext(MobXProviderContext);
  const [selected, setSelected] = useState(null);
  const userStore = toJS(store.userStore);
  const placeOrderStore = toJS(store.placeOrderStore);
  let { optionCategories } = placeOrderStore;
  let { defaultLocation } = userStore;

  const getOptionCategories = (id) => {
    setSelected(id[0]);
    if (id[0] && id[0] !== selected) {
      store.userStore.setLoader(true);

      store.placeOrderStore.clearOptionCategories();
      store.placeOrderStore
        .getOptionCategories(id[0], {
          employee_id: employeeId,
          location_id: defaultLocation.organizationId,
        })
        .then((response) => {
          store.userStore.setLoader(false);
        });
    }
  };

  const getIcon = (attributes, expanded) => {
    if (
      attributes?.availability !== "sold_out" &&
      attributes?.availability !== "in_active"
    ) {
      return expanded ? faMinus : faPlus;
    }
    return faPlus;
  };

  return (
    <div className="w-full mt-4">
      <Screen.Desktop>
        <strong className="font-inter-semibold text-lg">MENU</strong>
      </Screen.Desktop>

      {restaurantDetail?.menu_categories?.length > 0 ? (
        <Accordion
          onChange={(id) => {
            getOptionCategories(id);
          }}
          allowZeroExpanded
        >
          {restaurantDetail?.menu_categories.map((category, index) => (
            <div className={`overflow-hidden mt-2 rounded-t-xl`}>
              <div className="bg-[#363a7d] py-4 d-row justify-between px-2">
                <div className="d-col w-full">
                  <EmbedHTML
                    text={category?.attributes?.display_name_with_html}
                    className="text-white text-sm"
                  />

                  <EmbedHTML
                    text={category?.attributes?.description_with_html}
                    className="text-white mt-2 opacity-90 text-xs"
                  />
                </div>

                <MenuElementStatus
                  status={category?.attributes?.availability}
                  light
                  mediumSized
                />
              </div>

              {category?.attributes?.menu_items?.map(
                ({ attributes }, index) => (
                  <AccordionItem key={attributes.id} uuid={attributes.id}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <AccordionItemState>
                          {({ expanded }) => (
                            <div
                              className={classNames(
                                "p-5 mb-1 border rounded-lg shadow-md hover:shadow-lg",
                                {
                                  "rounded-tl-none rounded-tr-none":
                                    index === 0,
                                  "mt-2": index !== 0,
                                  "bg-gray-100":
                                    attributes?.availability === "sold_out" ||
                                    attributes?.availability === "in_active",
                                }
                              )}
                            >
                              <div className="d-row justify-between">
                                <EmbedHTML
                                  text={attributes.display_name_with_html}
                                  className="text-sm mr-2 text-black"
                                />
                                <MenuElementStatus
                                  status={attributes?.availability}
                                />
                              </div>

                              <div className="d-row items-start justify-between">
                                <EmbedHTML
                                  text={attributes.description_with_html}
                                  className="text-dark-gray text-xs pr-12"
                                />

                                <div className="d-row items-end w-1/4 justify-end">
                                  {attributes.image_url && (
                                    <ProgressiveImage
                                      src={attributes.image_url}
                                      placeholder={Placeholder}
                                    >
                                      {(src) => (
                                        <img
                                          src={src}
                                          className="h-24 w-24 bg-white object-contain shadow-sm rounded-md border"
                                          alt="an image"
                                        />
                                      )}
                                    </ProgressiveImage>
                                  )}
                                </div>
                              </div>

                              <div className="d-col justify-start mt-3">
                                <div className="grid grid-cols-12 gap-2 w-3/4">
                                  {attributes?.badges
                                    ?.filter((badge) => !badge?.disabled)
                                    ?.map((badge) => (
                                      <img
                                        title={badge}
                                        src={Icon.menuItemBadge(badge)}
                                        alt="an image"
                                        className="object-contain h-6 w-6"
                                      />
                                    ))}
                                </div>

                                <div className="d-row justify-between">
                                  <small className="font-inter-medium text-sm mt-4">
                                    {priceFormatter(attributes.price)}
                                  </small>

                                  <button className="btn-purple bg-dark-purple h-10 flex justify-center items-center w-10">
                                    <FontAwesomeIcon
                                      icon={getIcon(attributes, expanded)}
                                      color={"#fff"}
                                      size="md"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </AccordionItemState>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {attributes?.availability !== "sold_out" &&
                      attributes?.availability !== "in_active" && (
                        <AccordionItemPanel>
                          <div className="border bg-white py-3">
                            <MenuOptions
                              currentOptions={optionCategories}
                              item={attributes}
                              categoryId={category?.attributes?.id}
                              addItemToCart={addItemToCart}
                            />
                          </div>
                        </AccordionItemPanel>
                      )}
                  </AccordionItem>
                )
              )}
            </div>
          ))}
        </Accordion>
      ) : (
        <div className="py-4 mt-5">
          <span className="font-inter-medium text-sm">
            No menu items available for the selected restaurant.
          </span>
        </div>
      )}
    </div>
  );
}

export default observer(PlaceOrderMenu);
