import { observable, action, makeObservable, toJS } from "mobx";
import axios from "axios";
import { API_URL } from "../utils/constants/api";
import {
  getLocationEmployeesWithAdmin,
  getLocationEmployees,
  getLocationID,
  isPresent,
} from "../utils/helpers/array";
import LocalStorage from "../utils/helpers/localStorage";
import { makePersistable, clearPersistedStore } from "mobx-persist-store";
import Cookies from "js-cookie";
import { client } from "../store";
import api from "store/api";
import qs from "qs";
import { errorHandler } from "utils/middlewares/errorHandler";

class UserStore {
  isLoading = false;
  currentUser = {};
  defaultLocation = {};
  locationID = "";
  invoices = [];
  invoicesPagy = null;
  error = false;
  errorMessage = "";

  currentEmployee = {};
  availableDates = [];
  allTimes = [];
  addressTimezone = null;
  timeOptionsLoading = false;
  selectedAddress = "";
  selectedDate = "";
  selectedTime = "";
  allMenus = [];
  allMenusPagy = null;
  sortBy = { label: "Sort by Distance", value: "" };
  allFilters = null;
  isFreshChatOpen = false;

  constructor() {
    makePersistable(this, {
      name: "UserStore",
      properties: [
        "currentUser",
        "defaultLocation",
        "locationID",
        "currentEmployee",
        "availableDates",
        "allTimes",
        "addressTimezone",
        "selectedAddress",
        "selectedDate",
        "selectedTime",
        "allFilters",
      ],
      storage: window.localStorage,
    });
    makeObservable(this, {
      isLoading: observable,
      currentUser: observable,
      defaultLocation: observable,
      invoices: observable,
      invoicesPagy: observable,
      errorMessage: observable,
      error: observable,
      selectedAddress: observable,
      selectedTime: observable,
      selectedDate: observable,
      allFilters: observable,
      locationID: observable,
      allMenus: observable,
      isFreshChatOpen: observable,

      getLocationMenus: action,
      getProfile: action,
      setdefaultLocation: action,
      getAvailableDates: action,
      getAvailableTimes: action,
      setNewAddress: action,
      setDate: action,
      setTime: action,
      setSort: action,
      createUserOrder: action,
      setFreshChatOpen: action,
    });
  }

  setLoader(value) {
    this.isLoading = value;
  }

  setdefaultLocation(region) {
    const cookieOptions = {
      secure: false,
      domain:
        process.env.NODE_ENV === "development" ? "localhost" : ".hungerhub.com",
      path: "/",
    };

    Cookies.set("employee-id", region?.value, cookieOptions);

    this.defaultLocation = region;
    this.locationID = region?.organizationId;
  }

  async clearStoredData() {
    await clearPersistedStore(this);
  }

  setErrorAlert(visible, errorMessage) {
    this.error = visible;
    this.errorMessage = errorMessage;
  }

  async getProfile(employeeId, token, email) {
    let url = `${API_URL}/v1/profiles/${email}?employee_id=${employeeId}`;

    try {
      const response = await axios({
        url,
        method: "GET",
        headers: {
          "ACCESS-TOKEN": token,
          "Content-Type": "application/json",
        },
      });

      let selectedLocationEmployeeId = employeeId;

      const locationEmployees = getLocationEmployeesWithAdmin(
        response.data?.data
      );

      const { locationId, employee } = getLocationID(
        response.data?.data,
        selectedLocationEmployeeId
      );

      this.locationID = locationId;
      this.currentEmployee = employee;

      if (isPresent(locationEmployees)) {
        this.currentUser = response.data?.data;

        const preSelectedLocation = locationEmployees?.find(
          (employee) => employee.value == selectedLocationEmployeeId
        );

        const location = preSelectedLocation;

        this.setdefaultLocation(location);

        LocalStorage.setAccessToken(
          response.data?.data?.attributes?.access_token
        );

        return response.data?.data;
      } else {
        this.clearStoredData();
        const source = Cookies.get("source");
        window.location.href =
          source === "uncatering"
            ? process.env.REACT_APP_UNCATERING_APP_LINK
            : process.env.REACT_APP_CATERING_APP_LINK;
      }
    } catch (error) {
      // Handle error or alert appropriately
      console.error("Fetch error:", error);
      return error.response;
    }
  }

  setLocationData(employeeId) {
    const { locationId, employee } = getLocationID(
      this.currentUser,
      employeeId
    );

    this.locationID = locationId;
    this.currentEmployee = employee;
  }

  async getUncateringInvoices(params) {
    let _params = qs.stringify(params, { arrayFormat: "brackets" });

    try {
      this.setLoader(true);
      const response = await client().get(
        `${api.uncateringInvoices()}?${_params}`
      );
      this.setLoader(false);
      this.invoices = response?.data?.data;
      this.invoicesPagy = response?.data?.pagy;
    } catch (error) {
      errorHandler(error?.response);
      return error;
    }
  }

  async exportInvoicePDF(params) {
    let access_token = LocalStorage.getAccessToken();
    const API_URL = "https://nbedev1.hungerhub.com/api";
    let url = `${API_URL}/v2/uncatering_admin/invoices/${params.invoiceId}.pdf?location_id=${params.locationId}`;

    this.setLoader(true);
    axios({
      url,
      method: "GET",
      responseType: "blob",
      headers: {
        "ACCESS-TOKEN": access_token,
      },
    }).then(
      action("fetchSuccess", (response) => {
        let newBlob = new Blob([response.data], { type: "application/pdf" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement("a");
        link.href = url;
        link.download = `${params.invoiceNumber}_invoice.pdf`;
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 100);

        this.setLoader(false);
      }),

      action("fetchError", (error) => {
        errorHandler(error?.response);
        this.setLoader(false);
        return error;
      })
    );
  }

  async exportInvoiceXLS(params) {
    let access_token = LocalStorage.getAccessToken();
    const API_URL = "https://nbedev1.hungerhub.com/api";
    let url = `${API_URL}/v2/uncatering_admin/invoices/${params.invoiceId}.xlsx?location_id=${params.locationId}`;
    this.setLoader(true);
    axios({
      url,
      method: "GET",
      responseType: "blob",
      headers: {
        "ACCESS-TOKEN": access_token,
      },
    }).then(
      action("fetchSuccess", (response) => {
        let newBlob = new Blob([response.data], { type: "application/xlsx" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement("a");
        link.href = url;
        link.download = `${params.invoiceNumber}_sales_report.xlsx`;
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 100);

        this.setLoader(false);
      }),
      action("fetchError", (error) => {
        errorHandler(error?.response);
        this.setLoader(false);
        return error;
      })
    );
  }

  async exportInvoiceXLSwithEmail(params) {
    let access_token = LocalStorage.getAccessToken();
    const API_URL = "https://nbedev1.hungerhub.com/api";
    let url = `${API_URL}/v2/uncatering_admin/sales_receipts.json?location_id=${params.locationId}&start_date=${params.startDate}&end_date=${params.endDate}&email=${params.email}`;
    this.setLoader(true);

    try {
      const response = await axios({
        url,
        method: "POST",
        headers: {
          "ACCESS-TOKEN": access_token,
        },
      });

      let newBlob = new Blob([response.data], { type: "application/xlsx" });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement("a");
        link.href = url;
        link.download = `${params.invoiceNumber}_sales_report.xlsx`;
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 100);
      }

      this.setLoader(false);
      return response;
    } catch (error) {
      errorHandler(error.response);
      this.setLoader(false);
      return error;
    }
  }

  async getAvailableDates(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.availableDates()}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.availableDates = data?.dates;
          return data;
        }),
        action("fetchError", (error) => {
          return error?.response;
        })
      );
  }

  async getAvailableTimes(params) {
    let paramsString = qs.stringify(params);
    this.timeOptionsLoading = true;

    return client()
      .get(`${api.availableTimes()}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.timeOptionsLoading = false;
          this.allTimes = data?.times;

          return data;
        }),
        action("fetchError", (error) => {
          this.timeOptionsLoading = false;
          return error?.response;
        })
      );
  }

  setNewAddress(address) {
    this.selectedAddress = address;
  }

  setDate(date) {
    this.selectedDate = date;
  }

  setTime(time) {
    this.selectedTime = time;
  }

  setSort(value) {
    this.sortBy = value;
  }

  async getLocationMenus(params, signal) {
    let paramsString = qs.stringify(params, { arrayFormat: "brackets" });

    return client()
      .get(`${api.locationMenus()}?${paramsString}`, { signal })
      .then(
        action("fetchSuccess", ({ data }) => {
          if (params?.page == 1) this.allMenus = data?.data;
          else this.allMenus = this.allMenus.concat(data?.data);

          this.allMenusPagy = data?.pagy;

          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async getSettings() {
    return client()
      .get(`${api.settings()}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.allFilters = data?.data;

          return data?.data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async createUserOrder(params) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.orders()}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          return error?.response;
        })
      );
  }

  setFreshChatOpen(isOpen) {
    this.isFreshChatOpen = isOpen;
  }
}

const userStore = new UserStore();
export default userStore;
