import React, { useContext, useState, useEffect, useReducer } from "react";
import { MobXProviderContext } from "mobx-react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import Select from "react-select";
import { customStylesSubsidy } from "utils/helpers/styles";
import { CutleryIcon } from "assets/img";
import PlaceOrderCart from "components/PlaceOrderCart";
import PlaceOrderMenu from "components/PlaceOrderMenu";
import PlaceOrderConfirmationModal from "components/PlaceOrderConfirmationModal";
import { AddCard } from "alerts";
import { longDateFormatWithShortDay } from "utils/helpers/dateTime";
import moment from "moment-timezone";
import { checkIfCapacityReached } from "utils/helpers/order";
import { needToPay } from "utils/helpers/order";
import CustomDropdownIndicator from "components/CustomDropdownIndicator";
import PlaceOrderSuccessModal from "components/PlaceOrderSuccessModal";

function PlaceOrder() {
  const initialState = {
    users: null,
    selectedUser: null,
    dates: [],
    selectedDate: null,
    meals: [],
    selectedMeal: null,
    mealDetails: null,
    restaurants: [],
    selectedRestaurant: null,
    restaurantDetail: null,
    temporaryCard: null,
    temporaryCardSelected: false,
    showTemporaryCard: false,
    overrideSubsidy: false,
    cutlery: false,
    tip: null,
    selectedOption: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const store = useContext(MobXProviderContext);
  const userStore = store?.userStore;
  const placeOrderStore = toJS(store?.placeOrderStore);
  const [provider, dispatch] = useReducer(reducer, initialState);
  const [placeOrderModal, setPlaceOrderModal] = useState(false);
  const [bypassCapacity, setBypassCapacity] = useState(null);
  const [addCard, setAddCard] = useState(false);
  let { defaultLocation } = userStore;
  let { cart } = placeOrderStore;

  const [isCardSelected, setIsCardSelected] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    store.userStore.setLoader(true);

    store.mealManagementStore
      .fetchEmployees(defaultLocation.organizationId)
      .then((response) => {
        if (response?.data?.length > 0) {
          const locationUsers = response?.data?.map((employee) => ({
            label: `${employee.attributes.first_name ?? ""} ${
              employee.attributes.last_name ?? ""
            }`,
            value: employee.attributes.id,
            employeeId: employee.attributes.employee_id,
            showTip: employee.attributes.show_tip,
          }));

          dispatch({ field: "users", value: locationUsers });
          store.userStore.setLoader(false);
        }
      });
  }, [defaultLocation.organizationId]);

  const addItemToCart = (
    menuItemId,
    selectedOptions,
    instructions,
    parentCategoryId,
    byPassCapacityLimit = false
  ) => {
    let payload = {
      meal_date: provider.selectedDate.value,
      meal_name: provider.selectedMeal.value,
      order_items: {
        menu_item_id: menuItemId,
        menu_category_id: parentCategoryId,
        quantity: "1",
        special_instructions: instructions,
        order_item_options_attributes: selectedOptions,
      },
    };
    if (byPassCapacityLimit) payload.bypass_capacity_validation = true;
    store.userStore.setLoader(true);

    store.placeOrderStore
      .addItemToCart(
        {
          location_employee_id: provider.selectedUser?.value,
          location_id: defaultLocation.organizationId,
        },
        payload
      )
      .then((response) => {
        store.userStore.setLoader(false);
        if (checkIfCapacityReached(response?.errors)) {
          setBypassCapacity({
            menuItemId,
            selectedOptions,
            instructions,
            parentCategoryId,
          });
        } else {
          store.placeOrderStore.getCart(provider.selectedUser?.showTip, {
            location_employee_id: provider.selectedUser?.value,
            location_id: defaultLocation.organizationId,
          });
        }
      });
  };

  const getDates = (user) => {
    store.userStore.setLoader(true);
    store.placeOrderStore
      .getDays({
        location_employee_id: user.value,
        location_id: defaultLocation.organizationId,
      })
      .then((response) => {
        const days = response?.days?.map((day) => ({
          label: moment(day).format(longDateFormatWithShortDay),
          value: day,
        }));

        dispatch({ field: "dates", value: days });

        store.userStore.setLoader(false);
      });
  };

  const getMeals = (date) => {
    store.userStore.setLoader(true);
    store.placeOrderStore
      .getMeals({
        location_employee_id: provider.selectedUser.value,
        location_id: defaultLocation.organizationId,
        date: date,
      })
      .then((response) => {
        const meals = response?.meal_names?.map((meal) => ({
          label: meal,
          value: meal,
        }));

        dispatch({ field: "meals", value: meals });
        dispatch({ field: "mealDetails", value: response?.meal_details });

        store.userStore.setLoader(false);
      });
  };

  const getMealDetails = (selectedMeal) => {
    const selectedMealData = provider.mealDetails?.find(
      (meal) => meal.meal_name == selectedMeal
    );

    const active_menus = selectedMealData
      ? selectedMealData?.menus?.map((menu) => ({
          label: menu.display_name,
          value: menu.id,
          disabled: false,
        }))
      : [];

    const stopped_menus = selectedMealData
      ? selectedMealData?.stopped_menus?.map((menu) => ({
          label: menu.display_name,
          value: menu.id,
          disabled: true,
        }))
      : [];

    const allMenus = [...active_menus, ...stopped_menus];
    dispatch({ field: "restaurants", value: allMenus });
  };

  const getRestaurantDetail = (id) => {
    store.userStore.setLoader(true);

    store.placeOrderStore
      .getMenuDetails(id, {
        location_id: defaultLocation.organizationId,
        location_employee_id: provider.selectedUser.value,
        date: provider.selectedDate.value,
      })
      .then((response) => {
        dispatch({ field: "restaurantDetail", value: response?.data });
        store.userStore.setLoader(false);
      });
  };

  const removeItemFromCart = (itemId) => {
    store.userStore.setLoader(true);
    store.placeOrderStore
      .removeItemFromCart(itemId, {
        location_employee_id: provider.selectedUser.value,
        location_id: defaultLocation.organizationId,
      })
      .then(() => {
        store.placeOrderStore.getCart(provider.selectedUser.showTip, {
          location_employee_id: provider.selectedUser.value,
          location_id: defaultLocation.organizationId,
        });
        store.userStore.setLoader(false);
      });
  };

  const clearCart = () => {
    dispatch({ field: "restaurant", value: null });
    dispatch({ field: "restaurantDetail", value: null });
    store.placeOrderStore.getCart(
      cart?.billing_setting && cart?.billing_setting?.tip_fee_display_to_user,
      {
        location_employee_id: provider.selectedUser.value,
        location_id: defaultLocation.organizationId,
      }
    );
  };

  const setTipValue = (tip) => {
    dispatch({ field: "tip", value: tip });
    if (provider.selectedUser?.value)
      store.placeOrderStore.setTip(
        tip,
        cart?.billing_setting && cart?.billing_setting?.tip_fee_display_to_user,
        {
          location_employee_id: provider.selectedUser.value,
          location_id: defaultLocation.organizationId,
        }
      );
    else store.placeOrderStore.resetCart();
  };

  const onUserChange = (user) => {
    dispatch({ field: "selectedUser", value: user });
    getDates(user);
    store.placeOrderStore.getCart(user?.showTip, {
      location_employee_id: user?.value,
      location_id: defaultLocation?.organizationId,
    });
    dispatch({ field: "selectedDate", value: null });
    dispatch({ field: "selectedMeal", value: null });
    dispatch({ field: "selectedRestaurant", value: null });
  };

  const handleOverrideSubsidy = (value, isEmptyCard) => {
    if (isEmptyCard) dispatch({ field: "overrideSubsidy", value: true });
    else {
      dispatch({ field: "overrideSubsidy", value: value });
      setIsCardSelected(!value);
      dispatch({
        field: "temporaryCardSelected",
        value: value == false ? false : !value,
      });
    }
  };

  const handleTemporaryCard = (card) => {
    dispatch({ field: "temporaryCard", value: card });
    dispatch({ field: "showTemporaryCard", value: true });
    dispatch({ field: "temporaryCardSelected", value: true });
    dispatch({ field: "overrideSubsidy", value: false });
    dispatch({ field: "isCardSelected", value: true });
  };

  return (
    <div className="d-row p-6 w-full relative">
      <div className="d-col w-1/2">
        <p className="font-inter-bold text-heading text-[32px]">
          Place Orders On Behalf Of Users
        </p>

        <Select
          styles={customStylesSubsidy({ preferences: true })}
          aria-labelledby="sort-by"
          aria-label="sort-by"
          placeholder="Select a user"
          closeMenuOnSelect
          onChange={onUserChange}
          options={provider.users}
          className="w-full mt-[24px]"
        />

        <Select
          styles={customStylesSubsidy({ preferences: true })}
          aria-labelledby="sort-by"
          aria-label="sort-by"
          placeholder="Select date"
          closeMenuOnSelect
          onChange={(option) => {
            dispatch({ field: "selectedDate", value: option });
            getMeals(option.value);
          }}
          value={provider.selectedDate}
          options={provider.dates}
          className="w-full mt-[24px]"
          isDisabled={!provider.selectedUser}
          components={{
            DropdownIndicator: CustomDropdownIndicator,
          }}
        />

        <Select
          styles={customStylesSubsidy({ preferences: true })}
          aria-labelledby="sort-by"
          aria-label="sort-by"
          placeholder="Select meal"
          closeMenuOnSelect
          onChange={(option) => {
            dispatch({ field: "selectedMeal", value: option });
            getMealDetails(option.value);
          }}
          options={provider.meals}
          className="w-full mt-[24px]"
          isDisabled={!provider.selectedDate}
        />

        <Select
          styles={customStylesSubsidy({ preferences: true })}
          aria-labelledby="sort-by"
          aria-label="sort-by"
          placeholder="Select restaurant"
          closeMenuOnSelect
          onChange={(option) => {
            if (!option.disabled)
              dispatch({ field: "selectedRestaurant", value: option });
            getRestaurantDetail(option.value);
          }}
          options={provider.restaurants}
          isOptionDisabled={(option) => option.disabled}
          className="w-full mt-[24px]"
          isDisabled={!provider.selectedMeal}
        />

        <PlaceOrderMenu
          restaurantDetail={provider.restaurantDetail?.attributes}
          addItemToCart={addItemToCart}
          employeeId={provider.selectedUser?.employeeId}
        />
      </div>

      <div className="d-col w-1/2 p-12 items-center">
        {cart?.all_items?.data?.length > 0 ? (
          <PlaceOrderCart
            cart={cart}
            selectedDate={provider.selectedDate?.value}
            selectedMeal={provider?.selectedMeal?.value}
            selectedOffice={provider.selectedUser}
            removeItemFromCart={removeItemFromCart}
            selectedRestaurant={provider?.selectedRestaurant?.value}
            resetAll={() => dispatch({ type: "reset" })}
            tip={provider.tip}
            setTipValue={setTipValue}
            resetCart={() => store.placeOrderStore.resetCart()}
            clearCart={clearCart}
            setPlaceOrderConfirm={setPlaceOrderModal}
            setCutlery={(val) => dispatch({ field: "cutlery", value: val })}
            cutlery={provider.cutlery}
            setSelectedOptions={(options) =>
              dispatch({ field: "selectedOption", value: options })
            }
            selectedOptions={provider.selectedOption}
            setShowSuccessModal={setShowSuccessModal}
            setOrder={(order) => setOrder(order)}
            bypassCapacity={bypassCapacity}
          />
        ) : (
          <div className="w-[446px] h-[596px] ml-6 bg-white rounded-3xl border border-[#dee2e6] flex justify-center items-center">
            <img src={CutleryIcon} alt="cutlery-icon" />
          </div>
        )}
      </div>

      <PlaceOrderConfirmationModal
        visible={placeOrderModal}
        setModal={setPlaceOrderModal}
        hanldeAddCreditCard={() => setAddCard(true)}
        temporaryCard={provider.temporaryCard}
        temporaryCardSelected={provider.temporaryCardSelected}
        overrideSubsidy={provider.overrideSubsidy}
        handleOverrideSubsidy={handleOverrideSubsidy}
        needToPay={needToPay(cart).toFixed(2)}
        orderTotal={parseFloat(cart?.grand_total).toFixed(2)}
        subsidizedSubtotal={parseFloat(cart?.subsidized_subtotal).toFixed(2)}
        isCardSelected={isCardSelected}
        setIsCardSelected={(value) => setIsCardSelected(value)}
        handleTemporaryCard={(value) => {
          dispatch({ field: "temporaryCardSelected", value: value });
          dispatch({ field: "showTemporaryCard", value: value });
        }}
        cutlery={provider.cutlery}
        tip={provider.tip}
        cart={cart}
        selectedOffice={provider.selectedUser}
        resetAll={() => dispatch({ type: "reset" })}
        clearCart={clearCart}
        selectedOptions={provider.selectedOption}
        setShowSuccessModal={setShowSuccessModal}
        setOrder={(order) => setOrder(order)}
      />

      <AddCard
        visible={addCard}
        setModal={() => {
          setAddCard(false);
          setPlaceOrderModal(true);
        }}
        rightClick={() => {
          setAddCard(false);
          setPlaceOrderModal(true);
        }}
        isPlaceOrder
        handlePaymentMethod={() => {
          dispatch({
            field: "overrideSubsidy",
            value: false,
          });
          setPlaceOrderModal(true);
        }}
        handleTemporaryCard={handleTemporaryCard}
      />

      <PlaceOrderSuccessModal
        visible={showSuccessModal}
        setModal={(val) => {
          setShowSuccessModal(val);
          window.location.reload();
        }}
        meal={order?.[0]}
      />
    </div>
  );
}

export default observer(PlaceOrder);
