import { makeAutoObservable, observable, action } from "mobx";
import { client } from "../store";
import api from "store/api";
import userStore from "./userStore";
import qs from "qs";

class InvitationLinkStore {
  invitationLinks = [];

  constructor() {
    makeAutoObservable(this, {
      invitationLinks: observable,
    });
  }

  async getInvitationLinks(mealId, locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.invitationLinks()}?meal_id=${mealId}&location_id=${locationId}`
      );
      userStore.setLoader(false);
      this.invitationLinks = response?.data?.data;
      return response;
    } catch (error) {}
  }

  async createInvitationLink(params, payload) {
    try {
      userStore.setLoader(true);
      const response = await client().post(
        `${api.invitationLinks()}?${qs.stringify(params)}`,
        payload
      );
      userStore.setLoader(false);
      return response?.data?.data;
    } catch (error) {}
  }
}

const invitationLinkStore = new InvitationLinkStore();
export default invitationLinkStore;
