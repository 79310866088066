import { observable, action, makeObservable } from "mobx";
import { makePersistable, clearPersistedStore } from "mobx-persist-store";
import { client } from "../store";
import api from "store/api";
import qs from "qs";
import { errorHandler } from "utils/middlewares/errorHandler";

class RestaurantStore {
  restaurants = [];
  activeRestaurant = null;

  tagsFilterModal = false;

  activeMenuItemTagsLength = 0;

  orderDeadline = null;
  orderDeadlineTime = null;

  constructor() {
    makePersistable(this, {
      name: "RestaurantStore",
      properties: [
        "restaurants",
        "activeRestaurant",
        "orderDeadline",
        "orderDeadlineTime",
      ],
      storage: window.localStorage,
    });
    makeObservable(this, {
      restaurants: observable,
      activeRestaurant: observable,
      orderDeadline: observable,
      orderDeadlineTime: observable,

      getRestaurantDetails: action,
      getMenuItemOptions: action,
    });
  }

  async getRestaurantDetails(id, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.locationMenus()}/${id}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async setActiveRestaurant(activeRestaurant) {
    this.activeRestaurant = activeRestaurant;
  }

  async getMenuItemOptions(id) {
    return client()
      .get(`${api.menuItems()}/${id}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async getMenuPreview(id, payload) {
    let paramsString = qs.stringify(payload);

    return client()
      .get(`${api.previewMenu(id)}/?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }
}

const restaurantStore = new RestaurantStore();
export default restaurantStore;
