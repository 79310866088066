import React, { useState, useContext, useEffect, useRef } from "react";
import { DatePicker, NotificationBanner } from "components";
import { NotFound } from "components";
import { MobXProviderContext, observer } from "mobx-react";
import dayjs from "dayjs";
import dateTime from "utils/helpers/dateTime";
import { priceFormatter } from "utils/helpers/validation";
import { validateEmail } from "utils/helpers/validation";
import classNames from "classnames";
import ReactPaginate from "react-paginate";
import { ArrowDownIcon, ArrowDownUp, DotsIcon } from "assets/img";
import SelectionModal from "components/SelectionModal";
import { errorHandler } from "utils/middlewares/errorHandler";
import InvoiceRequestCard from "alerts/invoiceRequestCard";
import {
  dotsDropdownOptions,
  dueDatesOptions,
  invoiceDatesOptions,
  orderCateringTypeOptions,
  orderStatusOptions,
  orderUncateringTypeOptions,
} from "utils/constants/common";

function UncateringInvoices() {
  const [startDate, setStartDate] = useState();
  const [email, setEmail] = useState();
  const [endDate, setEndDate] = useState();
  const { userStore } = useContext(MobXProviderContext);
  const store = useContext(MobXProviderContext);

  const { defaultLocation, invoices, invoicesPagy } = userStore;
  const isValidEmail = email && validateEmail(email);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [showTypeModal, setShowTypeModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showInvoiceDateModal, setShowInvoiceDateModal] = useState(false);
  const [showDueDateModal, setShowDueDateModal] = useState(false);
  const [showDotsDropdown, setShowDotsDropdown] = useState(false);
  const [invoiceCard, setInvoiceCard] = useState(false);
  const [enableInvoice, setEnableInvoice] = useState("disabled");
  const [showInvoiceNotification, setShowInvoiceNotification] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const [typeModalPosition, setTypeModalPosition] = useState({
    top: 0,
    left: 0,
  });
  const [statusModalPosition, setStatusModalPosition] = useState({
    top: 0,
    left: 0,
  });
  const [invoiceDateModalPosition, setInvoiceDateModalPosition] = useState({
    top: 0,
    left: 0,
  });
  const [dueDateModalPosition, setDueDateModalPosition] = useState({
    top: 0,
    left: 0,
  });
  const [dotsDropdownPosition, setDotsDropdownPosition] = useState({
    top: 0,
    left: 0,
  });

  const orderTypeRef = useRef(null);
  const orderStatusRef = useRef(null);
  const invoiceDateRef = useRef(null);
  const dueDateRef = useRef(null);
  const dotsDropdownRef = useRef(null);

  const [selectedFilters, setSelectedFilters] = useState({
    order_type: [],
    order_status: [],
    invoice_date: "",
    due_date: "",
    sorting: "",
  });

  useEffect(() => {
    if (defaultLocation?.organizationId) {
      let statusFilter = selectedFilters?.order_status?.map((status) => {
        return status;
      });

      if (statusFilter?.includes("1")) {
        statusFilter = [...new Set([...statusFilter, "2", "4"])];
      }

      if (statusFilter?.includes("3")) {
        statusFilter = [...new Set([...statusFilter, "6"])];
      }

      let params = {
        location_id: defaultLocation?.organizationId,
        page: 1,
        per_page: 8,
        "q[status_in]": statusFilter,
        "q[shipments_service_in]": selectedFilters?.order_type?.map((type) => {
          return type;
        }),
        "q[s]": selectedFilters?.sorting,
      };
      userStore.getUncateringInvoices(params);

      setShowInvoiceNotification(
        defaultLocation?.invoicePayments == "requested"
      );
      setEnableInvoice(defaultLocation?.invoicePayments);
    }
  }, [
    defaultLocation?.organizationId,
    defaultLocation?.invoicePayments,
    selectedFilters,
  ]);

  const invoiceStatus = (status) => {
    switch (status) {
      case "sent_failed":
        return "Sent Failed";
      case "open":
        return "Open";
      case "sent":
        return "Sent";
      case "paid":
        return "Paid";
      case "overdue":
        return "Overdue";
      case "closed":
        return "Closed";
      default:
        return "";
    }
  };

  const exportPDF = (invoice) => {
    userStore.exportInvoicePDF({
      invoiceId: invoice?.id,
      invoiceNumber: invoice?.attributes?.invoice_number,
      locationId: defaultLocation?.organizationId,
    });
  };

  const exportSalesReport = (invoice) => {
    userStore.exportInvoiceXLS({
      invoiceId: invoice?.id,
      invoiceNumber: invoice?.attributes?.invoice_number,
      locationId: defaultLocation?.organizationId,
    });
  };

  const generateCustomReport = async (e) => {
    e.preventDefault();

    if (isValidEmail && startDate && endDate) {
      try {
        await userStore.exportInvoiceXLSwithEmail({
          locationId: defaultLocation?.organizationId,
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
          email: email,
        });

        setShowSuccessMessage(true);
      } catch (error) {
        errorHandler(error.response);
        setShowSuccessMessage(false);
      }
    }
  };

  const pageClick = (page) => {
    let params = {
      location_id: defaultLocation?.organizationId,
      page: page.selected + 1,
      per_page: 8,
    };
    userStore.getUncateringInvoices(params);
  };

  useEffect(() => {
    setEndDate("");
  }, [startDate]);

  const handleOptionChange = (e, filterType) => {
    if (filterType === "invoice_date" || filterType === "due_date") {
      setSelectedFilters((prevState) => ({
        ...prevState,
        sorting: e ?? "",
      }));
      return;
    }

    // For order_type and order_status, use e.target for checkbox input
    const { name, checked } = e?.target;

    switch (filterType) {
      case "order_type":
        setSelectedFilters((prevState) => {
          let newOrderTypes = [...prevState.order_type];
          if (checked) {
            if (!newOrderTypes?.includes(name)) {
              newOrderTypes?.push(name);
            }
          } else {
            newOrderTypes = newOrderTypes?.filter(
              (orderType) => orderType !== name
            );
          }
          return {
            ...prevState,
            order_type: newOrderTypes,
          };
        });
        break;

      case "order_status":
        setSelectedFilters((prevState) => {
          let newOrderStatus = [...prevState.order_status];
          if (checked) {
            if (!newOrderStatus?.includes(name)) {
              newOrderStatus?.push(name);
            }
          } else {
            newOrderStatus = newOrderStatus?.filter(
              (status) => status !== name
            );
          }
          return {
            ...prevState,
            order_status: newOrderStatus,
          };
        });
        break;

      default:
        break;
    }
  };

  const handleIconClick = (filterType, invoiceId) => {
    const rect =
      filterType === "dots_dropdown"
        ? dotsDropdownRef.current.getBoundingClientRect()
        : filterType === "order_type"
        ? orderTypeRef.current.getBoundingClientRect()
        : filterType === "invoice_date"
        ? invoiceDateRef.current.getBoundingClientRect()
        : filterType === "due_date"
        ? dueDateRef.current.getBoundingClientRect()
        : orderStatusRef.current.getBoundingClientRect();

    const modalPosition = {
      top: rect.bottom,
      left: rect.left,
    };

    switch (filterType) {
      case "order_type":
        setTypeModalPosition(modalPosition);
        setShowTypeModal(true);
        break;
      case "invoice_date":
        setInvoiceDateModalPosition(modalPosition);
        setShowInvoiceDateModal(true);
        break;
      case "due_date":
        setDueDateModalPosition(modalPosition);
        setShowDueDateModal(true);
        break;
      case "status":
        setStatusModalPosition(modalPosition);
        setShowStatusModal(true);
        break;
      case "dots_dropdown":
        setOpenDropdown((prevState) =>
          prevState === invoiceId ? null : invoiceId
        );

        setDotsDropdownPosition({
          top: rect.bottom,
          left: rect.left - 120,
        });
        setShowDotsDropdown(true);
        break;
      default:
        break;
    }
  };

  const handleOptionClick = (value, filter, invoice) => {
    if (value == "sales_report") exportSalesReport(invoice);
    else if (value == "invoice") exportPDF(invoice);
    else handleOptionChange(value, filter);
  };

  const handleRequestInvoiceAvailability = (reasonText) => {
    const payload = {
      location: {
        invoice_payments: "requested",
        invoice_payments_reason: reasonText,
      },
    };

    store.userStore.setLoader(true);
    store.paymentInfoStore
      .requestInvoiceAvailability(defaultLocation?.organizationId, payload)
      .then((response) => {
        store.userStore.setLoader(false);
        setEnableInvoice(response?.data?.attributes.invoice_payments);
        setInvoiceCard(false);
        if (response?.data?.attributes?.invoice_payments == "requested")
          setShowInvoiceNotification(true);
      })
      .catch(() => store.userStore.setLoader(false));
  };

  return (
    <div className="flex-col w-full justify-start items-start py-[24px] px-[32px]">
      <div className="flex-col justify-start items-start w-full">
        <NotificationBanner
          message="Period sales report successfully generated and sent to your email"
          messageType="success"
          textColor="success"
          visible={showSuccessMessage}
          setVisible={setShowSuccessMessage}
        />

        <NotificationBanner
          message="Your request has been sent. This message will disappear once changes have taken effect."
          messageLink="Contact us"
          messageType="warning"
          visible={showInvoiceNotification}
          setVisible={setShowInvoiceNotification}
          handleLinkClick={() => store.userStore.setFreshChatOpen(true)}
        />

        <h1 className="font-inter-semibold text-[32px] text-dark-gray">
          Invoices
        </h1>
        <div className="w-full bg-light-gray py-4 px-3 mt-[24px] rounded-md">
          <h2 className="font-inter-semibold text-xl text-dark-gray">
            Generate custom period sales report of your orders{" "}
          </h2>

          <div className="d-row justify-between mt-[24px] w-full">
            <div className="d-row w-4/5">
              <DatePicker
                selectedDate={startDate}
                setSelectedDate={setStartDate}
                placeholderText="Select start date"
                format="MMMM d, yyyy"
                className="flex h-12 bg-white justify-between rounded-lg mr-[20px] pr-2 items-center"
                pickerClassName="font-inter-medium"
                isInvoice={true}
                disabled={defaultLocation?.invoicePayments !== "enabled"}
              />

              <DatePicker
                selectedDate={endDate}
                setSelectedDate={setEndDate}
                minDate={new Date(startDate)}
                format="MMMM d, yyyy"
                placeholderText="Select end date"
                className="flex h-12 bg-white justify-between rounded-lg mr-[20px] pr-2 items-center"
                pickerClassName="font-inter-medium"
                isInvoice={true}
                disabled={defaultLocation?.invoicePayments !== "enabled"}
              />

              <input
                className="input-gray-bg bg-white text-sm mr-[20px] placeholder-[#566884]"
                type="email"
                onChange={(e) => {
                  setEmail(e?.target?.value);
                }}
                placeholder="Enter your email"
                value={email}
              />
            </div>

            <button
              onClick={generateCustomReport}
              className={classNames({
                "btn-light-gray text-[16px] w-[146px] text-white bg-catering": true,
                "opacity-50 bg-zinc-200":
                  !isValidEmail ||
                  defaultLocation?.invoicePayments !== "enabled",
              })}
              disabled={
                !isValidEmail || defaultLocation?.invoicePayments !== "enabled"
              }
            >
              Send
            </button>
          </div>
        </div>

        <h3 className="font-inter-semibold text-xl text-dark-gray mt-[24px]">
          Past Invoices
        </h3>

        <div className="flex-col items-start gap-6 flex">
          <div className="mt-8 w-full flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr className="bg-light-gray">
                      <th
                        ref={orderTypeRef}
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3"
                      >
                        Order type
                        {defaultLocation?.invoicePayments == "enabled" && (
                          <img
                            src={ArrowDownIcon}
                            className="icon inline-block cursor-pointer"
                            onClick={() => handleIconClick("order_type")}
                          />
                        )}
                        {showTypeModal &&
                          defaultLocation?.invoicePayments == "enabled" && (
                            <SelectionModal
                              options={
                                defaultLocation?.uncatering
                                  ? orderUncateringTypeOptions
                                  : orderCateringTypeOptions
                              }
                              selectedOptions={selectedFilters?.order_type}
                              handleTypeChange={handleOptionChange}
                              handleCloseModal={() => setShowTypeModal(false)}
                              modalPosition={typeModalPosition}
                              isCheckbox
                              filterType="order_type"
                              visible={showTypeModal}
                            />
                          )}
                      </th>

                      <th
                        ref={invoiceDateRef}
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                      >
                        Invoice date
                        {defaultLocation?.invoicePayments == "enabled" && (
                          <img
                            src={ArrowDownUp}
                            className="icon inline-block cursor-pointer ml-2"
                            onClick={() => handleIconClick("invoice_date")}
                          />
                        )}
                        {showInvoiceDateModal &&
                          defaultLocation?.invoicePayments == "enabled" && (
                            <SelectionModal
                              options={invoiceDatesOptions}
                              selectedOptions={selectedFilters?.sorting}
                              handleCloseModal={() =>
                                setShowInvoiceDateModal(false)
                              }
                              handleOptionClick={(value, filter) => {
                                handleOptionClick(value, filter);
                              }}
                              modalPosition={invoiceDateModalPosition}
                              filterType="invoice_date"
                              visible={showInvoiceDateModal}
                            />
                          )}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Invoice no.
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Period start
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Period end
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Invoice amount
                      </th>

                      <th
                        ref={dueDateRef}
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Due date
                        {defaultLocation?.invoicePayments == "enabled" && (
                          <img
                            src={ArrowDownUp}
                            className="icon inline-block cursor-pointer ml-2"
                            onClick={() => handleIconClick("due_date")}
                          />
                        )}
                        {showDueDateModal &&
                          defaultLocation?.invoicePayments == "enabled" && (
                            <SelectionModal
                              options={dueDatesOptions}
                              selectedOptions={selectedFilters.sorting}
                              handleOptionClick={(value, filter) => {
                                handleOptionClick(value, filter);
                              }}
                              handleCloseModal={() =>
                                setShowDueDateModal(false)
                              }
                              modalPosition={dueDateModalPosition}
                              filterType="due_date"
                              visible={showDueDateModal}
                            />
                          )}
                      </th>

                      <th
                        ref={orderStatusRef}
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      >
                        Status
                        {defaultLocation?.invoicePayments == "enabled" && (
                          <img
                            src={ArrowDownIcon}
                            className="icon inline-block cursor-pointer"
                            onClick={() => handleIconClick("status")}
                          />
                        )}
                        {showStatusModal &&
                          defaultLocation?.invoicePayments == "enabled" && (
                            <SelectionModal
                              options={orderStatusOptions}
                              selectedOptions={selectedFilters?.order_status}
                              handleTypeChange={handleOptionChange}
                              handleCloseModal={() => setShowStatusModal(false)}
                              modalPosition={statusModalPosition}
                              isCheckbox
                              filterType="order_status"
                              visible={showStatusModal}
                            />
                          )}
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                      ></th>
                    </tr>
                  </thead>

                  <tbody className="bg-white">
                    {invoices?.map((invoice) => (
                      <tr key={invoice?.id} className="even:bg-light-gray">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-3">
                          {invoice?.attributes?.order_type
                            ?.map((order) => {
                              switch (order) {
                                case "odc":
                                  return "Catering";
                                case "grouped":
                                  return "Group Order";
                                default:
                                  return "Uncatering";
                              }
                            })
                            .join(", ")}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-medium text-dark-gray sm:pl-3">
                          {dateTime.onlyDate(invoice?.attributes?.invoice_date)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                          {dateTime.onlyDate(
                            invoice?.attributes?.invoice_number
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                          {dateTime.onlyDate(invoice?.attributes?.start_date)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                          {dateTime.onlyDate(invoice?.attributes?.end_date)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                          {priceFormatter(invoice?.attributes?.invoice_total)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                          {dateTime.onlyDate(invoice?.attributes?.due_date) ??
                            "-"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                          {invoiceStatus(invoice?.attributes?.status) ?? "-"}
                        </td>
                        <td
                          className="whitespace-nowrap px-3 py-4"
                          ref={dotsDropdownRef}
                        >
                          <img
                            src={DotsIcon}
                            alt="dots-icon"
                            className="cursor-pointer"
                            onClick={() =>
                              handleIconClick("dots_dropdown", invoice?.id)
                            }
                          />

                          {showDotsDropdown && openDropdown === invoice?.id && (
                            <SelectionModal
                              options={dotsDropdownOptions}
                              handleOptionClick={(value, filter) => {
                                handleOptionClick(value, filter, invoice);
                              }}
                              handleCloseModal={() => setOpenDropdown(null)}
                              modalPosition={dotsDropdownPosition}
                              isDotsDropdown
                              visible={openDropdown === invoice?.id}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <NotFound
                  data={invoices}
                  message={
                    defaultLocation?.invoicePayments !== "enabled"
                      ? "Your account does not currently have invoicing enabled and can only charge by credit card"
                      : "No invoices found"
                  }
                />

                {!defaultLocation?.uncatering &&
                  defaultLocation?.invoicePayments !== "enabled" && (
                    <div className="d-row flex-row-reverse mt-[24px]">
                      <button
                        className={classNames({
                          "btn-blue-accent": true,
                          "bg-zinc-200 text-white":
                            enableInvoice == "requested",
                        })}
                        disabled={enableInvoice == "requested"}
                        onClick={() => setInvoiceCard(true)}
                      >
                        Request invoice availibility
                      </button>
                    </div>
                  )}

                {invoicesPagy && invoicesPagy?.count !== 0 && (
                  <div className="w-full flex items-center justify-center mt-12 text-primary-color">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={<span className="gap">...</span>}
                      pageCount={invoicesPagy?.pages}
                      onPageChange={pageClick}
                      forcePage={
                        invoicesPagy?.page - 1 > 0 ? invoicesPagy?.page - 1 : 0
                      }
                      containerClassName={"pagination"}
                      previousLinkClassName={"previous_page"}
                      nextLinkClassName={"next_page"}
                      pageClassName={"page_number"}
                      activeClassName={"active_page"}
                    />
                  </div>
                )}

                {invoiceCard && (
                  <InvoiceRequestCard
                    visible={invoiceCard}
                    setModal={setInvoiceCard}
                    rightClick={handleRequestInvoiceAvailability}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(UncateringInvoices);
