import React, {
  useState,
  useContext,
  useReducer,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { refreshIcon } from "assets/img";
import { ViewBag } from "alerts";
import { useNavigate } from "react-router-dom";
import { IS_LARGE_SCREEN, userOrderStatus } from "utils/constants/common";
import { useQuery } from "@tanstack/react-query";
import { MobXProviderContext } from "mobx-react";
import { toJS } from "mobx";
import { useParams } from "react-router-dom";
import dateTime from "utils/helpers/dateTime";
import { formattedAddres } from "utils/helpers/string";
import { isPresent } from "utils/helpers/array";
import { priceFormatter } from "utils/helpers/validation";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select from "react-select";
import { NotFound } from "components";
import { SearchBar } from "components";
import { observer } from "mobx-react";
import { uncateringShipmentStatus } from "utils/helpers/shipment";
import { ChevronLeftIcon, ChevronRightIcon } from "assets/img";
import dayjs from "dayjs";
import classNames from "classnames";

const debounce = (f, ms) => {
  let timer = null;

  return function (...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(onComplete, ms);
  };
};

function OrdersByRestaurant({ data, meal }) {
  const navigate = useNavigate();
  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full">
            <thead>
              <tr className="bg-light-gray">
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-bold text-dark-gray sm:pl-3"
                >
                  Restaurant
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Users Ordered
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Food Total
                </th>

                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                ></th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {data?.map((shipment, index) => (
                <tr
                  key={index}
                  className="even:bg-light-gray"
                  onClick={
                    IS_LARGE_SCREEN
                      ? null
                      : () => navigate(`/home/order-detail/${shipment?.id}`)
                  }
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-bold text-dark-gray sm:pl-3">
                    {shipment?.menu_name}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    {shipment?.users_ordered_count}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm capitalize font-inter-bold text-dark-gray">
                    {uncateringShipmentStatus(
                      shipment,
                      meal?.attributes?.tzinfo,
                      meal?.attributes?.target_time
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    {priceFormatter(shipment?.food_total)}
                  </td>

                  <td className="hidden lg:flex relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    {shipment?.status != "No Orders" && (
                      <button
                        onClick={() =>
                          navigate(`/home/order-detail/${shipment?.id}`)
                        }
                        className="btn-outline"
                      >
                        View order
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function OrdersByUsers({ status, data, setBagId, setViewBagModal }) {
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const grouped =
    status?.value == "no order"
      ? data?.reduce((acc, item) => {
          acc[item.id] = [item];
          return acc;
        }, {})
      : data?.reduce((acc, curr) => {
          if (!acc[curr?.id]) {
            acc[curr?.id] = [];
          }
          acc[curr?.id].push(curr);
          return acc;
        }, {});

  const getStatusMapping = (status = "sent") => {
    if (
      status == "confirmed" ||
      status == "preview" ||
      status == "confirmed" ||
      status == "sent"
    )
      return "Placed";
    else if (status == "completed") return "Delivered";
    else if (status == "cancelled") return "Cancelled";
    else return "No order";
  };

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 pb-20">
          <table className="min-w-full ">
            <thead>
              <tr className="bg-light-gray">
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-bold text-dark-gray sm:pl-3"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Restaurant
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Team
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Cutlery
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Bag#
                </th>

                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                ></th>

                <th
                  scope="col"
                  className="py-3.5 text-left text-sm font-inter-bold text-transparent"
                >
                  Expand/collapse
                </th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {Object.entries(grouped).map(([id, records]) => {
                const isExpanded = expandedRows[id];
                const hasMultipleRecords = records?.length > 1;

                return (
                  <React.Fragment key={id}>
                    {/* First row (always visible) */}
                    <tr
                      className="even:bg-light-gray cursor-pointer"
                      onClick={() => hasMultipleRecords && toggleRow(id)}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm capitalize font-inter-bold text-dark-gray sm:pl-3">
                        <div className="d-col">
                          {(records[0]?.attributes?.first_name ?? "") +
                            " " +
                            (records[0]?.attributes?.last_name ?? "")}

                          {records[0]?.attributes?.guest && (
                            <small className="text-secondary font-inter-medium text-[12px]">
                              Guest
                            </small>
                          )}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                        {records[0]?.attributes?.restaurant_name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray capitalize">
                        {getStatusMapping(records[0]?.attributes?.status)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                        {records[0]?.attributes?.teams?.map((team, index) => (
                          <div key={index}>{team?.name}</div>
                        ))}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                        {records[0]?.attributes?.cutlery ? "Yes" : "No"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                        {records[0]?.attributes?.status != "cancelled" &&
                          records[0]?.attributes?.bag_number}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                        {records[0]?.attributes?.order_id && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setBagId(records[0]?.attributes?.order_id);
                              setViewBagModal(true);
                            }}
                            className="btn-outline"
                          >
                            View bag
                          </button>
                        )}
                      </td>

                      <td className="px-3 py-4 text-sm font-inter-bold text-dark-gray">
                        {hasMultipleRecords && (
                          <div className="d-row items-center justify-center">
                            <img
                              src={
                                isExpanded ? ChevronLeftIcon : ChevronRightIcon
                              }
                              className="h-[20px] w-[20px] transform-left transform rotate-90"
                            />
                          </div>
                        )}
                      </td>
                    </tr>

                    {/* Expanded rows */}
                    {isExpanded &&
                      records.slice(1).map((user, index) => (
                        <tr key={`${id}-${index}`} className="bg-white">
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm capitalize font-inter-bold text-footer sm:pl-3">
                            <div className="d-col">
                              {(user?.attributes?.first_name ?? "") +
                                " " +
                                (user?.attributes?.last_name ?? "")}

                              {user?.attributes?.guest && <small>Guest</small>}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-footer">
                            {user?.attributes?.restaurant_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-footer capitalize">
                            {user?.attributes?.status === "pending"
                              ? "Placed"
                              : user?.attributes?.status}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-footer">
                            {user?.attributes?.teams?.map(
                              (team, index, arr) => (
                                <div key={index}>
                                  {team?.name}
                                  {index !== arr.length - 1 && ", "}{" "}
                                </div>
                              )
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-footer">
                            {user?.attributes?.cutlery ? "Yes" : "No"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-footer">
                            {user?.attributes?.bag_number}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                            {user?.attributes?.order_id && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setBagId(user?.attributes?.order_id);
                                  setViewBagModal(true);
                                }}
                                className="btn-outline"
                              >
                                View bag
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function OrderDetail() {
  const [viewBagModal, setViewBag] = useState(false);
  const [bagData, setBagData] = useState(null);
  const navigate = useNavigate();
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const homeStore = toJS(store?.homeStore);
  const { locationID, defaultLocation } = userStore;
  const { allEmployees, employeesWithOrderInfo, mealTeams } = homeStore;
  const [usersWithNoOrders, setUsersWithNoOrders] = useState(0);
  const [usersWithOrders, setUsersWithOrders] = useState(0);
  const [bagId, setBagId] = useState(null);
  const [trigger, setTrigger] = useState(0);
  const debouncedFilterRef = useRef(null);

  const { mealId } = useParams();

  const initialState = {
    meal: null,
    userOrderFilter: userOrderStatus[0],
    usersWithOrderInfo: [],
    userTeamFilter: null,
    userOrderSearchText: "",
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["groupOrder", locationID, mealId],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return store.homeStore.fetchMealDetail(mealId, locationID);
    },
  });

  useEffect(() => {
    dispatch({ field: "meal", value: data });
    dispatch({ field: "userOrderFilter", value: userOrderStatus[0] });
    dispatch({ field: "usersWithOrderInfo", value: [] });

    const params = {
      location_id: locationID,
      meal_id: mealId,
      meal_order_details: true,
    };

    store.homeStore.fetchUsersWithOrderDetails(params).then((response) => {
      const usersWithOrderInfo = response?.filter(
        (employee) =>
          employee?.attributes?.order_id &&
          (employee?.attributes?.status == "pending" ||
            employee?.attributes?.status == "preview" ||
            employee?.attributes?.status == "confirmed" ||
            employee?.attributes?.status == "sent")
      );

      dispatch({
        field: "usersWithOrderInfo",
        value: usersWithOrderInfo,
      });
    });

    store?.homeStore?.fetchTeamsForMeal(params);
  }, [data, trigger]);

  const { meal } = provider;

  const cutoffTime = dayjs
    .utc(meal?.attributes?.cutoff)
    .tz(meal?.attributes?.tzinfo);

  const currentTime = dayjs().tz(meal?.attributes?.tzinfo);

  const isExpired = currentTime.isAfter(cutoffTime);

  useEffect(() => {
    if (isPresent(allEmployees)) {
      let employees_id =
        allEmployees?.length &&
        allEmployees?.map((emp) => emp?.attributes?.employee_id);

      const orderedEmployeeIds = meal?.attributes?.orders?.map(
        (order) => order?.attributes?.employee_id
      );

      const employeesWhoHaveNotOrdered = employees_id?.filter(
        (id) => !orderedEmployeeIds?.includes(id)
      );

      setUsersWithNoOrders(employeesWhoHaveNotOrdered?.length);

      const uniqueOrderedEmployeeIds = new Set(
        meal?.attributes?.orders.map(
          (order) =>
            order?.attributes?.employee_id &&
            order?.attributes?.status != "cancelled"
        )
      );
      setUsersWithOrders(uniqueOrderedEmployeeIds?.size);
    }
  }, [allEmployees]);

  const filterByOrderStatus = (filter) => {
    if (filter?.value == "placed") {
      return employeesWithOrderInfo?.filter(
        (employee) =>
          employee?.attributes?.order_id &&
          (employee?.attributes?.status == "pending" ||
            employee?.attributes?.status == "preview" ||
            employee?.attributes?.status == "confirmed" ||
            employee?.attributes?.status == "sent")
      );
    } else if (filter?.value == "delivered") {
      return employeesWithOrderInfo?.filter(
        (employee) =>
          employee?.attributes?.order_id &&
          employee?.attributes?.status == "completed"
      );
    } else if (filter?.value == "no order") {
      return employeesWithOrderInfo?.filter(
        (employee) => !employee?.attributes?.order_id
      );
    } else if (filter?.value == "cancelled") {
      return employeesWithOrderInfo?.filter(
        (employee) =>
          employee?.attributes?.order_id &&
          employee?.attributes?.status == "cancelled"
      );
    }
  };

  useEffect(() => {
    const filter = provider?.userOrderFilter;

    dispatch({
      field: "usersWithOrderInfo",
      value: filterByOrderStatus(filter),
    });
  }, [provider?.userOrderFilter, provider?.userTeamFilter == null]);

  const onChangeUserOrderFilter = (filter) => {
    store?.userStore?.setLoader(true);

    if (filter?.value != provider?.userOrderFilter?.value)
      dispatch({ field: "usersWithOrderInfo", value: [] });
    dispatch({ field: "userTeamFilter", value: null });
    dispatch({ field: "userOrderFilter", value: filter });
    dispatch({ field: "userOrderSearchText", value: "" });

    setTimeout(() => {
      store?.userStore?.setLoader(false);
    }, 500);
  };

  useEffect(() => {
    let users = filterByOrderStatus(provider?.userOrderFilter);
    const usersWithOrderInfo = users?.filter(
      (employee) =>
        employee?.attributes?.team?.id == provider?.userTeamFilter?.value
    );
    if (provider?.userTeamFilter?.value) {
      dispatch({ field: "usersWithOrderInfo", value: [] });
      dispatch({
        field: "usersWithOrderInfo",
        value: usersWithOrderInfo,
      });
    }
  }, [provider?.userTeamFilter]);

  const onChangeTeamFilter = (filter) => {
    store?.userStore?.setLoader(true);

    dispatch({ field: "userTeamFilter", value: filter });

    setTimeout(() => {
      store?.userStore?.setLoader(false);
    }, 500);
  };

  const debouncedFilter = useMemo(() => {
    return debounce((search) => {
      let users = filterByOrderStatus(provider?.userOrderFilter);
      let searchedUsers = users?.filter((user) => {
        return (
          user?.attributes?.first_name
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          user?.attributes?.last_name
            ?.toLowerCase()
            .includes(search.toLowerCase())
        );
      });

      dispatch({
        field: "usersWithOrderInfo",
        value: search ? searchedUsers : users,
      });
    }, 500);
  }, [provider?.userOrderFilter]);

  useEffect(() => {
    debouncedFilter(provider.userOrderSearchText);

    return () => {
      // debouncedFilter?.cancel();
    };
  }, [provider.userOrderSearchText, provider?.userOrderFilter]);

  const handleRefetch = async () => {
    await refetch();
    setTrigger((prev) => prev + 1);
  };

  const handleOnChange = (e) => {
    dispatch({ field: "userOrderSearchText", value: e.target.value });
  };

  return (
    <div className="rounded-lg px-[28px] flex flex-col min-w-full">
      <div className="d-row justify-between mt-[40px]">
        <button
          className="font-inter-medium d-row items-center text-sm cursor-pointer hover:text-gray-500"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={ChevronLeftIcon} className="h-[20px] w-[20px]" />
          Back to home page
        </button>

        <button
          onClick={() => {
            handleRefetch();
          }}
          className="h-12 flex flex-row bg-light-gray items-center px-4 py-[4px] rounded-lg ml-6"
        >
          <img className="h-4 w-4 mr-[8px]" src={refreshIcon} />
          <small className="text-dark-gray font-inter-regular text-sm">
            Refresh Page
          </small>
        </button>
      </div>

      <div className="flex flex-col border border-[#DEE2E6] border-1 rounded-lg p-[12px] mt-[32px] sm:flex-row sm:items-center justify-between">
        <div className="d-col w-full">
          <div className="d-row items-center justify-between">
            <div className="flex flex-col">
              <strong className="text-dark-gray text-[16px] font-inter-semibold">
                {dateTime.formatDate(
                  meal?.attributes?.meal_date,
                  "ddd, MMMM D, yyyy"
                )}{" "}
                {dateTime.checkAndGetTimezone(meal?.attributes?.tzinfo)}
              </strong>
              <span className="text-[24px] font-inter-semibold mr-3">
                {meal?.attributes?.name}
              </span>
            </div>

            <div className="d-row md:mt-[32px] md:justify-between">
              <button
                className={classNames({
                  "btn-outline h-[48px] mr-[12px] rounded-lg font-inter-semibold border-1": true,
                  "opacity-30": isExpired,
                })}
                onClick={() => {
                  navigate(`/home/invitation-links/${meal?.id}`);
                }}
                disabled={isExpired}
              >
                Manage Guest links
              </button>
              <button
                className="btn-blue-accent rounded-lg font-inter-semibold"
                onClick={() =>
                  navigate(`/home/request-meal-changes/${meal?.id}`)
                }
              >
                Request Changes To This Meal
              </button>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-2 mt-[16px]">
            <div className="flex flex-col">
              <span className="text-[14px] font-inter-semibold text-black-light">
                Deadline to order
              </span>
              <span className="text-[14px] font-inter-medium">
                {dateTime.formatDateWithTimzoneInfo(
                  meal?.attributes?.cutoff,
                  "hh:mm a",
                  meal?.attributes?.tzinfo
                )}
              </span>
            </div>

            <div className="flex flex-col">
              <span className="text-[14px] font-inter-semibold text-black-light">
                Delivery time
              </span>
              <span className="text-[14px] font-inter-medium">
                {dateTime.to12hourWithFormat(
                  dateTime?.onlyTime(meal?.attributes?.target_time),
                  meal?.attributes?.tzinfo,
                  "hh:mm a"
                )}
              </span>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-2 mt-[20px]">
            <div className="flex flex-col">
              <span className="text-[14px] font-inter-semibold text-black-light">
                Delivery address
              </span>
              <span className="text-[14px] font-inter-medium">
                {meal?.attributes?.address &&
                  formattedAddres(meal?.attributes?.address?.attributes)}
              </span>
            </div>

            <div className="flex flex-col">
              <span className="text-[14px] font-inter-semibold text-black-light">
                Delivery instructions
              </span>
              <span className="text-[14px] font-inter-medium">
                {meal?.attributes?.address?.attributes?.delivery_instructions}
              </span>
            </div>

            <div className="flex flex-col">
              <span className="text-[14px] font-inter-semibold text-black-light">
                Point of contact
              </span>
              <span className="text-[14px] font-inter-medium">
                {meal?.attributes?.address?.attributes?.delivery_contact_name}
              </span>

              <span className="text-[14px] font-inter-medium">
                {meal?.attributes?.address?.attributes?.delivery_contact_phone}
              </span>
            </div>
          </div>
        </div>
      </div>

      <strong className="text-dark-gray my-[12px] text-[20px] font-inter-medium">
        Orders by Restaurant
      </strong>

      <OrdersByRestaurant
        data={data?.attributes?.shipments}
        meal={provider?.meal}
      />

      <NotFound data={data?.attributes?.shipments} message="No orders found" />

      <div className="d-row justify-between mt-[32px]">
        <div className="d-col">
          <strong className="text-dark-gray text-[20px] font-inter-medium">
            Users status
          </strong>

          <small className="font-inter-regular">
            <span className="hidden lg:flex">
              <p>
                Users who ordered: {usersWithOrders} Users with no orders:{" "}
                {usersWithNoOrders}
              </p>
            </span>

            <span className="hidden lg:hidden md:flex flex-col">
              <p>Users who ordered: {usersWithOrders}</p>
              <p>Users with no orders: {usersWithNoOrders}</p>
            </span>
          </small>
        </div>

        <button
          className="btn-blue-accent rounded-lg font-inter-semibold"
          onClick={() => {
            navigate("/uncatering/place-orders");
          }}
        >
          Place order
        </button>
      </div>

      <div className="d-row items-center mt-[21px]">
        <SearchBar
          value={provider?.userOrderSearchText}
          handleOnChange={handleOnChange}
          placeholder="Search"
          className="!mr-0 mt-6 sm:mt-0"
        />

        <small className="mr-2 ml-[24px]">Filter by:</small>
        <Select
          styles={customStylesSubsidy({ preferences: true })}
          aria-labelledby="sort-by"
          aria-label="sort-by"
          placeholder="Status"
          closeMenuOnSelect
          value={provider?.userOrderFilter}
          onChange={onChangeUserOrderFilter}
          options={userOrderStatus}
          className="w-[195px]"
        />

        <Select
          styles={customStylesSubsidy({ preferences: true })}
          aria-labelledby="sort-by"
          aria-label="sort-by"
          placeholder="Team"
          closeMenuOnSelect
          value={provider?.userTeamFilter}
          onChange={onChangeTeamFilter}
          options={mealTeams}
          isClearable
          className="w-[195px] ml-2"
        />
      </div>

      <OrdersByUsers
        setBagId={setBagId}
        setViewBagModal={setViewBag}
        data={provider?.usersWithOrderInfo}
        status={provider?.userOrderFilter}
      />

      <NotFound data={provider?.usersWithOrderInfo} message="No users found" />

      {viewBagModal && (
        <ViewBag
          visible={viewBagModal}
          bagId={bagId}
          setModal={setViewBag}
          refreshData={() => setTrigger((prev) => prev + 1)}
        />
      )}
    </div>
  );
}

export default observer(OrderDetail);
