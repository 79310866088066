import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";

function SetSubsidy({ visible, setModal, onClickRightButton }) {
  const [subsidy, setSubsidyAmount] = useState("");

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[696px] h-[276px] d-col justify-between rounded-xl md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div>
                  <div className="d-row justify-between items-center">
                    <h2 className="text-2xl font-inter-bold">Set Subsidy</h2>
                    <button
                      onClick={() => setModal(false)}
                      className="text-dark-gray hover:text-yellow-600"
                      aria-label="Close"
                    >
                      <span className="text-xl">&times;</span>
                    </button>
                  </div>

                  <div className="d-row font-inter-medium text-base bg-background items-center mt-[24px] rounded-md pl-[19px]">
                    <small>$</small>
                    <input
                      type="text"
                      className="flex w-full bg-transparent text-dark-gray focus:outline-none text-md py-[9px] px-[0.5]"
                      onChange={(e) => {
                        const regex = /^[0-9]*\.?[0-9]*$/;
                        if (regex.test(e.target.value)) {
                          setSubsidyAmount(e.target?.value);
                        }
                      }}
                      value={subsidy}
                    />
                  </div>
                </div>

                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3"
                    onClick={() => {
                      subsidy && onClickRightButton(subsidy);
                    }}
                  >
                    Generate link
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default SetSubsidy;
